<template>
  <v-app-bar app>
    <v-btn icon exact :to="telaAnterior()">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div class="text-h6 font-weight-bold ml-3">
      {{ tituloRegua }}
    </div>

    <v-chip
      v-if="$route.query.tipo"
      :color="$route.query.tipo == 'MOVIMENTACAO' ? 'green' : 'blue'"
      class="black--text font-weight-medium ml-4"
    >
      {{ $route.query.tipo == "MOVIMENTACAO" ? "Movimentação" : "Pré-definidas" }}
    </v-chip>

    <v-divider style="border: none"></v-divider>
    <div v-if="this.$route.query?.tela == 'reguas'" class="my-auto">
      <v-combobox
        class="searchInput"
        ref="searchInput"
        id="searchInput"
        dense
        outlined
        filled
        clearable
        hide-details
        label="Procurar"
        placeholder="Régua ou perfil"
        :items="getBuscasSalvas()"
        :append-icon="hasValue ? '' : 'mdi-magnify'"
        @update:search-input="(e) => handleInput(e)"
        @blur="(e) => salvarBuscaParaAutoComplete(e.target)"
      >
      </v-combobox>
    </div>

    <v-btn text elevation="0" color="primary" class="font-weight-bold" @click="trocarTela()">
      {{ textoBotao }}
    </v-btn>
  </v-app-bar>
</template>

<script>
import enviroment from "@/assets/js/enviroment";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      hasValue: false,
    };
  },
  props: {
    tituloRegua: String,
    telaAtual: String,
    trocarTela: Function,
  },
  computed: {
    idRegua() {
      return this.$route.query?.id;
    },
    textoBotao() {
      if (this.idRegua && this.$route.query?.tela == "variaveis") {
        return "Régua";
      }
      let telasPossiveis = {
        reguas: "Variáveis",
        variaveis: "Réguas",
      };
      return telasPossiveis[this.telaAtual];
    },
  },
  methods: {
    handleInput(value) {
      this.hasValue = value && value.length > 0;
      this.$emit("search", value);
    },

    getBuscasSalvas() {
      const buscasSalvas = JSON.parse(Cookies.get("buscasReguaSalvas") || "false");
      if (!buscasSalvas) return [];

      return buscasSalvas;
    },

    salvarBuscaParaAutoComplete({ value }) {
      if (!value) return;

      const buscas = JSON.stringify([]);
      const buscasReguaSalvas = JSON.parse(Cookies.get("buscasReguaSalvas") || buscas);

      if (buscasReguaSalvas.some((b) => b === value)) return;

      buscasReguaSalvas.unshift(value);
      Cookies.set("buscasReguaSalvas", JSON.stringify(buscasReguaSalvas), {
        domain: enviroment.cookiesDomain,
        path: "/",
      });
    },

    telaAnterior() {
      if (this.idRegua && !this.$route.query?.tipo_movimentacao_id) {
        return `/reguas/${this.idRegua}`;
      } else if (this.idRegua && this.$route.query?.tipo_movimentacao_id) {
        let tipoMovimentacaoId = this.$route.query?.tipo_movimentacao_id;
        return `/reguas/${this.idRegua}?tipo_movimentacao_id=${tipoMovimentacaoId}`;
      } else if (this.$route.query?.tela == "variaveis") {
        return `/reguas?arquivadas=false&tipo=${this.$route.query.tipo}&tela=reguas`;
      }
      return {
        name:
          this.$route.query.tipo == "MOVIMENTACAO"
            ? "ListagemReguasMovimentacao"
            : "ListagemReguasPreDefinidas",
      };
    },
  },
};
</script>

<style>
.searchInput .v-icon {
  transition: none !important; /* Remove a animação de rotação */
  transform: none !important; /* Impede a rotação do ícone */
}
</style>
