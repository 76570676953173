<template>
  <v-container>
    <div class="text-center mt-8 mb-4">
      <h1 class="text-h6">Variáveis para as mensagens</h1>
    </div>

    <div v-if="carregando" class="ml-carregando">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-alert v-if="erro" type="error" prominent text>
      <v-row align="center">
        <v-col class="grow"> Não foi possível carregar as variáveis. </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="carregar()"> Tentar novamente </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-card v-if="!carregando && !erro" class="variaveis">
      <v-data-table
        :headers="cabecalhos"
        :items="variaveis"
        :items-per-page="1000"
        hide-default-footer
        no-data-text="Nenhuma variável encontrada"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              @mouseover="selecionaItem(item)"
              @mouseleave="desselecionarItem()"
            >
              <td>{{ item.nome }}</td>
              <td>{{ item.descricao }}</td>
              <td>{{ item.exemplo }}</td>
              <td class="text-center">{{ item.tamanho }}</td>
              <td class="menu-tabela">
                <v-menu v-if="item === itemSelecionado" v-model="menuAberto">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="abrirDialog(item)">
                      <v-list-item-title>
                        Tipos de Régua Vinculados
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" :width="400" persistent>
      <v-form lazy-validation>
        <v-card>
          <v-card-title class="pb-0"> Tipos de Réguas Vinculados </v-card-title>
          <v-card-text class="pt-0">
            <template>
              <v-data-table
                dense
                :headers="cabecalhoTiposRegua"
                :items="tiposRegua()"
                item-key="name"
                class="elevation-0"
                no-data-text="Nenhum tipo cadastrado."
                hide-details
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.tipo_regua`]="{ item }">
                  <span class="my-2" style="font-size: 14px !important">{{
                    item.tipo_regua | tipoRegua
                  }}</span>
                </template>
                <template v-slot:[`item.excluir`]="{ item }">
                  <v-btn
                    icon
                    v-if="item"
                    @click="removerTipoRegua(item.tipo_regua)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </template>
          </v-card-text>

          <v-divider></v-divider>

          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn color="primary" text @click="fecharDialog()">
                Fechar </v-btn
              ><v-btn color="primary" @click="salvar()"> Salvar </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import variavelMensagemService from "../../../services/variavel-mensagem";

export default {
  data: () => ({
    cabecalhos: [
      { text: "Nome", value: "nome" },
      { text: "Descrição", value: "descricao" },
      { text: "Exemplo", value: "exemplo" },
      { text: "Tamanho", value: "tamanho", align: "center" },
      { text: "", value: "tipo_regua", align: "right" },
    ],
    cabecalhoTiposRegua: [
      { text: "", value: "tipo_regua", sortable: false },
      { text: "", value: "excluir", align: "right", sortable: false },
    ],
    arrayTipos: [],
    tiposRegua: [],
    dialog: false,
    idVariavelSelecionada: null,
    itemSelecionado: false,
    menuAberto: false,
  }),
  created() {
    let payload = {
      limite: 1000,
      ordem: "nome",
      empresa_id: this.empresaSelecionada.id,
    };
    this.carregar(payload);
  },
  methods: {
    ...mapActions("variavelMensagem", ["carregar"]),
    selecionaItem(item) {
      if (!this.menuAberto) this.itemSelecionado = item;
    },
    desselecionarItem() {
      if (!this.menuAberto) this.itemSelecionado = false;
    },
    abrirDialog(variavel) {
      if (!variavel.tipo_regua) return;
      this.idVariavelSelecionada = variavel.id;
      this.arrayTipos = variavel.tipo_regua.split(",");
      this.arrayTipos.forEach((tipo) => {
        this.tiposRegua.push({ tipo_regua: tipo });
      });
      this.dialog = true;
    },
    fecharDialog() {
      this.arrayTipos = [];
      this.tiposRegua = [];
      this.dialog = false;
    },
    removerTipoRegua(tipoRegua) {
      if (!tipoRegua) return;
      let index = this.tiposRegua().findIndex((tipo) => {
        return tipo.tipo_regua == tipoRegua;
      });
      this.tiposRegua().splice(index, 1);
    },
    async salvar() {
      let tipos = [];
      this.tiposRegua().forEach((tipo) => {
        tipos.push(tipo["tipo_regua"]);
      });
      if (this.comparaArray(tipos, this.arrayTipos)) {
        this.fecharDialog();
      } else {
        let payload = {
          id: this.idVariavelSelecionada,
          tipo_regua: tipos.join(),
        };
        await variavelMensagemService
          .salvarVariavelMensagem(payload)
          .then((retorno) => {
            const index = this.variaveis.findIndex(
              (variavel) => variavel.id == retorno.data.id
            );
            this.$set(this.variaveis, index, retorno.data);
            this.fecharDialog();
          });
      }
    },
    comparaArray(a, b) {
      return JSON.stringify(a) === JSON.stringify(b);
    },
  },
  computed: {
    ...mapGetters("variavelMensagem", ["carregando", "erro", "variaveis"]),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        let payload = {
          limite: 1000,
          ordem: "nome",
          empresa_id: this.empresaSelecionada.id,
        };
        this.carregar(payload);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.variaveis {
  margin: auto;
  max-width: 1200px;
}
.menu-tabela {
  width: 70px !important;
}
</style>
