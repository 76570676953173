<template>
  <v-dialog v-model="dialog" :width="500" persistent>
    <v-form lazy-validation>
      <v-card>
        <v-card-title class="text-h5 title-card">
          Visualizar Perfis
        </v-card-title>
        <v-card-text class="pt-3">
          <template>
            <v-data-table
              dense
              :headers="cabecalhoTabela"
              :items="perfisRegua"
              item-key="name"
              class="elevation-0"
              no-data-text="Nenhum perfil cadastrado."
              hide-details
              hide-default-footer
              disable-pagination
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    @mouseover="selecionaItem(item)"
                    @mouseleave="desselecionarItem()"
                  >
                    <td class="text-left">
                      {{ item.perfil_cliente || "Sem Perfil" | perfilCliente }}
                    </td>
                    <td class="text-left">
                      {{
                        item.perfil_contrato || "Sem Perfil" | perfilContrato
                      }}
                    </td>
                    <td class="botao-excluir text-right">
                      <v-btn
                        icon
                        small
                        v-if="item === itemSelecionado && opcaoEditar"
                        @click="excluirPerfis(item)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="fecharDialog()"
              :disabled="desvinculandoPerfil"
            >
              FECHAR
            </v-btn>
            <v-btn
              v-if="opcaoEditar"
              color="primary"
              @click="salvar()"
              :loading="desvinculandoPerfil"
            >
              SALVAR
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      perfisRegua: [],
      cabecalhoTabela: [
        {
          text: "Perfil cliente",
          align: "start",
          sortable: false,
          value: "perfil_cliente",
        },
        {
          text: "Perfil contrato",
          value: "perfil_contrato",
          sortable: false,
          align: "left",
        },
        {
          text: "",
          value: "menu",
          sortable: false,
          align: "left",
        },
      ],
      itemSelecionado: false,
      perfisExcluidos: [],
      regua: null,
      opcaoEditar: true,
    };
  },
  methods: {
    ...mapActions("reguas", {
      desvincularPerfil: "desvincularPerfil",
    }),
    selecionaItem(item) {
      this.itemSelecionado = item;
    },
    desselecionarItem() {
      this.itemSelecionado = false;
    },
    abrirDialog(regua, tipo = "EDITAR") {
      if (tipo == "VISUALIZAR") {
        this.opcaoEditar = false;
      }
      this.regua = regua;
      this.carregaPerfisRegua(regua.perfis);
      this.dialog = true;
    },
    fecharDialog() {
      this.dialog = false;
    },
    carregaPerfisRegua(perfis) {
      this.perfisRegua = [];
      perfis.forEach((perfil) => {
        this.perfisRegua.push({
          id: perfil.id,
          perfil_cliente: perfil.perfil_cliente,
          perfil_contrato: perfil.perfil_contrato,
        });
      });
    },
    excluirPerfis(perfis) {
      this.perfisExcluidos.push(perfis);
      let index = this.perfisRegua.findIndex(
        (perfil) =>
          perfil.perfil_contrato == perfis.perfil_contrato &&
          perfil.perfil_cliente == perfis.perfil_cliente
      );
      this.perfisRegua.splice(index, 1);
    },
    async salvar() {
      let payload = {
        regua: this.regua,
        perfis: this.perfisExcluidos,
        empresa_id: this.empresaSelecionada.id,
      };
      try {
        let retorno = await this.desvincularPerfil(payload);
        retorno.forEach((id) => {
          let index = this.regua.perfis.findIndex((perfil) => perfil.id == id);
          if (index > -1) {
            this.regua.perfis.splice(index, 1);
          }
        });
        if (this.regua.id_regua_desafiante) {
          this.perfisExcluidos.forEach((perfilExcluido) => {
            let indexDesafiante = this.getReguaById(
              this.regua.id_regua_desafiante
            ).perfis.findIndex((perfil) => {
              return (
                perfil.perfil_contrato == perfilExcluido.perfil_contrato &&
                perfil.perfil_cliente == perfilExcluido.perfil_cliente
              );
            });
            this.getReguaById(this.regua.id_regua_desafiante).perfis.splice(
              indexDesafiante,
              1
            );
          });
        }
        this.dialog = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("reguas", {
      desvinculandoPerfil: "desvinculandoPerfil",
      erroDesvincularPerfil: "erroDesvincularPerfil",
      getReguaById: "getReguaById",
    }),
  },
};
</script>

<style scoped lang="scss">
.botao-excluir {
  width: 80px !important;
}
</style>
