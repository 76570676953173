<template>
  <div class="fill-height">
    <v-app-bar app>
      <v-btn outlined @click="value = ''"> Hoje </v-btn>

      <v-spacer />

      <v-toolbar-title class="text-h5 d-flex align-center mx-auto">
        <!-- Botão para selecionar mês antetior -->
        <v-btn fab text small @click="$refs.calendar.prev()">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <!-- Título calendário (mês e ano) -->
        <b style="width: 200px; text-align: center">
          {{ tituloCalendario() }}
        </b>
        <!-- Botão para selecionar próximo mês -->
        <v-btn fab text small @click="$refs.calendar.next()">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
      </v-toolbar-title>

      <v-progress-linear
        :active="carregando"
        :indeterminate="carregando"
        absolute
        bottom
        color="deep-purple accent-4"
      ></v-progress-linear>

      <v-spacer />

      <v-select
        v-model="tipoCalendario"
        :items="tiposCalendario"
        dense
        outlined
        hide-details
        style="max-width: 120px"
      ></v-select>

      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="routeParams.arquivadas"></v-switch>
            </v-list-item-action>
            <v-list-item-title>Mostrar arquivadas</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-alert
      dense
      type="error"
      class="mb-0 text-center"
      v-if="erroCarregarAgendas"
    >
      Não foi possível carregar as agendas
      <v-btn
        color="primary"
        class="ml-3"
        @click="recarregarAgendas(routeParams.arquivadas)"
      >
        Tentar novamente
      </v-btn>
    </v-alert>

    <!-- Calendário -->
    <v-calendar
      locale="pt-br"
      ref="calendar"
      v-model="value"
      :type="tipoCalendario"
      class="calendar mt-1"
      @click:day="abreCriarAgenda"
      @click:event="abreEditarAgenda"
      @change="carregaAgendas"
      :events="agendasCalendario"
      event-more-text="mais {0}"
      @click:more="agendasDia"
      @click:date="agendasDia"
    >
    </v-calendar>

    <v-dialog v-model="dialogAgenda" :width="600" persistent>
      <v-form ref="formAgenda" lazy-validation @submit="salvarAgenda">
        <v-card>
          <v-card-title class="text-h5 title-card">
            {{ editar ? "Editar" : "Criar" }} agenda
            <v-spacer></v-spacer>

            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="atualizarStatus">
                  <v-list-item-title>
                    {{
                      editarAgenda.status != "ARQUIVADA" ? "Arquivar" : "Ativar"
                    }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Nome"
              outlined
              autofocus
              required
              v-model="nomeAgenda"
              :rules="campoRules.nome"
            ></v-text-field>
            <div class="d-flex">
              <v-text-field
                type="date"
                outlined
                v-model="dataAgenda"
                :rules="campoRules.data"
                required
                class="mr-2"
                style="max-width: 170px"
              ></v-text-field>
              <v-text-field
                v-if="!diaInteiro"
                type="time"
                label="Início"
                v-model="horarioAgenda"
                class="mr-2"
                outlined
                required
                style="width: 45px"
              ></v-text-field>
              <v-text-field
                v-if="!diaInteiro"
                type="time"
                label="Fim"
                v-model="agenda.hora_fim"
                class="mr-2"
                outlined
                required
                style="width: 45px"
              ></v-text-field>

              <v-switch v-model="diaInteiro" label="Dia inteiro"></v-switch>
            </div>
            <v-textarea
              v-model="descricaoAgenda"
              rows="4"
              outlined
              placeholder="Adicione uma descrição"
            >
            </v-textarea>
            <div class="d-flex align-center">
              <v-select
                class="mr-2"
                hide-details
                outlined
                v-model="selectReguasComunicacao"
                label="Régua de comunicação"
                :items="reguasCominucacao"
                :rules="campoRules.regua"
                no-data-text="Nenhuma régua cadastrada!"
              ></v-select>

              <v-tooltip v-if="selectReguasComunicacao" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :to="{
                      name: 'eventos',
                      params: { regua_id: selectReguasComunicacao },
                    }"
                    target="_blank"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <span>Editar eventos</span>
              </v-tooltip>

              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :to="{ name: 'Reguas', query: { tipo: 'COMUNICACAO' } }"
                    target="_blank"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <span>Criar régua</span>
              </v-tooltip>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="fecharDialog()">
              cancelar
            </v-btn>
            <v-btn color="primary" type="submit" :loading="salvando">
              salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <PromiseBasedDialog ref="dialogConfirmacao" :options="opcoesDialog" />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import PromiseBasedDialog from "@/components/dialogs/promiseBasedDialog.vue";

const CORES = {
  ARQUIVADA: "grey",
  PRODUCAO: "",
};

export default {
  components: {
    PromiseBasedDialog,
  },
  data: () => ({
    /** Agenda sendo editada. */
    agenda: {},

    campoRules: {
      nome: [(valor) => (valor || "").length > 0 || "Campo obrigatório"],
      data: [(valor) => (valor || "").length > 0 || "Campo obrigatório"],
      regua: [(v) => !!v || "Campo obrigatório"],
    },

    /** Indica se o evento sendo editado não tem horário de início. */
    diaInteiro: false,

    editarAgenda: {
      id: null,
    },
    opcoesDialog: {},
    dataInicio: null,
    dataFim: null,
    selectReguasComunicacao: null,
    tipoCalendario: "month",
    tiposCalendario: [
      {
        text: "mês",
        value: "month",
      },
      {
        text: "dia",
        value: "day",
      },
    ],
    agendasCalendario: [],
    editar: false,
    dataAgenda: null,
    dialogAgenda: false,
    horarioAgenda: null,
    nomeAgenda: null,
    descricaoAgenda: null,
    reguasCominucacao: [],
    value: "",
    salvando: false,
    routeParams: {
      arquivadas: false,
    },
  }),
  computed: {
    ...mapGetters("agenda", {
      agendas: "agendas",
      carregando: "carregando",
      erroCarregarAgendas: "erroCarregarAgendas",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
  async created() {
    await this.carregaReguasComunicacao();
    this.atualizaQueryParams();
  },

  watch: {
    routeParams: {
      handler() {
        this.atualizaQueryParams();
        this.recarregarAgendas(this.routeParams.arquivadas);
      },
      deep: true,
    },
    empresaSelecionada: {
      handler() {
        this.recarregarAgendas(this.routeParams.arquivadas);
        this.carregaReguasComunicacao();
      },
    },
  },

  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    ...mapActions("reguas", {
      carregarReguas: "carregarReguas",
    }),

    ...mapActions("agenda", {
      getAgendas: "getAgendas",
      criaOuEditaAgenda: "criaOuEditaAgenda",
    }),

    async atualizarStatus() {
      let status = this.editarAgenda.status;

      this.opcoesDialog = {
        cardText:
          status == "ARQUIVADA"
            ? "Deseja ativar esta agenda?"
            : "Deseja arquivar esta agenda?",
        cardTitle: null,
        acceptBtn: status != "ARQUIVADA" ? "arquivar" : "ativar",
        declineBtn: "cancelar",
        width: "300px",
      };

      const resultado = await this.$refs.dialogConfirmacao.openDialog();

      if (!resultado) return;

      let novoStatus = status == "ARQUIVADA" ? "PRODUCAO" : "ARQUIVADA";

      let payload = {
        id: this.editarAgenda.id,
        status: novoStatus,
      };

      this.criaOuEditaAgenda(payload)
        .then((resposta) => {
          this.showSnackBar({
            text: `Agenda ${this.editar ? "editada" : "criada"} com sucesso`,
          });

          let evento = this.agendasCalendario.find(
            (evento) => evento.agenda.id == this.editarAgenda.id
          );

          evento.agenda = resposta;
          evento.color = CORES[novoStatus];

          this.fecharDialog();
        })
        .catch((e) => {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao ${this.editar ? "editar" : "criar"} a agenda.`,
          });
        });
    },

    agendasDia({ date }) {
      this.value = date;
      this.tipoCalendario = "day";
    },

    async carregaAgendas({ start, end }) {
      this.agendasCalendario = [];
      if (!start || !end) {
        return;
      }

      this.dataInicio = moment(start.date).subtract(7, "day").toISOString();
      this.dataFim = moment(end.date).add(7, "day").toISOString();

      console.debug(
        `Buscando agendas entre ${this.dataInicio} e ${this.dataFim}`
      );
      try {
        let payload = {
          limite: 1000,
          empresa_id: this.empresaSelecionada.id,
          data_inicio: this.dataInicio,
          data_fim: this.dataFim,
          status: "PRODUCAO",
        };
        await this.getAgendas(payload);
        this.mostrarAgendaCalendario();
      } catch (e) {
        console.error(e);
      }
    },

    async recarregarAgendas(arquivadas) {
      if (!arquivadas) {
        let payload = {
          limite: 1000,
          empresa_id: this.empresaSelecionada.id,
          data_inicio: this.dataInicio,
          data_fim: this.dataFim,
          status: "PRODUCAO",
        };
        await this.getAgendas(payload);
        this.agendasCalendario = [];
        this.mostrarAgendaCalendario();
      } else {
        let payload = {
          limite: 1000,
          empresa_id: this.empresaSelecionada.id,
          data_inicio: this.dataInicio,
          data_fim: this.dataFim,
        };
        await this.getAgendas(payload);
        this.agendasCalendario = [];
        this.mostrarAgendaCalendario(true);
      }
    },

    /** Retorna a lista de agendas para a data informada. */
    mostrarAgendaCalendario() {
      if (!this.agendas) {
        return [];
      }

      this.agendas.forEach((agenda) => {
        let start = agenda.data;
        let end = null;

        if (agenda.hora_inicio) {
          start = `${agenda.data}T${agenda.hora_inicio}`;
        }

        if (agenda.hora_fim) {
          end = `${agenda.data}T${agenda.hora_fim}`;
        }

        this.agendasCalendario.push({
          name: agenda.nome,
          agenda: agenda,
          end: end,
          start: start,
          color: CORES[agenda.status],
        });
      });
    },

    // Carrega as réguas do tipo COMUNICAÇÂO para mostrar no v-select
    async carregaReguasComunicacao() {
      let payload = {
        limite: 1000,
        order: "nome",
        tipo: "COMUNICACAO",
        status: "PRODUCAO",
      };
      let reguasCadastradas = await this.carregarReguas(payload);

      reguasCadastradas.objects.forEach((regua) => {
        if (regua.tipo == "COMUNICACAO" && regua.status == "PRODUCAO") {
          this.reguasCominucacao.push({
            text: regua.nome,
            value: regua.id,
          });
        }
      });
    },

    abreCriarAgenda(dia) {
      this.dialogAgenda = true;
      this.dataAgenda = dia.date;
      this.carregaReguasComunicacao();
    },

    abreEditarAgenda(agenda, evento) {
      evento.stopPropagation();

      this.editarAgenda = JSON.parse(JSON.stringify(agenda.event.agenda));
      this.dialogAgenda = true;
      if (this.editarAgenda.id) {
        this.dataAgenda = this.editarAgenda.data;
        this.nomeAgenda = this.editarAgenda.nome;
        this.horarioAgenda = this.editarAgenda.hora_inicio;
        this.descricaoAgenda = this.editarAgenda.descricao;
        this.selectReguasComunicacao = this.editarAgenda.regua_id;
        this.editar = true;
        this.agenda.hora_fim = this.editarAgenda.hora_fim;
      }

      this.diaInteiro =
        !this.editarAgenda.hora_inicio && !this.editarAgenda.hora_fim;
      this.carregaReguasComunicacao();
    },

    tituloCalendario() {
      if (this.$refs.calendar) {
        return this.$refs.calendar.title;
      }

      return moment().format("MMMM YYYY");
    },

    retornaDiaDeHoje(dia) {
      const diaCalendario = moment(dia).format("llll");
      let hoje = moment().startOf("day").format("llll");

      return diaCalendario == hoje;
    },

    salvarAgenda(evento) {
      evento.preventDefault();
      if (!this.$refs.formAgenda.validate()) return;
      if (this.horarioAgenda == "") {
        this.horarioAgenda = null;
      }

      if (this.diaInteiro) {
        this.horarioAgenda = null;
        this.agenda.hora_fim = null;
      }

      this.salvando = true;
      let payload = {
        id: this.editarAgenda.id || undefined,
        empresa_id: this.empresaSelecionada.id,
        nome: this.nomeAgenda,
        data: this.dataAgenda,
        descricao: this.descricaoAgenda,
        hora_inicio: this.horarioAgenda,
        hora_fim: this.agenda.hora_fim,
        regua_id: this.selectReguasComunicacao,
      };

      if (!this.editarAgenda.id) {
        payload.status = "PRODUCAO";
      }

      this.criaOuEditaAgenda(payload)
        .then((resp) => {
          this.showSnackBar({
            text: `Agenda ${this.editar ? "editada" : "criada"} com sucesso`,
          });

          if (this.editarAgenda.id) {
            let indexElemento = this.agendasCalendario.findIndex(
              (elemento) => elemento.agenda.id == resp.id
            );
            let novoValor = {
              name: resp.nome,
              start:
                resp.data + (resp.hora_inicio ? "T" + resp.hora_inicio : ""),
              agenda: resp,
              color: CORES[resp.status],
            };
            this.$set(this.agendasCalendario, indexElemento, novoValor);
          } else {
            this.agendasCalendario.push({
              name: resp.nome,
              start:
                resp.data + (resp.hora_inicio ? "T" + resp.hora_inicio : ""),
              agenda: resp,
              color: CORES[resp.status],
            });
          }
          this.editarAgenda.id = null;
          this.fecharDialog();
        })
        .catch((e) => {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao ${this.editar ? "editar" : "criar"} a agenda.`,
          });
        })
        .finally(() => {
          this.salvando = false;
        });
    },

    fecharDialog() {
      this.$refs.formAgenda.reset();
      this.dialogAgenda = false;
      this.horarioAgenda = null;
      this.nomeAgenda = null;
      this.descricaoAgenda = null;
      this.selectReguasComunicacao = null;
      this.editar = false;
      this.editarAgenda.id = null;
    },

    atualizaQueryParams() {
      this.$router.push({ query: { ...this.routeParams } }).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.calendar {
  height: calc(100vh - 75px);
  cursor: pointer;
}

:deep(.v-calendar-weekly__day:hover) {
  background: #e0e0e0;
}

:deep(.v-event-more) {
  background-color: #bdbdbd !important;
}

.ARQUIVADA {
  text-decoration: line-through !important;
  color: #757575 !important;
}
</style>
