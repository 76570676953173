import http from "@/plugins/axios";
import store from "@/store";

export default {
  getNotificacoes(params) {
    return http.api.get(`/v1/notificacao`, {
      params: params,
    });
  },
  getFluxoAutomatico(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + "/v1/fluxo_automatico", {
      params,
    });
  },
  getEvidencia(id) {
    return http.api.get(`/v1/notificacao/${id}/evidencia`, {
      validateStatus: false,
    });
  },
  getTemplateEvidencia(id) {
    return http.api.get(`/v1/notificacao/${id}/evidencia/email`, {
      validateStatus: false,
    });
  },
};
