<template>
  <v-app-bar app>
    <v-btn
      icon
      exact
      :to="{ name: 'Contratos', query: { consulta: $route.query.consulta } }"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-breadcrumbs class="d-inline pl-2" :items="items" divider=">">
      <v-breadcrumbs-item
        slot="item"
        slot-scope="{ item }"
        exact
        :disabled="item.disabled"
        :to="item.href"
      >
        <a class="d-flex flex-column black--text">
          <span
            style="line-height: 10px !important; font-size: 18px"
            class="font-weight-bold"
            >{{ item.text }}</span
          >
          <span style="font-size: 14px !important">{{ item.subtext }}</span>
        </a>
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    items: [],
  },
};
</script>

<style></style>
