<template>
  <div
    class="pt-8 pb-8 d-flex justify-center"
    :class="{
      'pr-4 pl-4 mr-2 ml-2': $vuetify.breakpoint.smAndDown,
      'pr-4 pl-4 mr-2 ml-2': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-app-bar app>
      <div class="text-h6 font-weight-bold ml-3">Net Promoter Score</div>
      <v-spacer></v-spacer>
      <v-switch
        hide-details=""
        label="NPS arquivadas"
        class="mr-4"
        v-model="mostrarArquivadas"
      ></v-switch>
    </v-app-bar>
    <Tabela
      class="w-8/12"
      :listaNps="listaNps"
      :carregando="carregando"
      :mostrarArquivadas="mostrarArquivadas"
      @navegar="irPara"
      @editar="abrirDialogEditar"
      @despublicar="despublicarItem"
      @publicar="publicarItem"
      @arquivar="arquivarItem"
      @desarquivar="despublicarItem"
    />

    <DialogAlterar
      ref="dialogModificar"
      @novo="novoItem"
      @editar="editarItem"
    />
    <DialogConfirmar ref="dialogConfirmar" @confirmar="editarItem" />
    <FloatingBtn @click="abrirDialog({ operacao: 'novo' })" />
  </div>
</template>

<script>
import AppBar from "../componentes/AppBar.vue";
import Tabela from "../componentes/Tabela.vue";
import DialogAlterar from "../componentes/DialogAlterar.vue";
import DialogConfirmar from "../componentes/DialogConfirmar.vue";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

import { mapActions, mapGetters } from "vuex";
import service from "@/services/nps.js";

export default {
  components: {
    AppBar,
    Tabela,
    DialogAlterar,
    DialogConfirmar,
    FloatingBtn,
  },
  data() {
    return {
      mostrarArquivadas: false,
    };
  },
  computed: {
    ...mapGetters("nps", ["listaNps", "carregando"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.carregarItens({
          empresa_id: this.empresaSelecionada.id,
          limite: 10000,
          ordem: "status",
        });
      },
      deep: true,
    },
  },
  async created() {
    await this.carregarItens({
      empresa_id: this.empresaSelecionada.id,
      limite: 10000,
      ordem: "status",
    });
  },
  methods: {
    ...mapActions("nps", {
      carregarItens: "pegar",
      adicionarItem: "criar",
      editarItem: "editar",
    }),
    limparOpcoesVazias(nps) {
      for (const [atributo, valor] of Object.entries(nps)) {
        if (Array.isArray(valor) && valor.length < 1) {
          nps[atributo] = null;
        }
      }
    },
    abrirDialog(dados) {
      return this.$refs.dialogModificar.abrir(dados);
    },
    abrirDialogEditar(id) {
      let nps = { ...this.listaNps.find((item) => item.id == id) };
      this.abrirDialog({ operacao: "editar", nps });
    },
    async novoItem(item) {
      let novoItem = { ...item, empresa_id: this.empresaSelecionada.id };
      await this.limparOpcoesVazias(novoItem);
      this.adicionarItem(novoItem);
    },
    publicarItem(id) {
      let nps = { ...this.listaNps.find((item) => item.id == id) };
      nps.status = "PRODUCAO";
      this.editarItem(nps);
    },
    async despublicarItem(id) {
      let nps = { ...this.listaNps.find((item) => item.id == id) };
      if (nps.status == "PRODUCAO") {
        let { data: eventosVinculados } = await service.buscarEventosVinculados(
          nps.id
        );

        if (eventosVinculados && eventosVinculados?.length > 0) {
          this.$refs.dialogConfirmar.abrir(
            "despublicar",
            eventosVinculados.length,
            nps
          );
          return;
        }
      }

      nps.status = "RASCUNHO";
      this.editarItem(nps);
    },
    async arquivarItem(id) {
      let nps = { ...this.listaNps.find((item) => item.id == id) };

      if (nps.status == "PRODUCAO") {
        let { data: eventosVinculados } = await service.buscarEventosVinculados(
          nps.id
        );

        if (eventosVinculados && eventosVinculados?.length > 0) {
          this.$refs.dialogConfirmar.abrir(
            "arquivar",
            eventosVinculados.length,
            nps
          );
          return;
        }
      }

      nps.status = "ARQUIVADA";
      this.editarItem(nps);
    },
    irPara(endereco) {
      window.open(endereco, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1000px !important;
}
</style>
