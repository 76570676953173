<template>
  <v-container>
    <v-app-bar app>
      <v-btn
        icon
        exact
        :to="{
          name: 'Contratos',
        }"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="text-h6 font-weight-bold ml-3">
        {{ $route.query.tipo | tipoContrato }}
      </div>
    </v-app-bar>
    <div class="elevation-7 principal mx-auto pa-7 rounded my-5">
      <v-form ref="form">
        <div class="d-flex">
          <p class="text-h5 font-weight-medium">Cliente</p>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            class="mt-2"
            small
            @click="cadastrarEndereco = true"
            v-if="!cadastrarEndereco"
            >Adicionar Endereço</v-btn
          >
        </div>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              hide-details
              label="Nome*"
              v-model="cliente.nome"
              :rules="[rules.required]"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="Documento*"
              hide-details
              v-model="cliente.documento"
              v-mask="'###.###.###-##'"
              :rules="[rules.required]"
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataNascimentoFormatada"
                  hide-details
                  outlined
                  append-icon="mdi-calendar"
                  label="Data de Nascimento"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="cliente.dataNascimento"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              type="text"
              label="Código do Cliente"
              v-model="cliente.codCliente"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              type="text"
              label="Perfil do Cliente*"
              hide-details
              v-model="cliente.perfilCliente"
              :items="perfisCliente"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <div v-if="cadastrarEndereco">
          <p class="text-h5 font-weight-medium mt-8">Endereço</p>
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="CEP"
                v-model="cliente.endereco_cep"
                hide-details
                v-mask="'#####-###'"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="text"
                label="Rua"
                v-model="cliente.endereco_rua"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="text"
                label="Número"
                v-model="cliente.endereco_numero"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                type="text"
                label="Cidade"
                v-model="cliente.endereco_cidade"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-text-field
                type="text"
                label="UF"
                v-model="cliente.endereco_uf"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <p class="text-h5 font-weight-medium mt-8">Contato</p>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              label="E-mail*"
              v-model="contatoCliente.email"
              :rules="[rules.required]"
              hide-details
              outlined
              required
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="contatoCliente.celular"
              label="Celular*"
              hide-details
              v-mask="'(##) #####-####'"
              :rules="[rules.required]"
              required
              outlined
              type="tel"
            ></v-text-field>
            <v-checkbox
              label="Este número é WhatsApp"
              v-model="contatoCliente.whatsapp"
            ></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-text-field
              hide-details
              type="text"
              label="Fixo"
              v-model="contatoCliente.fixo"
              v-mask="'(##) ####-####'"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="d-flex mt-2">
          <p class="text-h5 font-weight-medium">Contrato</p>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            class="mt-2"
            small
            @click="maisInformacoes = true"
            v-if="!maisInformacoes"
            >Mais Informações</v-btn
          >
        </div>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              label="Tipo"
              v-model="contrato.tipo"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              label="Código do Contrato*"
              v-model="contrato.codContrato"
              hide-details
              :rules="[rules.required]"
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              label="Composição"
              v-model="contrato.composicao"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              label="Categoria"
              v-model="contrato.categoria"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              label="Risco"
              v-model="contrato.risco"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              label="Origem"
              v-model="contrato.origem"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              label="Canal"
              v-model="contrato.canal"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataEmissaoFormatada"
                  outlined
                  append-icon="mdi-calendar"
                  hide-details
                  label="Data de Emissão"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="contrato.dataEmissao"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataVigenciaInicioEmFormatada"
                  outlined
                  append-icon="mdi-calendar"
                  hide-details
                  :label="
                    $route.query.tipo == 'CONSORCIO' ||
                    $route.query.tipo == 'GENERICO'
                      ? 'Vigência Início Em*'
                      : 'Vigência Início Em'
                  "
                  :rules="[
                    () =>
                      $route.query.tipo == 'CONSORCIO' ||
                      $route.query.tipo == 'GENERICO'
                        ? !!contrato.vigenciaInicioEm || 'Campo obrigatório'
                        : true,
                  ]"
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="contrato.vigenciaInicioEm"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-menu
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dataVigenciaTerminoEmFormatada"
                  outlined
                  append-icon="mdi-calendar"
                  :label="
                    $route.query.tipo == 'CONSORCIO' ||
                    $route.query.tipo == 'GENERICO'
                      ? 'Vigência Término Em*'
                      : 'Vigência Término Em'
                  "
                  :rules="[
                    () =>
                      $route.query.tipo == 'CONSORCIO' ||
                      $route.query.tipo == 'GENERICO'
                        ? !!contrato.vigenciaInicioEm || 'Campo obrigatório'
                        : true,
                  ]"
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="contrato.vigenciaTerminoEm"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              label="Perfil da Empresa*"
              hide-details
              :items="perfisEmpresa"
              v-model="contrato.perfilEmpresa"
              outlined
              :rules="[rules.required]"
              required
            ></v-select>
          </v-col>
          <v-col cols="4" v-if="$route.query.tipo == 'CONSORCIO'">
            <v-text-field
              type="number"
              label="Valor do Bem"
              prefix="R$"
              hide-details
              v-model="contrato.valorDoBem"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              type="text"
              label="Perfil do Contrato"
              hide-details
              :items="perfisContrato"
              v-model="contrato.perfilContrato"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="maisInformacoes" class="mt-2">
          <v-col>
            <v-text-field
              type="text"
              :label="nomeDimensao('dimensao_1')"
              v-model="contrato.dimensao1"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              :label="nomeDimensao('dimensao_2')"
              hide-details
              v-model="contrato.dimensao2"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              :label="nomeDimensao('dimensao_3')"
              hide-details
              v-model="contrato.dimensao3"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              type="text"
              :label="nomeDimensao('dimensao_4')"
              hide-details
              v-model="contrato.dimensao4"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <div
          v-if="
            $route.query.tipo == 'CONSORCIO' || $route.query.tipo == 'GENERICO'
          "
        >
          <div class="d-flex mt-8">
            <p class="text-h5 font-weight-medium">Movimentações</p>
            <v-spacer></v-spacer>
            <v-btn
              @click="adicionarMovimentacaoRecorrente()"
              color="primary"
              class="mt-2 mr-1"
              text
              small
              >adicionar recorrente</v-btn
            >
            <v-btn
              @click="adicionarMovimentacaoEspecifica()"
              color="primary"
              class="mt-2"
              text
              small
              >adicionar específica</v-btn
            >
          </div>
          <v-row
            v-for="(movimentacao, index) in arrayMovimentacoesRecorrentes"
            :key="'A' + index"
          >
            <v-col cols="4">
              <v-text-field
                type="text"
                hide-details
                v-model="movimentacao.chave"
                :readonly="
                  $route.query.tipo == 'CONSORCIO' && index == 0 ? true : false
                "
                label="Chave"
                @input="uppercase(index, 'RECORRENTE')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select
                label="Dia do Mês"
                hide-details
                :items="diasDoMes"
                v-model="movimentacao.diaMovimentacao"
                outlined
              ></v-select>
            </v-col>
            <v-col v-if="index == 0">
              <p
                class="grey--text text-caption"
                v-if="$route.query.tipo == 'CONSORCIO'"
              >
                Será criada mensalmente uma movimentação que representa a
                reunião de assembleia no dia do mês informado.
              </p>
              <p class="grey--text text-caption" v-else>
                Será criada mensalmente uma movimentação que representa algum
                evento no dia do mês informado.
              </p>
            </v-col>
            <v-spacer v-if="index > 0"></v-spacer>
            <v-btn
              v-if="index > 0"
              class="mt-6"
              icon
              @click="removerMovimentacaoRecorrente(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-row
            v-for="(
              movimentacaoEspecifica, index
            ) in arrayMovimentacoesEspecificas"
            :key="'B' + index"
          >
            <v-col cols="4">
              <v-text-field
                type="text"
                hide-details
                label="Chave"
                v-model="movimentacaoEspecifica.chave"
                @input="uppercase(index, 'ESPECIFICA')"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="
                      formataData(movimentacaoEspecifica.dataMovimentacao)
                    "
                    outlined
                    append-icon="mdi-calendar"
                    label="Data da Movimentação"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="movimentacaoEspecifica.dataMovimentacao"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pb-0 pt-0" v-if="index == 0">
              <v-col>
                <p class="grey--text text-caption">
                  Cria uma movimentação em uma data específica.
                </p>
              </v-col>
            </v-col>
            <v-spacer v-if="index > 0"></v-spacer>
            <v-btn
              v-if="index > 0"
              class="mt-6"
              icon
              @click="removerMovimentacaoEspecifica(index)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </div>
        <div class="d-flex mt-8">
          <p class="text-h5 font-weight-medium">Parcelas</p>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              $route.query.tipo == 'SEGURO' || $route.query.tipo == 'GENERICO'
            "
            @click="adicionarParcela()"
            color="primary"
            class="mt-2"
            text
            small
            >adicionar parcela</v-btn
          >
        </div>
        <div
          v-if="
            $route.query.tipo != 'SEGURO' && $route.query.tipo != 'GENERICO'
          "
        >
          <v-row>
            <v-col>
              <v-text-field
                type="text"
                label="Quantidade*"
                v-model="parcela.quantidade"
                hide-details
                :rules="[rules.required]"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                label="Valor*"
                v-model="parcela.valor"
                hide-details
                prefix="R$"
                :rules="[rules.required]"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                type="text"
                label="Método de Pagamento*"
                hide-details
                :rules="[rules.required]"
                required
                :items="metodoPagamento"
                v-model="parcela.metodoPagamento"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataVencimentoFormatada"
                    outlined
                    append-icon="mdi-calendar"
                    label="Data de Vencimento*"
                    :rules="[rules.required]"
                    hide-details
                    required
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="parcela.vencimento"
                  :allowed-dates="verificaData"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-select
                label="Dia de Aniversário"
                hide-details
                :items="diasDoMes"
                v-model="parcela.diaAniversario"
                outlined
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                min="1"
                label="Dias de Tolerância*"
                v-model.number="parcela.tolerancia"
                :rules="[rules.required]"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row
            v-for="(parcelaSeguro, index) in arrayParcelas"
            :key="index + parcela.valor"
          >
            <p class="mb-0 mt-7" style="font-size: 14px">
              {{ parcelaSeguro.numero_parcela }}
            </p>
            <v-col>
              <v-text-field
                type="number"
                label="Valor*"
                v-model="parcelaSeguro.valor"
                hide-details
                prefix="R$"
                :rules="[rules.required]"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                type="text"
                label="Método de Pagamento*"
                :rules="[rules.required]"
                required
                hide-details
                :items="metodoPagamento"
                v-model="parcelaSeguro.metodo_pagamento"
                outlined
              ></v-select>
            </v-col>
            <v-col>
              <v-menu
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formataData(parcelaSeguro.data_vencimento)"
                    outlined
                    append-icon="mdi-calendar"
                    label="Data de Vencimento*"
                    :rules="[rules.required]"
                    hide-details
                    required
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  locale="pt-br"
                  v-model="parcelaSeguro.data_vencimento"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                label="Dias de Tolerância"
                v-model="parcelaSeguro.data_tolerancia"
                hide-details
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex mt-5">
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelar()"
            color="primary"
            text
            class="mr-2"
            :disabled="importandoContrato"
            >Cancelar</v-btn
          >
          <v-btn
            @click="importarContrato($event)"
            color="primary"
            :loading="importandoContrato"
            >salvar</v-btn
          >
        </div>
      </v-form>
      <v-alert type="error" class="mt-2 mb-0" v-if="erroImportacao">
        {{ msgErroImportacao }}
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import FakeService from "../../services/fake";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ContratoService from "../../services/contratos";
import MovimentacaoService from "../../services/movimentacao";

export default {
  data: () => ({
    diaMovimentacao: null,
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      diasMes: (value) =>
        (value >= 1 && value <= 31) ||
        value == null ||
        "Valor deve ser de 1 à 31",
    },
    cliente: {
      nome: null,
      documento: null,
      dataNascimento: null,
      codCliente: null,
      perfilCliente: null,
      endereco_rua: null,
      endereco_cep: null,
      endereco_numero: null,
      endereco_cidade: null,
      endereco_uf: null,
    },
    contatoCliente: {
      email: null,
      celular: null,
      fixo: null,
      whatsapp: false,
    },
    contrato: {
      tipo: null,
      codContrato: null,
      composicao: "",
      origem: null,
      canal: null,
      categoria: null,
      risco: null,
      perfilContrato: null,
      dataEmissao: null,
      vigenciaInicioEm: null,
      vigenciaTerminoEm: null,
      perfilEmpresa: null,
      valorDoBem: null,
      dimensao1: null,
      dimensao2: null,
      dimensao3: null,
      dimensao4: null,
    },
    parcela: {
      vencimento: null,
      valor: null,
      quantidade: null,
      tolerancia: null,
      metodoPagamento: null,
      diaAniversario: null,
    },
    perfisCliente: [
      {
        text: "Sem Perfil",
        value: null,
      },
    ],
    perfisContrato: [
      {
        text: "Sem Perfil",
        value: null,
      },
    ],
    perfisEmpresa: [],
    cadastrarEndereco: false,
    maisInformacoes: false,
    metodoPagamento: [
      {
        text: "Boleto",
        value: "BOLETO",
      },
      {
        text: "Débito",
        value: "DEBITO",
      },
    ],
    dataNascimentoFormatada: null,
    dataEmissaoFormatada: null,

    dataVigenciaInicioEmFormatada: null,
    dataVigenciaTerminoEmFormatada: null,

    dataVencimentoFormatada: null,

    arrayParcelas: [
      {
        numero_parcela: 1,
        status: "PENDENTE",
        valor: null,
        data_vencimento: null,
        metodo_pagamento: null,
        linha_digitavel: null,
        data_tolerancia: null,
      },
    ],
    movimentacoes: [],

    importandoContrato: false,
    erroImportacao: false,
    msgErroImportacao: null,

    diasDoMes: [],

    arrayMovimentacoesEspecificas: [
      {
        chave: null,
        dataMovimentacao: null,
      },
    ],

    chaveMovimentacaoRecorrente: null,
    dataMovimentacaoRecorrente: null,
    arrayMovimentacoesRecorrentes: [],
  }),
  async created() {
    if (!this.$route.query.tipo) {
      this.$router.push({ name: "Contratos" }).catch(() => {});
    }
    this.arrayMovimentacoesRecorrentes.push({
      chave: this.$route.query.tipo == "CONSORCIO" ? "ASSEMBLEIA" : null,
      diaMovimentacao: null,
    });
    this.carregaDiasDoMes();

    const perfis = await this.buscaperfisEmpresa();
    if (perfis.length) {
      perfis.forEach((p) => {
        this.perfisEmpresa.push({ text: p.nome_curto, value: p.chave });
      });
      if (this.perfisEmpresa.length === 1) {
        this.contrato.perfilEmpresa = this.perfisEmpresa[0].value;
      }
    } else {
      this.perfisEmpresa = [
        {
          text: this.empresaSelecionada.nome,
          value: this.empresaSelecionada.chave,
        },
      ];
      this.contrato.perfilEmpresa = this.perfisEmpresa[0].value;
    }
  },
  mounted() {
    this.carregaInformacoes();
  },
  methods: {
    ...mapActions("perfisEmpresa", {
      buscaperfisEmpresa: "buscaperfisEmpresa",
    }),
    verificaData(data) {
      let hoje = moment().format("YYYY-MM-DD");
      if (data >= hoje) return true;
      return false;
    },
    async carregaInformacoes() {
      let params = {
        tipo: this.$route.query.tipo,
      };
      await FakeService.getInformacoesFake(params)
        .then((retorno) => {
          this.populaCampos(retorno.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    populaCampos(dados) {
      this.cliente.nome = dados.cliente.nome;
      this.cliente.documento = dados.cliente.documento;
      this.cliente.dataNascimento = dados.cliente.data_nascimento;
      this.cliente.codCliente = dados.cliente.cod_cliente;
      this.cliente.endereco_rua = dados.cliente.endereco_rua;
      this.cliente.endereco_cep = dados.cliente.endereco_cep;
      this.cliente.endereco_uf = dados.cliente.endereco_uf;
      this.cliente.endereco_numero = dados.cliente.endereco_numero;
      this.cliente.endereco_cidade = dados.cliente.endereco_cidade;
      this.verificaPerfisCliente();

      this.contrato.codContrato = dados.contrato.cod_contrato;
      this.contrato.composicao = dados.contrato.composicao;
      this.contrato.origem = dados.contrato.origem;
      this.contrato.canal = dados.contrato.canal;
      this.contrato.categoria = dados.contrato.categoria;
      this.contrato.risco = dados.contrato.risco;
      this.verificaPerfisContrato();

      if (
        this.$route.query.tipo != "SEGURO" &&
        this.$route.query.tipo != "GENERICO"
      ) {
        this.parcela.quantidade = dados.parcela.quantidade;
        this.parcela.valor = dados.parcela.valor;
        this.parcela.metodoPagamento = "BOLETO";
      } else {
        this.arrayParcelas[0].valor = dados.parcela.valor;
        this.arrayParcelas[0].metodo_pagamento = "BOLETO";
      }
    },
    verificaPerfisCliente() {
      this.empresaSelecionada.perfis_regua.perfis_cliente.forEach((perfil) => {
        this.perfisCliente.push({
          text: Object.values(perfil)[0],
          value: Object.keys(perfil)[0],
        });
      });
    },
    verificaPerfisContrato() {
      this.empresaSelecionada.perfis_regua.perfis_contrato.forEach((perfil) => {
        this.perfisContrato.push({
          text: Object.values(perfil)[0],
          value: Object.keys(perfil)[0],
        });
      });
    },
    formataData(data) {
      if (!data) return null;
      const [ano, mes, dia] = data.split("-");
      return `${dia}/${mes}/${ano}`;
    },
    adicionarParcela() {
      let numeroParcela = this.arrayParcelas.length + 1;
      this.arrayParcelas.push({
        numero_parcela: numeroParcela,
        status: "PENDENTE",
        valor: null,
        data_vencimento: null,
        metodo_pagamento: null,
        linha_digitavel: null,
        data_tolerancia: null,
      });
    },
    adicionarMovimentacaoEspecifica() {
      this.arrayMovimentacoesEspecificas.push({
        chave: null,
        dataMovimentacao: null,
      });
    },
    removerMovimentacaoEspecifica(index) {
      this.arrayMovimentacoesEspecificas.splice(index, 1);
    },
    adicionarMovimentacaoRecorrente() {
      this.arrayMovimentacoesRecorrentes.push({
        chave: null,
        diaMovimentacao: null,
      });
    },
    removerMovimentacaoRecorrente(index) {
      this.arrayMovimentacoesRecorrentes.splice(index, 1);
    },
    async importarContrato(event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;
      this.importandoContrato = true;
      try {
        const { data } = await ContratoService.importar(this.montarPayload());
        this.verificaContratoImportado(data.importacao_contrato_id);
      } catch (error) {
        this.importandoContrato = false;
        console.error(error);
      }
    },
    montarPayload() {
      let payload = {
        canal: this.contrato.canal,
        categoria: this.contrato.categoria,
        cod_cliente: this.cliente.codCliente.toString(),
        cod_contrato: this.contrato.codContrato.toString(),
        composicao: this.contrato.composicao,
        data_nascimento: this.cliente.dataNascimento,
        documento_cliente: this.desformatarDocumento(this.cliente.documento),
        endereco_cep: this.cadastrarEndereco ? this.cliente.endereco_cep : null,
        endereco_cidade: this.cadastrarEndereco
          ? this.cliente.endereco_cidade
          : null,
        endereco_numero: this.cadastrarEndereco
          ? this.cliente.endereco_numero
          : null,
        endereco_rua: this.cadastrarEndereco ? this.cliente.endereco_rua : null,
        endereco_uf: this.cadastrarEndereco ? this.cliente.endereco_uf : null,
        email: this.contatoCliente.email,
        nome_cliente: this.cliente.nome,
        notificavel: 1,
        origem: this.contrato.origem,
        perfil_contrato: this.contrato.perfilContrato,
        risco: this.contrato.risco,
        tipo_contrato: this.contrato.tipo,
        vigencia_inicio_em: this.contrato.vigenciaInicioEm,
        vigencia_termino_em: this.contrato.vigenciaTerminoEm,
        perfil_empresa: this.contrato.perfilEmpresa || this.empresaSelecionada.chave,
        metadados: {
          valor_do_bem: this.contrato.valorDoBem
            ? this.contrato.valorDoBem
            : undefined,
        },
        telefones: [
          {
            numero: this.desformatarTelefone(this.contatoCliente.celular),
            tipo: "CELULAR",
            whatsapp: this.contatoCliente.whatsapp,
          },
        ],
      };
      if (this.contatoCliente.fixo) {
        payload["telefones"].push({
          numero: this.desformatarTelefone(this.contatoCliente.fixo),
          tipo: "FIXO",
        });
      }
      if (
        this.$route.query.tipo != "SEGURO" &&
        this.$route.query.tipo != "GENERICO"
      ) {
        if (
          this.parcela.diaAniversario &&
          this.parcela.diaAniversario !=
            moment(this.parcela.vencimento).format("D")
        ) {
          this.arrayParcelas = [];
          this.arrayParcelas.push({
            numero_parcela: 1,
            status: "PENDENTE",
            valor: parseFloat(this.parcela.valor),
            data_vencimento: this.parcela.vencimento,
            metodo_pagamento: this.parcela.metodoPagamento,
            linha_digitavel: null,
            data_tolerancia: parseInt(this.parcela.tolerancia) || null,
          });
          let quantidadeParcelas = this.parcela.quantidade - 1;
          let dataAniversario =
            moment(this.parcela.vencimento).format("YYYY") +
            "-" +
            moment(this.parcela.vencimento).format("MM") +
            "-" +
            this.parcela.diaAniversario;

          for (let cont = 0; cont < quantidadeParcelas; cont++) {
            let data = moment(dataAniversario)
              .add(cont + 1, "months")
              .format("YYYY-MM-DD");
            this.arrayParcelas.push({
              numero_parcela: cont + 2,
              status: "PENDENTE",
              valor: parseFloat(this.parcela.valor),
              data_vencimento: data,
              metodo_pagamento: this.parcela.metodoPagamento,
              linha_digitavel: null,
              data_tolerancia: parseInt(this.parcela.tolerancia) || null,
            });
          }
          payload["parcelas"] = this.arrayParcelas;
        } else {
          payload["linha_digitavel"] = null;
          payload["metodo_pagamento"] = this.parcela.metodoPagamento;
          payload["qtde_parcelas"] = this.parcela.quantidade;
          payload["valor"] = parseFloat(this.parcela.valor);
          payload["data_vencimento"] = this.parcela.vencimento;
          payload["numero_parcela"] = 1;
          payload["tolerancia"] = parseInt(this.parcela.tolerancia) || null;
        }
      } else {
        this.arrayParcelas.forEach((parcela) => {
          parcela.valor = parseFloat(parcela.valor);
          parcela.data_tolerancia = parseInt(parcela.data_tolerancia);
        });
        payload["parcelas"] = this.arrayParcelas;
      }
      return payload;
    },
    cancelar() {
      this.$router.push({ name: "Contratos" }).catch(() => {});
      this.$refs.form.reset();
    },
    nomeDimensao(dimensao) {
      if (!dimensao) {
        return;
      }
      let empresa = this.$store.state.empresa || {};
      let empresaSelecionada = empresa.empresaSelecionada || {};
      let dimensoes = empresaSelecionada.dimensoes_contrato || {};

      return dimensoes[dimensao] || dimensao.replace("dimensao_", "Dimensão ");
    },
    desformatarDocumento(documento) {
      if (!documento) return;
      return documento.replace(/[. -]/g, "");
    },
    desformatarTelefone(celular) {
      if (!celular) return;
      return celular.replace(/[() -]/g, "");
    },
    sleep(ms = 1000) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async verificaContratoImportado(importacaoContratoId) {
      let semRespostaApi = true;
      while (semRespostaApi) {
        await this.sleep();
        const { data } = await ContratoService.statusImportacao(
          importacaoContratoId
        );
        if (data.status == "PROCESSADO") {
          this.erroImportacao = false;
          semRespostaApi = false;
          this.importandoContrato = false;
          if (
            (this.$route.query.tipo == "CONSORCIO" ||
              this.$route.query.tipo == "GENERICO") &&
            (this.arrayMovimentacoesEspecificas[0].chave ||
              this.arrayMovimentacoesRecorrentes[0].chave)
          ) {
            this.cadastrarMovimentacoes(
              this.contrato.vigenciaInicioEm,
              this.contrato.vigenciaTerminoEm,
              data.contrato_id
            );
          }
          this.$router
            .push({ name: "VerContrato", params: { id: data.contrato_id } })
            .catch(() => {});
        }
        if (data.status == "ERRO") {
          this.erroImportacao = true;
          this.msgErroImportacao = data.logs;
          semRespostaApi = false;
          this.importandoContrato = false;
        }
      }
    },
    async cadastrarMovimentacoes(
      vigenciaInicioContrato,
      vigenciaTerminoContrato,
      contrato_id
    ) {
      this.movimentacoes = [];

      this.adicionaMovimentacaoEspecifica(contrato_id);
      this.adicionaMovimentacaoRecorrente(
        vigenciaInicioContrato,
        vigenciaTerminoContrato,
        contrato_id
      );
      try {
        const { data } = await MovimentacaoService.cadastraMovimentacao(
          this.movimentacoes
        );
        this.importandoContrato = false;
      } catch (error) {
        this.erroImportacao = true;
        this.msgErroImportacao = "Erro ao importar o contrato.";
        console.error(error);
        this.importandoContrato = false;
      }
    },
    adicionaMovimentacaoEspecifica(contrato_id) {
      this.arrayMovimentacoesEspecificas.forEach((movimentacao) => {
        if (!movimentacao.chave) return;
        this.movimentacoes.push({
          contrato_id: contrato_id,
          data_referencia: movimentacao.dataMovimentacao,
          chave: movimentacao.chave,
          ativa: true,
        });
      });
    },
    async adicionaMovimentacaoRecorrente(
      vigenciaInicioContrato,
      vigenciaTerminoContrato,
      contrato_id
    ) {
      this.arrayMovimentacoesRecorrentes.forEach((movimentacao) => {
        if (!movimentacao.chave) return;
        let ultimoDiaMes = moment(vigenciaInicioContrato)
          .endOf("month")
          .format("DD");
        let diaSelecionado = String(movimentacao.diaMovimentacao).padStart(
          2,
          "0"
        );
        let diaDaMovimentacao =
          diaSelecionado > ultimoDiaMes ? ultimoDiaMes : diaSelecionado;
        let dataMovimentacao =
          moment(vigenciaInicioContrato).format("YYYY") +
          "-" +
          moment(vigenciaInicioContrato).format("MM") +
          "-" +
          diaDaMovimentacao;
        if (dataMovimentacao < vigenciaInicioContrato) {
          dataMovimentacao = moment(dataMovimentacao)
            .add(1, "months")
            .format("YYYY-MM-DD");
        }
        while (
          dataMovimentacao >= vigenciaInicioContrato &&
          dataMovimentacao <= vigenciaTerminoContrato
        ) {
          let ultimoDiaMesAtual = moment(dataMovimentacao)
            .endOf("month")
            .format("DD");
          if (diaSelecionado >= ultimoDiaMesAtual) {
            dataMovimentacao = moment(dataMovimentacao)
              .endOf("month")
              .format("YYYY-MM-DD");
          }
          this.movimentacoes.push({
            contrato_id: contrato_id,
            data_referencia: dataMovimentacao,
            chave: movimentacao.chave,
            ativa: true,
          });
          dataMovimentacao = moment(dataMovimentacao)
            .add(1, "months")
            .format("YYYY-MM-DD");
        }
      });
    },
    carregaDiasDoMes() {
      for (let cont = 0; cont < 31; cont++) {
        this.diasDoMes.push({
          text: cont + 1 + "º dia",
          value: cont + 1,
        });
      }
    },
    uppercase(index, tipo) {
      if (tipo == "ESPECIFICA") {
        this.arrayMovimentacoesEspecificas[index].chave =
          this.arrayMovimentacoesEspecificas[index]?.chave?.toUpperCase();
      } else {
        this.arrayMovimentacoesRecorrentes[index].chave =
          this.arrayMovimentacoesRecorrentes[index]?.chave?.toUpperCase();
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
  watch: {
    "cliente.dataNascimento"() {
      if (!this.cliente.dataNascimento) return;
      this.dataNascimentoFormatada = this.formataData(
        this.cliente.dataNascimento
      );
    },
    "contrato.dataEmissao"() {
      this.dataEmissaoFormatada = this.formataData(this.contrato.dataEmissao);
    },
    "contrato.vigenciaInicioEm"() {
      this.dataVigenciaInicioEmFormatada = this.formataData(
        this.contrato.vigenciaInicioEm
      );
    },
    "contrato.vigenciaTerminoEm"() {
      this.dataVigenciaTerminoEmFormatada = this.formataData(
        this.contrato.vigenciaTerminoEm
      );
    },
    "parcela.vencimento"() {
      this.dataVencimentoFormatada = this.formataData(this.parcela.vencimento);
    },
  },
};
</script>

<style lang="scss" scoped>
.principal {
  max-width: 1000px;
}

:deep(.v-input--selection-controls) {
  margin-top: 0px;
}
</style>
