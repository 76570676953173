<template>
  <v-speed-dial
    v-model="fabs[day]"
    direction="right"
    class="my-auto"
    :id="'qj-speed-dial-' + day"
  >
    <template v-slot:activator>
      <v-btn
        v-model="fabs[day]"
        :color="day == 0 ? 'red' : isImportant ? 'blue' : 'grey'"
        fab
        depressed
        :small="isImportant && day != 0"
        :x-small="!isImportant && day != 0"
      >
        <v-icon
          color="white"
          :large="day == 0"
          :small="!isImportant && day != 0"
          v-if="fabs[day]"
        >
          mdi-close
        </v-icon>
        <v-icon
          color="white"
          :large="day == 0"
          :small="!isImportant && day != 0"
          v-else
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </template>

    <v-chip
      v-for="evento in eventos"
      :key="evento"
      class="mx-1 text-subtitle-2 tipo-evento"
      :class="evento"
      @click="newEvent(evento)"
    >
      {{ evento | tipoEvento }}
    </v-chip>
  </v-speed-dial>
</template>

<script>
import { TIPO_EVENTO } from "@/store/enum";

export default {
  name: "EndButtonTimeline",
  props: {
    day: {
      type: Number,
      require: true,
    },
    isImportant: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    eventos: TIPO_EVENTO,
    fabs: {},
  }),
  watch: {
    fabs: {
      handler: async function (abrir) {
        var speedDial = document.querySelector(`#qj-speed-dial-${this.day}`);
        var buttonRect = speedDial.getBoundingClientRect();
        var screenWidth =
          window.innerWidth || document.documentElement.clientWidth;

        if (Object.values(abrir)[0] == true) {
          var container = document.querySelector(".qj-timeline");
          await new Promise((resolve) => setTimeout(resolve, 200));

          if (buttonRect.right + 45 > screenWidth) {
            container.scrollLeft =
              container.scrollWidth - container.clientWidth;
          } else if (buttonRect.right > screenWidth / 1.75) {
            container.scrollLeft = buttonRect.x + buttonRect.width;
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    newEvent(type) {
      this.$emit("open", {
        tipo: type,
        dia: this.day,
      });
    },
  },
};
</script>

<style></style>
