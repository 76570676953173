<template>
  <v-container class="elevation-7 my-6 pa-6 container rounded">
    <v-app-bar app>
      <v-btn
        icon
        exact
        :to="{
          name: 'VerCliente',
          params: { id: $route.query.cliente_id },
        }"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="text-h6 font-weight-bold ml-3">Histórico de Notificações</div>
    </v-app-bar>
    <v-row>
      <v-col cols="auto">
        <p class="text-h5 mb-0">Notificações ({{ notificacoes.length || 0 }})</p>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn text color="primary" @click="abrirDialogFiltros()"> filtrar </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="filtrosAplicados.length && !erroCarregarNotificacoes" class="flex-column">
      <v-col class="py-0">
        <p class="text-subtitle-1 mb-1">Filtros Aplicados</p>
      </v-col>
      <v-col cols="auto pt-0 pl-2">
        <v-chip
          v-for="(filtro, index) in filtrosAplicados"
          :key="index"
          class="ma-1"
          color="black"
          outlined
          close
          @click:close="removeFiltros(filtro)"
        >
          {{ mostrarNome(filtro) }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-progress-circular
        v-if="carregandoNotificacoes && !erroCarregarNotificacoes"
        indeterminate
        color="primary"
        class="d-flex mx-auto my-8 pb-8"
      ></v-progress-circular>
      <v-alert
        type="error"
        prominent
        text
        class="mx-auto mt-3"
        v-if="erroCarregarNotificacoes && !carregandoNotificacoes"
      >
        <v-row align="center">
          <v-col class="grow">Erro ao carregar as notificações.</v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="carregarNotificacoes()"> Tentar novamente </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-col
        cols="12"
        v-if="!erroCarregarNotificacoes && !carregandoNotificacoes"
        id="notifications"
      >
        <v-divider
          v-if="!erroCarregarNotificacoes && !carregandoNotificacoes && notificacoes?.length"
        ></v-divider>
        <p
          class="text-center mt-3 text-body-1 grey--text"
          v-if="!notificacoes?.length && !erroCarregarNotificacoes && !carregandoNotificacoes"
        >
          Nenhuma notificação encontrada!
        </p>
        <div id="listaNotificacao">
          <v-list
            v-for="(notificacao, index) in notificacoes"
            :key="notificacao.id"
            color="transparent"
            class="py-0"
          >
            <v-list-item
              @mouseover="selecionaItem(notificacao)"
              @mouseleave="desselecionarItem()"
              class="pa-0"
            >
              <v-list-item-content class="d-flex">
                <v-list-item-title>
                  <div class="notificacao-row">
                    <div class="notificacao-hora" cols="2">
                      <p class="mb-0 grey--text text-body-2 pt-2">
                        {{ notificacao.criado_em | moment("L") }} às
                        {{ formatarHorario(notificacao.criado_em) }}
                      </p>
                    </div>
                    <div class="chips-notificacao pa-0 ma-0" offset="3" cols="9">
                      <div
                        v-if="notificacao.tipo_notificacao === 'TESTE_EVENTO'"
                        class="flex-grow-0 pa-0"
                        cols="auto"
                      >
                        <v-chip class="text-center">
                          {{ notificacao.tipo_notificacao | tipoNotificacao }}
                        </v-chip>
                      </div>
                      <div
                        v-if="notificacao.status == 'NAO_ENVIADO' || notificacao.status == 'ERRO'"
                        class="flex-grow-0 pa-0"
                        cols="auto"
                      >
                        <v-chip class="text-center" :color="notificacao.status">
                          {{ notificacao.status | statusNotificacao }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon color="yellow" class="ml-1" v-bind="attrs" v-on="on" small
                                >mdi-alert</v-icon
                              >
                            </template>
                            <span>{{
                              notificacao.motivo_erro ||
                              notificacao.motivo_nao_enviado ||
                              notificacao.motivo
                            }}</span>
                          </v-tooltip>
                        </v-chip>
                      </div>
                      <div v-else class="flex-grow-0 pa-0">
                        <v-tooltip :disabled="notificacao.status !== 'ENVIADO'" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              class="text-center"
                              :color="notificacao.status"
                              v-if="notificacao.status"
                            >
                              {{ notificacao.status | statusNotificacao }}
                            </v-chip>
                          </template>
                          <span
                            >{{ notificacao.enviado_em | moment("L") }} às
                            {{ formatarHorario(notificacao.enviado_em) }}</span
                          >
                        </v-tooltip>
                      </div>
                      <div class="flex-grow-0 pa-0">
                        <v-chip class="text-center" :color="notificacao?.tipo">
                          {{ notificacao?.tipo | canais }}
                        </v-chip>
                      </div>
                      <div
                        v-if="
                          notificacao.tipo_notificacao == 'REGUA_COMUNICACAO' ||
                          notificacao.tipo_notificacao == 'TESTE_EVENTO'
                        "
                        class="flex-grow-0 pa-0"
                      >
                        <v-chip class="text-center white--text" color="#9C27B0">
                          {{ retornarTextoNotificacao(notificacao) }}
                        </v-chip>
                      </div>
                      <div
                        v-if="
                          notificacao.tipo_notificacao == 'REGUA_COMUNICACAO' ||
                          notificacao.tipo_notificacao == 'TESTE_EVENTO'
                        "
                        class="flex-grow-0 pa-0"
                      >
                        <v-chip class="text-center white--text" :color="notificacao?.regua?.tipo">
                          {{ notificacao?.regua?.tipo | tipoRegua }}
                        </v-chip>
                      </div>
                      <div
                        v-if="
                          notificacao?.regua?.tipo == 'MOVIMENTACAO' &&
                          notificacao?.regua?.tipo_movimentacao
                        "
                        class="flex-grow-0 pa-0"
                      >
                        <v-chip class="text-center white--text" :color="notificacao?.regua?.tipo">
                          {{ notificacao.regua.tipo_movimentacao.nome }}
                        </v-chip>
                      </div>
                      <div
                        v-if="
                          notificacao.tipo_notificacao == 'FLUXO_AUTOMATICO' &&
                          carregandoFluxoAutomatico
                        "
                        class="flex-grow-0 pa-0"
                      >
                        <v-skeleton-loader type="chip"></v-skeleton-loader>
                      </div>
                      <div
                        v-if="
                          notificacao.tipo_notificacao == 'FLUXO_AUTOMATICO' &&
                          !carregandoFluxoAutomatico
                        "
                        class="flex-grow-0 pa-0"
                      >
                        <v-chip class="text-center white--text" color="#607D8B">
                          Fluxo Automático
                        </v-chip>
                      </div>
                      <div
                        v-if="
                          notificacao.tipo_notificacao == 'FLUXO_AUTOMATICO' &&
                          !carregandoFluxoAutomatico
                        "
                        class="flex-grow-0 pa-0"
                      >
                        <v-chip class="text-center white--text" color="#9C27B0">
                          {{ retornarTextoNotificacao(notificacao) }}
                        </v-chip>
                      </div>
                      <div
                        v-if="
                          notificacao.tipo_notificacao != 'FLUXO_AUTOMATICO' &&
                          notificacao.tipo_notificacao != 'REGUA_COMUNICACAO' &&
                          notificacao.tipo_notificacao != 'TESTE_EVENTO'
                        "
                        class="flex-grow-0 pa-0"
                      >
                        <v-chip>
                          {{ notificacao.tipo_notificacao }}
                        </v-chip>
                      </div>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action style="min-width: 30px" class="ma-0">
                <v-menu v-if="notificacao === itemSelecionado" v-model="menuAberto">
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" class="ma-0">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="abrirDialogJson(notificacao, 'Debug Notificação')">
                      <v-list-item-title> Detalhes </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="mostrarOpcaoEvidencia(notificacao?.tipo, notificacao?.status)"
                      @click="abrirDialogEvidencia(notificacao)"
                    >
                      <v-list-item-title> Evidência </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < notificacoes?.length - 1"></v-divider>
          </v-list>
        </div>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="carregandoProximaPagina && !erroCarregarNotificacoes"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <DialogJson ref="dialogJson" />
    <DialogEvidencia ref="dialogEvidencia" :service="notificacaoService" />
    <DialogFiltros
      ref="dialogFiltros"
      :contratos="contratos"
      :carregandoFiltros="carregandoNotificacoes"
      @aplicarFiltros="carregarNotificacoes"
    />
  </v-container>
</template>

<script>
import notificacaoService from "@/services/notificacao.js";
import DialogJson from "../components/DialogJson.vue";
import DialogEvidencia from "../components/DialogEvidencia.vue";
import DialogFiltros from "../components/DialogFiltros.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import moment from "moment";
import { stringToColor } from "@/assets/js/utils";

export default {
  components: {
    DialogJson,
    DialogFiltros,
    DialogEvidencia,
  },
  data: () => ({
    bottom: false,
    notificacoes: [],
    fluxoAutomatico: null,
    menuAberto: false,
    itemSelecionado: false,
    carregandoNotificacoes: false,
    erroCarregarNotificacoes: false,
    carregandoFluxoAutomatico: false,
    pagina: 1,
    totalPaginas: 0,
    carregandoProximaPagina: false,
    tiposComEvidencia: ["EMAIL", "PUSH", "SMS", "FAST_SMS", "WHATSAPP", "COMANDO"],
    statusComEvidencia: ["ENVIADO", "RECEBIDO", "ABRIU", "CLICOU", "VISUALIZADO"],
    notificacaoService: notificacaoService,
    stringToColor
  }),
  async mounted() {
    const scrollableEl = document.querySelector(".v-main__wrap");
    scrollableEl.addEventListener("scroll", () => {
      this.bottom = this.bottomVisible();
    });
    await this.carregarContratos();
    const queryParams = this.$route.query;
    for (let [chave, valor] of Object.entries(queryParams)) {
      if (chave == "EVENTO") {
        this.setFiltrosTipoEvento(valor.split(","));
      } else if (chave == "REGUA") {
        this.setFiltrosTipoRegua(valor.split(","));
      } else if (chave == "NOTIFICACAO") {
        this.setFiltrosTipoNotificacao(valor.split(","));
      } else if (chave == "STATUS_NOTIFICACAO") {
        this.setFiltrosStatusNotificacao(valor.split(","));
      } else if (chave == "DATA") {
        const payload = valor.split(",");
        this.setFiltrosData(payload);
      } else if (chave == "CONTRATO") {
        let payload = this.contratos.objects.find((contrato) => contrato.id == valor);
        this.setFiltroContrato(payload);
      }
    }
    this.setFiltrosAplicados();
    await this.carregarNotificacoes();
  },
  methods: {
    ...mapActions("contratos", {
      getContratos: "getContratos",
      limparContratos: "limparContratos",
    }),
    ...mapActions("filtros", {
      removeFiltros: "removeFiltros",
    }),
    ...mapMutations("filtros", [
      "setFiltrosTipoEvento",
      "setFiltrosTipoRegua",
      "setFiltrosTipoNotificacao",
      "setFiltrosStatusNotificacao",
      "setFiltrosData",
      "setFiltroContrato",
      "setFiltrosAplicados",
      "setFiltroContrato",
    ]),
    retornarTextoNotificacao(notificacao) {
      if (
        notificacao.tipo_notificacao === "REGUA_COMUNICACAO" ||
        notificacao.tipo_notificacao === "TESTE_EVENTO"
      ) {
        if (notificacao.dia < 0) {
          return `${Math.abs(notificacao.dia)} ${
            notificacao.dia == -1 ? "dia antes" : "dias antes"
          }`;
        } else if (notificacao.dia > 0) {
          return `${notificacao.dia} ${notificacao.dia == 1 ? "dia depois" : "dias depois"}`;
        } else {
          return "No dia";
        }
      } else {
        let fluxoSelecionado = this.fluxoAutomatico.objects?.find((fluxo) => {
          return fluxo.id == notificacao.fluxo_automatico_id;
        });
        if (fluxoSelecionado?.tipo) {
          return `${Vue.filter("tipoFluxoAutomatico")(fluxoSelecionado?.tipo)}`;
        }
      }
    },
    bottomVisible() {
      const rect = document.getElementById("listaNotificacao").getBoundingClientRect();
      return rect.bottom <= window.innerHeight;
    },
    selecionaItem(item) {
      if (!this.menuAberto) this.itemSelecionado = item;
    },
    desselecionarItem() {
      if (!this.menuAberto) this.itemSelecionado = false;
    },
    formataFiltroData(data) {
      if (data) {
        if (Array.isArray(data["valor"])) return data["valor"].join(",");
        return data["valor"];
      }
      return undefined;
    },
    async carregaFluxoAutomatico(fluxosId) {
      this.carregandoFluxoAutomatico = true;
      let params = {
        cliente_id: this.$route.query.cliente_id,
        _id: fluxosId.join(),
      };
      await notificacaoService.getFluxoAutomatico(params).then((resultado) => {
        this.fluxoAutomatico = resultado.data;
        this.carregandoFluxoAutomatico = false;
      });
    },
    async carregarNotificacoes(proximaPagina) {
      proximaPagina ? (this.carregandoProximaPagina = true) : (this.carregandoNotificacoes = true);

      this.erroCarregarNotificacoes = false;
      let params = {
        ordem: "-id",
        incluir: "REGUAS,TIPO_MOVIMENTACAO",
        empresa_id: this.empresaSelecionada.id,
        cliente_id: this.$route.query.cliente_id,
        contrato_id: this.filtroContrato[0]?.valor?.id || undefined,
        data_notificacao: this.formataFiltroData(this.filtrosData),
        tipo_evento: this.filtrosTipoEvento?.length
          ? this.filtrosTipoEvento.map((filtro) => filtro.valor).join()
          : undefined,
        tipo_notificacao: this.filtrosTipoNotificacao?.length
          ? this.filtrosTipoNotificacao.map((filtro) => filtro.valor).join()
          : undefined,
        status_notificacao: this.filtrosStatusNotificacao?.length
          ? this.filtrosStatusNotificacao.map((filtro) => filtro.valor).join()
          : undefined,
        tipo_regua: this.filtrosTipoRegua?.length
          ? this.filtrosTipoRegua.map((filtro) => filtro.valor).join()
          : undefined,
        limite: 20,
        pagina: proximaPagina ? this.pagina : 1,
      };

      await notificacaoService
        .getNotificacoes(params)
        .then((retorno) => {
          if (proximaPagina) {
            this.notificacoes = [...this.notificacoes, ...retorno.data.objects];
          } else {
            this.notificacoes = retorno.data.objects;
          }
          this.pagina = retorno.data.page;
          this.totalPaginas = retorno.data.total_pages;
          this.carregandoNotificacoes = false;
          this.carregandoProximaPagina = false;
          this.erroCarregarNotificacoes = false;

          let fluxos = [];
          this.notificacoes.forEach((notificacao) => {
            if (
              notificacao.tipo_notificacao == "FLUXO_AUTOMATICO" &&
              notificacao.fluxo_automatico_id
            ) {
              return fluxos.push(notificacao.fluxo_automatico_id);
            } else {
              return;
            }
          });
          if (!fluxos?.length) return;
          this.carregaFluxoAutomatico(fluxos);
        })
        .catch((erro) => {
          console.error(erro);
          this.carregandoNotificacoes = false;
          this.carregandoProximaPagina = false;
          this.erroCarregarNotificacoes = true;
        });
    },
    async carregarContratos() {
      let params = {
        cliente_id: this.$route.query.cliente_id,
      };
      await this.getContratos(params);
    },
    abrirDialogJson(dados, titulo) {
      this.$refs.dialogJson.abrirDialog(dados, titulo);
    },
    abrirDialogFiltros() {
      this.$refs.dialogFiltros.abrirDialog(this.contratos);
    },
    abrirDialogEvidencia(notificacao) {
      let { id, tipo } = notificacao;
      this.$refs.dialogEvidencia.abrirDialog(id, tipo);
    },
    mostrarOpcaoEvidencia(tipo, status) {
      return this.tiposComEvidencia.includes(tipo) && this.statusComEvidencia.includes(status);
    },
    mostrarNome({ nome, tipoFiltro = "" }) {
      if (tipoFiltro != "DATA") return nome;

      if (nome.includes("~")) {
        let [comeco, fim] = nome.split("~");
        return `${moment(new Date(comeco)).format("L")} ~ ${moment(new Date(fim)).format("L")}`;
      } else {
        return moment(nome).format("L");
      }
    },
    formatarHorario(horario) {
      var local = moment.utc(horario).local().format("HH:mm");
      return local;
    },
    limparFiltros() {
      this.filtrosAplicados.forEach((filtro) => {
        this.removeFiltros(filtro);
      });
    },
  },
  watch: {
    empresaSelecionada: {
      async handler() {
        this.trocandoEmpresa = true;
        this.limparFiltros();
        this.limparContratos();
        await this.carregarContratos();
        await this.carregarNotificacoes();
        this.trocandoEmpresa = false;
      },
    },
    filtrosAplicados: {
      handler(newValue, oldValue) {
        if (this.trocandoEmpresa) return;
        if (newValue.length < oldValue.length) {
          this.carregarNotificacoes();
        }
        const parametrosQuery = {};
        this.filtrosAplicados.forEach((filtro) => {
          if (Array.isArray(filtro.valor) && filtro.valor.length > 1) {
            filtro.valor = filtro.valor.join(",");
          }
          if (parametrosQuery[filtro.tipoFiltro]) {
            Object.assign(parametrosQuery, {
              [filtro.tipoFiltro]: parametrosQuery[filtro.tipoFiltro] + "," + filtro.valor,
            });
          } else {
            Object.assign(parametrosQuery, {
              [filtro.tipoFiltro]: filtro.valor,
            });
          }
        });
        this.filtroContrato.forEach((filtro) => {
          Object.assign(parametrosQuery, {
            [filtro.tipoFiltro]: filtro.valor.id,
          });
        });
        this.$router
          .replace({
            query: {
              cliente_id: this.$route.query.cliente_id,
              ...parametrosQuery,
            },
          })
          .catch(() => {});
      },
      deep: true,
    },
    bottom: {
      handler() {
        if (this.bottom) {
          if (this.pagina < this.totalPaginas && !this.carregandoProximaPagina) {
            this.pagina = this.pagina + 1;
            this.carregarNotificacoes(true);
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("contratos", {
      contratos: "contratos",
    }),
    ...mapGetters("filtros", {
      filtrosTipoEvento: "filtrosTipoEvento",
      filtrosTipoRegua: "filtrosTipoRegua",
      filtrosTipoNotificacao: "filtrosTipoNotificacao",
      filtrosStatusNotificacao: "filtrosStatusNotificacao",
      filtrosData: "filtrosData",
      filtroContrato: "filtroContrato",
      filtrosAplicados: "todosFiltros",
    }),
  },
  destroyed() {
    this.limparContratos();
    this.limparFiltros();
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1000px !important;
}
.notificacao-row {
  display: grid;
  grid-template-columns: 140px auto;
  gap: 1rem;
  align-items: center;
  .notificacao-hora {
    min-width: 140px;
  }
  .chips-notificacao {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    gap: 0.25rem;
  }
}

// CANAIS
.EMAIL {
  background-color: #ff9800 !important;
  color: white !important;
}
.FAST_SMS {
  background-color: #075e54 !important;
  color: white !important;
}
.LIGACAO_CELULAR {
  background-color: #2196f3 !important;
  color: white !important;
}
.PUSH {
  background-color: #388e3c !important;
  color: white !important;
}
.SMS {
  background-color: #3f51b5 !important;
  color: white !important;
}
.COMANDO {
  background-color: #555 !important;
  color: white !important;
}
.WHATSAPP {
  background-color: #1b5e20 !important;
  color: white !important;
}

// STATUS
.PENDENTE {
  background-color: #2196f3 !important;
  color: white !important;
}
.ENVIADO {
  background-color: #4caf50 !important;
  color: white !important;
}
.NAO_ENVIADO {
  background-color: #f44336 !important;
  color: white !important;
}
.ERRO {
  background-color: #f44336 !important;
  color: white !important;
}
.ATENDIDA {
  background-color: #4caf50 !important;
  color: white !important;
}
.NAO_ATENDIDA {
  background-color: #f44336 !important;
  color: white !important;
}
.RECEBIDO {
  background-color: #4caf50 !important;
  color: white !important;
}
.ABRIU {
  background-color: #4caf50 !important;
  color: white !important;
}
.CLICOU {
  background-color: #4caf50 !important;
  color: white !important;
}
.VISUALIZADO {
  background-color: #4caf50 !important;
  color: white !important;
}

// TIPO REGUA

.IMPLANTACAO {
  background-color: #ffc107 !important;
  color: white !important;
}

.VENCIMENTO_BOLETO {
  background-color: teal !important;
  color: white !important;
}

.VENCIMENTO_DEBITO {
  background-color: teal !important;
  color: white !important;
}

.TOLERANCIA_BOLETO {
  background-color: purple !important;
  color: white !important;
}

.TOLERANCIA_DEBITO {
  background-color: purple !important;
  color: white !important;
}

.INICIO_CONTRATO {
  background-color: blue !important;
  color: white !important;
}

.TERMINO_CONTRATO {
  background-color: blue !important;
  color: white !important;
}

.CONTRATO_CANCELADO {
  background-color: green !important;
  color: white !important;
}

.CONTRATO_FINALIZADO {
  background-color: green !important;
  color: white !important;
}

.BOLETO_SOLICITADO {
  background-color: brown !important;
  color: white !important;
}

.PARCELA_BOLETO_CRIADA {
  background-color: grey !important;
  color: white !important;
}

.PARCELA_DEBITO_CRIADA {
  background-color: grey !important;
  color: white !important;
}

.PAGAMENTO_BOLETO_RECEBIDO {
  background-color: orange !important;
  color: white !important;
}

.PAGAMENTO_DEBITO_RECEBIDO {
  background-color: orange !important;
  color: white !important;
}

.ANIVERSARIO_DO_CLIENTE {
  background-color: indigo !important;
  color: white !important;
}

.MOVIMENTACAO {
  background-color: black !important;
  color: white !important;
}


</style>
