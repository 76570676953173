<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent max-width="80vh">
    <v-card>
      <v-card-title class="text-h5">
        <span>{{ faq.id ? "Editar " : "Nova " }}FAQ</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"
          ><v-icon class="white--text">mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form" class="pt-6" v-model="form">
          <v-row>
            <v-col cols="6" class="py-0">
              <v-combobox
                counter="256"
                :rules="[rules.required]"
                maxLength="256"
                :items="categorias"
                outlined
                label="Categoria"
                v-model="faq.categoria"
              >
              </v-combobox>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                :rules="[podeCriarFaqSemChave]"
                :items="chaves"
                outlined
                label="Perfil"
                clearable
                v-model="faq.chave_empresa"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                counter="256"
                maxLength="256"
                :rules="[rules.required]"
                outlined
                label="Pergunta"
                v-model="faq.pergunta"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <!-- <v-textarea rows="8" :rules="[rules.required]" outlined label="Resposta"
                v-model="faq.resposta"></v-textarea> -->
              <quill-editor
                v-model="faq.resposta"
                ref="myQuillEditor"
                :options="editorOption"
                :rules="[rules.required]"
              >
              </quill-editor>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="dialog = false">
          Cancelar
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
export default {
  props: {
    categorias: {
      type: Array,
      required: false,
    },
    chaves: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      dialog: false,
      faq: {},
      form: null,
      loading: false,
      rules: {
        required: (v) => !!v || "Campo Obrigatorio",
      },
      editorOption: {
        placeholder: "Insira a resposta...",
      },
    };
  },
  methods: {
    ...mapActions("faq", ["criaFaq", "alteraFaq"]),
    ...mapActions("snackbar", ["showSnackBar"]),
    open(faq = {}, categoria) {
      this.dialog = true;
      this.faq = faq;

      if (categoria) {
        faq.categoria = this.categorias.find((cat) => cat === categoria);
      }
    },
    async save() {
      await this.$refs.form.validate();
      if (!this.form) return;
      this.loading = true;
      try {
        await this.salvarFaq();
        this.showSnackBar({ text: "Faq salva com sucesso", type: "success" });
        this.dialog = false;
      } catch (e) {
        this.showSnackBar({ text: "Falha ao salvar a faq", type: "error" });
      } finally {
        this.loading = false;
      }
    },
    async salvarFaq() {
      try {
        if (this.faq.id) {
          await this.alteraFaq(this.faq);
        } else {
          this.faq.ordem = 1;
          await this.criaFaq(this.faq);
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    podeCriarFaqSemChave(value) {
      const podeEditarTodasFaqs = checkCredentials([
        "admin",
        "editar_faq_melembre",
      ]);
      if (podeEditarTodasFaqs) {
        return true;
      }
      return this.rules.required();
    },
  },
  computed: {
    chavesEmpresa() {
      return {
        text: "test",
        value: "test",
      };
    },
  },
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style lang="scss">
.ql-container {
  height: 300px;
  border-radius: 0px 0px 4px 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
  border-top: 0px !important;
}
.ql-toolbar {
  border-radius: 4px 4px 0px 0px !important;
  border: 1px solid rgba(0, 0, 0, 0.38) !important;
}
</style>
