<template>
  <div class="mb-4 mt-4">
    <!-- REGUA A -->
    <div class="regua_A mb-6">
      <v-row class="mb-4">
        <v-icon color="#ef5350" class="ml-1"> mdi-alpha-a-circle-outline </v-icon>
        <div
          v-if="reguaA.eventos_info"
          class="shrink flex-row align-center ml-2"
          :class="{
            'indigo--text text--accent-3': reguaA.status == 'PRODUCAO',
          }"
        >
          <div
            class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
            v-if="reguaA.eventos_info.quantidade_eventos > 0"
            @click.stop="handleRedirectEvento(reguaA.id)"
          >
            <v-chip
              outlined
              link
              small
              :color="reguaA.status !== 'PRODUCAO' ? 'grey' : 'indigo accent-3'"
              class="mr-2 d-flex flex-column white--text regua__evento__text"
            >
              <span class="text-subtitle-2 font-weight-black">
                {{ reguaA.eventos_info.quantidade_eventos }}
                {{ reguaA.eventos_info.quantidade_eventos > 1 ? "eventos" : "evento" }}
                <span
                  v-if="reguaA.tipo != 'IMPLANTACAO' && reguaA.eventos_info.janela_eventos > 1"
                  class="text-secondary text-caption font-weight-light events__sub"
                >
                  em
                  {{ reguaA.eventos_info.janela_eventos }}
                  {{ reguaA.eventos_info.janela_eventos > 1 ? "dias" : "dia" }}
                </span>
              </span>
            </v-chip>
          </div>
          <div v-else>
          <div
            class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
            @click.stop="handleRedirectEvento(reguaA.id)"
          >
            <v-chip
              outlined
              link
              small
              color="grey"
              class="mr-2 d-flex flex-column white--text regua__evento__text"
            >
              <span class="text-subtitle-2 font-weight-black"> Nenhum evento ativo </span>
            </v-chip>
          </div>
        </div>
        </div>
      </v-row>
      <v-row>
        <div v-for="evento in listarEventosPorDia(reguaA.eventos)" :key="evento.id">
          <v-card style="min-width: 100px; height: 70px" class="mx-1 rounded-lg mb-2">
            <v-card-title
              :style="{
                'background-color': retornaCorCard(evento.dia),
              }"
              class="px-2 pt-1 pb-0 justify-center subtitle-2 white--text"
            >
              {{ retornaDiaEvento(evento, reguaA) }}
            </v-card-title>

            <v-card-text class="text-center pt-2">
              <v-tooltip bottom v-if="contadorEventos(reguaA, 'EMAIL', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-email </v-icon>
                </template>

                <span>Notificação E-MAIL</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaA, 'SMS', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-message </v-icon>
                </template>

                <span>Notificação SMS</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaA, 'WHATSAPP', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-whatsapp </v-icon>
                </template>

                <span>Notificação WhatsApp</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaA, 'PUSH', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-bell </v-icon>
                </template>

                <span>Notificação PUSH</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaA, 'LIGACAO_CELULAR', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-cellphone </v-icon>
                </template>

                <span>Notificação LIGAÇÃO CELULAR</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaA, 'LIGACAO_FIXO', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-phone </v-icon>
                </template>

                <span>Notificação LIGAÇÃO FIXO</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaA, 'COMANDO', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-xml </v-icon>
                </template>

                <span>Notificação COMANDO</span><br />
              </v-tooltip>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </div>

    <!-- REGUA B -->
    <div class="regua_B">
      <v-row class="mb-4">
        <v-icon color="#42A5F5" class="ml-1"> mdi-alpha-b-circle-outline </v-icon>
        <div
          v-if="reguaB.eventos_info"
          class="shrink flex-row align-center ml-2"
          :class="{
            'indigo--text text--accent-3': reguaB.status == 'PRODUCAO',
          }"
        >
          <div
            class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
            v-if="reguaB.eventos_info.quantidade_eventos > 0"
            @click.stop="handleRedirectEvento(reguaB.id)"
          >
            <v-chip
              outlined
              link
              small
              :color="reguaB.status !== 'PRODUCAO' ? 'grey' : 'indigo accent-3'"
              class="mr-2 d-flex flex-column white--text regua__evento__text"
            >
              <span class="text-subtitle-2 font-weight-black">
                {{ reguaB.eventos_info.quantidade_eventos }}
                {{ reguaB.eventos_info.quantidade_eventos > 1 ? "eventos" : "evento" }}
                <span
                  v-if="reguaB.tipo != 'IMPLANTACAO' && reguaB.eventos_info.janela_eventos > 1"
                  class="text-secondary text-caption font-weight-light events__sub"
                >
                  em
                  {{ reguaB.eventos_info.janela_eventos }}
                  {{ reguaB.eventos_info.janela_eventos > 1 ? "dias" : "dia" }}
                </span>
              </span>
            </v-chip>
          </div>

          <div v-else>
            <div
              class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
              @click.stop="handleRedirectEvento(reguaB.id)"
            >
              <v-chip
                outlined
                link
                small
                color="grey"
                class="mr-2 d-flex flex-column white--text regua__evento__text"
              >
                <span class="text-subtitle-2 font-weight-black"> Nenhum evento ativo </span>
              </v-chip>
            </div>
          </div>
        </div>
      </v-row>
      <v-row>
        <div v-for="evento in listarEventosPorDia(reguaB.eventos)" :key="evento.id">
          <v-card style="min-width: 100px; height: 70px" class="mx-1 rounded-lg mb-2">
            <v-card-title
              :style="{
                'background-color': retornaCorCard(evento.dia),
              }"
              class="px-2 pt-1 pb-0 justify-center subtitle-2 white--text"
            >
              {{ retornaDiaEvento(evento, reguaB) }}
            </v-card-title>

            <v-card-text class="text-center pt-2">
              <v-tooltip bottom v-if="contadorEventos(reguaB, 'EMAIL', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-email </v-icon>
                </template>

                <span>Notificação E-MAIL</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaB, 'SMS', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-message </v-icon>
                </template>

                <span>Notificação SMS</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaB, 'WHATSAPP', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-whatsapp </v-icon>
                </template>

                <span>Notificação WhatsApp</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaB, 'PUSH', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-bell </v-icon>
                </template>

                <span>Notificação PUSH</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaB, 'LIGACAO_CELULAR', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-cellphone </v-icon>
                </template>

                <span>Notificação LIGAÇÃO CELULAR</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaB, 'LIGACAO_FIXO', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-phone </v-icon>
                </template>

                <span>Notificação LIGAÇÃO FIXO</span><br />
              </v-tooltip>

              <v-tooltip bottom v-if="contadorEventos(reguaB, 'COMANDO', evento.dia)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-xml </v-icon>
                </template>

                <span>Notificação COMANDO</span><br />
              </v-tooltip>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  props: {
    regua: {
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      reguaB: null,
    };
  },
  async created() {
    const reguaDesafiante = this.getReguaById(this.regua.id_regua_desafiante);

    if (this.regua.tipo_teste_ab === "A") {
      this.reguaA = this.regua;
      this.reguaB = reguaDesafiante;
    } else {
      this.reguaB = this.regua;
      this.reguaA = reguaDesafiante;
    }
  },
  computed: {
    ...mapGetters("reguas", {
      reguas: "reguas",
      getReguaById: "getReguaById",
    }),
  },
  methods: {
    handleRedirectEvento(reguaId) {
      this.$emit("redirectEvento", reguaId);
    },

    listarEventosPorDia(eventos) {
      const eventosFiltrados = eventos.reduce((unicoEvento, evento) => {
        if (evento.status == "PRODUCAO" && evento.master_id == null) {
          if (!unicoEvento.some((obj) => obj.dia === evento.dia)) {
            unicoEvento.push(evento);
          }
        }
        return unicoEvento;
      }, []);
      return eventosFiltrados.sort((a, b) => (a.dia > b.dia ? 1 : -1));
    },

    contadorEventos(regua, tipoEvento, diaEvento) {
      if (!regua.eventos) return 0;

      const eventos = regua.eventos.filter(
        (evento) => evento.status === "PRODUCAO" && evento.master_id == null
      );

      return (
        eventos.filter((item) => item.tipo === tipoEvento && item.dia === diaEvento).length || 0
      );
    },

    retornaCorCard(diaEvento) {
      if (diaEvento < 0) {
        return "#448AFF";
      } else if (diaEvento == 0) {
        return "#FFAB40";
      } else {
        return "#EF5350";
      }
    },

    retornaDiaEvento(evento, regua) {
      if (evento.dia == 0) {
        return Vue.filter("tipoReguaEvento")(regua.tipo);
      } else if (evento.dia > 0) {
        return "+" + evento.dia;
      } else {
        return evento.dia;
      }
    },
  },
};
</script>
