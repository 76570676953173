import { render, staticRenderFns } from "./DialogComando.vue?vue&type=template&id=fa284af2&scoped=true"
import script from "./DialogComando.vue?vue&type=script&lang=js"
export * from "./DialogComando.vue?vue&type=script&lang=js"
import style0 from "./DialogComando.vue?vue&type=style&index=0&id=fa284af2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa284af2",
  null
  
)

export default component.exports