export default {
  email: [(v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido."],
  url: [
    (v) => {
      if (!v) return true;
      return (
        /^$|(http(s)?:\/\/)?(?:www\.)?.{1,256}\.[a-zA-Z0-9()]{1,6}\b(.*)$/.test(
          v
        ) ||
        /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)$/.test(
          v
        ) ||
        "O campo deve ser preenchido com uma URL"
      );
    },
  ],
  telefone: [
    (v) => {
      return /^\(?\d{2}\)?[ ]?\d{4,5}-?\d{4}$/.test(v) || "Telefone inválido"
    }
  ],
  exigido: [(v) => !!v || "Este campo deve ser preenchido"],
  exigidoObjId: [(v) => !!(v && v.id) || "Este campo deve ser preenchido"],
  campoSms: [
    (value) => !!value || "Este campo não pode ficar vazio",
    (v) => {
      if (!v) return true;
      return (
        !/[^a-zA-Z0-9\_{ },.:!?-]/g.test(v) ||
        "Não pode conter emojis ou caracteres especiais"
      );
    },
  ],
};

export const rules = {
  email: [(email) => validateEmail(email)],
  phone: [(v) => (v && v.length == 15) || !v || "Número deve ser válido."],
  fixo: [(v) => (v && v.length == 14) || !v || "Número deve ser válido."],
};

const validateEmail = (email) => {
  if (!email) {
    return true;
  }

  const emailValidatorRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = String(email).toLowerCase().match(emailValidatorRegex);
  if (isValid) return true;
  return "E-mail deve ser válido";
};

export const validateUrl = (url) => {
  if (!url) {
    return true;
  }

  const urlValidatorRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  const isValid = String(url).toLowerCase().match(urlValidatorRegex);
  if (isValid) return true;
  return false;
};

export const regrasDeIniciativas = {
  exigido: (value) => !!value || "Este campo não pode ficar vazio",
  limitado25: (value) =>
    value?.length <= 25 || "Este campo suporta apenas 25 caracteres",
  limitado60: (value) =>
    value?.length <= 60 || "Este campo suporta apenas 60 caracteres",
  limitado256: (value) =>
    value?.length <= 256 || "Este campo suporta apenas 256 caracteres",
  limitado1024: (value) =>
    value?.length <= 1024 || "Este campo suporta apenas 1024 caracteres",
  umaVariavel: (value) =>
    value.split(/\{{|}}/).length < 5 ||
    "Este campo suporta apenas uma variável",
  nenhumaVariavel: (value) =>
    !(value?.includes("{{") && value?.includes("}}")) ||
    "Este campo não suporta variáveis",
  alfanumerico: (v) => {
    if (!v) return true;
    return (
      /^[a-zA-Z0-9_]+$/.test(v) || "Use apenas letras, números e underline (_)"
    );
  },
  semEspeciais: (v) => {
    if (!v) return true;
    return (
      !/[^a-zA-Z0-9_.{}]/g.test(v) ||
      "Não é permitido o uso de caracteres especiais"
    );
  },
  url: (v) => {
    if (!v) return true;
    return (
      /^$|(http(s)?:\/\/)?(?:www\.)?.{1,256}\.[a-zA-Z0-9()]{1,6}\b(.*)$/.test(
        v
      ) ||
      /(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)$/.test(
        v
      ) ||
      "O campo deve ser preenchido com uma URL"
    );
  },
  urlEVariavel: (v) => {
    if (!v) return true;
    return (
      /^$|(http(s)?:\/\/)?(?:www\.)?.{1,256}\.[a-zA-Z0-9()]{1,6}\b(.*)$/.test(
        v
      ) ||
      (v.slice(0, 2) == "{{" &&
        v.slice(-2) == "}}" &&
        v[1] == "{" &&
        v.split(/\{{|}}/).length < 5) ||
      "O campo suporta URL ou variável"
    );
  },
};
