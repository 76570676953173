<template>
  <div>
    <v-app-bar app
      ><span class="text-h6 font-weight-bold ml-3"
        >{{ $route.meta.title }}
      </span>
      <div v-if="permissaoEditar" class="flex justify-end gap-4">
        <v-btn
          v-if="mudouEventos || mudouFranquias"
          text
          color="primary"
          :loading="false"
          @click="limpaAlteracoes"
          >Reverter alterações</v-btn
        >
        <v-btn
          class="primary"
          :disabled="!mudouEventos && !mudouFranquias"
          @click="salvar"
          >Salvar</v-btn
        >
      </div></v-app-bar
    >
    <div
      v-if="carregandoPrecificacao"
      class="flex flex-col items-center justify-center mt-12"
    >
      <v-progress-circular width="6" indeterminate color="primary" size="100">
      </v-progress-circular>
      <div class="mt-4">
        <span class="text-xl tex-grey weight">Carregando precificações...</span>
      </div>
    </div>

    <div class="container" v-else>
      <div
        class="bg-white elevation-1 rounded-lg border-gray-200 w-10/12 mx-auto"
      >
        <v-card class="d-flex flex-column fill-height" flat>
          <v-card-title class="align-self-start" style="width: 100%">
            <v-tabs grow v-model="tab">
              <v-tab v-for="t in tabItems" :key="t.name" :disabled="t.disabled">
                {{ t.name }}
              </v-tab>
            </v-tabs>
          </v-card-title>

          <v-card-text class="pt-3 fill-height" style="overflow-y: auto">
            <v-tabs-items style="overflow-y: auto" class="pt-3" v-model="tab">
              <v-tab-item>
                <TabFranquia
                  @mudou="(e) => (mudouFranquias = e)"
                  ref="tabFranquia"
                  :precificacoesFranquia="precificacaoFranquias"
                />
              </v-tab-item>
              <v-tab-item>
                <TabEvento
                  @mudou="(e) => (mudouEventos = e)"
                  ref="tabEvento"
                  :precificacoesEvento="precificacaoEventos"
                />
              </v-tab-item>
            </v-tabs-items>

            <v-alert
              type="error"
              class="mx-auto my-10 justify-center"
              outlined
              style="width: fit-content"
              v-if="erroCarregarPrecificacao && !carregandoPrecificacao"
            >
              <div class="mb-2">Erro ao carregar as precificações.</div>
              <v-btn color="primary" @click="carregarPrecificacao()">
                Tentar novamente
              </v-btn>
            </v-alert>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <!-- <DialogSalvaPrecificacao ref="DialogSalvaPrecificacao" /> -->
  </div>
</template>

<script>
import precificacaoService from "@/services/fatura";
import { mapGetters, mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import TabEvento from "./components/TabEvento.vue";
import TabFranquia from "./components/TabFranquia.vue";
// import DialogSalvaPrecificacao from "./components/DialogSalvaPrecificacao.vue";

export default {
  components: {
    TabEvento,
    TabFranquia,
  },
  data() {
    return {
      checkCredentials,
      tab: 0,
      precificacoes: [],
      carregandoPrecificacao: false,
      erroCarregarPrecificacao: false,
      precificacaoFranquias: [],
      precificacaoEventos: [],
      mudouFranquias: false,
      mudouEventos: false,
      // mudancasFranquia: [],
      // mudancasEvento: [],
    };
  },
  created() {
    this.carregandoPrecificacao = false;
    this.erroCarregarPrecificacao = false;
    this.limpaPrecificacoes();
    this.carregarPrecificacao();
  },
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    // async abrirDialogSalvaPrecificacao() {
    //   const tipo = this.tab === 0 ? "franquia" : "evento";
    //   const mudancasArr =
    //     this.tab === 0 ? this.mudancasFranquia : this.mudancasEvento;

    //   return await this.$refs.DialogSalvaPrecificacao.openAndWaitForDecision(
    //     mudancasArr,
    //     tipo
    //   );
    // },

    async salvar() {
      try {
        // const continuar = await this.abrirDialogSalvaPrecificacao();
        // if (!continuar) return;

        if (this.tab === 0) {
          await this.$refs.tabFranquia.editarFranquias();
        } else {
          await this.$refs.tabEvento.editarEventos();
        }

        this.limpaPrecificacoes();
        this.carregarPrecificacao();

        this.showSnackBar({
          text: `Precificação alterada com sucesso`,
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: `Erro ao alterar precificação`,
        });
      }
    },

    limpaAlteracoes() {
      if (this.$refs.tabEvento) {
        this.$refs.tabEvento.limpaAlteracoes();
      }
      if (this.$refs.tabFranquia) {
        this.$refs.tabFranquia.limpaAlteracoes();
      }
    },

    limpaPrecificacoes() {
      this.precificacoes = [];
      this.precificacaoFranquias = [];
      this.precificacaoEventos = [];
      this.erroCarregarPrecificacao = false;
    },

    async carregarPrecificacao() {
      this.carregandoPrecificacao = true;
      this.erroCarregarPrecificacao = false;
      let params = {
        empresa_id: this.empresaSelecionada.id,
        limite: 1000,
      };

      try {
        const result = await precificacaoService.getPrecificacao(params);
        this.precificacoes = result.data.objects;

        this.precificacoes.forEach((item) => {
          item.editando = false;
          if (item.categoria.includes("FRANQUIA")) {
            this.precificacaoFranquias.push(item);
          } else {
            this.precificacaoEventos.push(item);
          }
        });

        if (!this.permissaoEditar) {
          this.precificacaoEventos = this.precificacaoEventos.filter((item) => {
            return item.valor && item.valor >= 0;
          });
        }

        this.erroCarregarPrecificacao = false;
      } catch (e) {
        this.erroCarregarPrecificacao = true;
      } finally {
        this.carregandoPrecificacao = false;
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    permissaoEditar() {
      return checkCredentials(["admin", "editor_precificacao"]);
    },
    tabItems() {
      return [
        {
          id: 0,
          name: "Franquias",
          disabled: this.$refs.tabEvento
            ? this.$refs.tabEvento.editandoEvento
            : false,
        },
        {
          id: 1,
          name: "Eventos",
          disabled: this.$refs.tabFranquia
            ? this.$refs.tabFranquia.editandoFranquia
            : false,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
.v-data-table__wrapper tr .edit-button {
  display: none;
}
.v-data-table__wrapper tr:hover .edit-button {
  display: block;
}
</style>
