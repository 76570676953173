<template>
  <div>
    <v-app-bar app
      ><span class="text-h6 font-weight-bold ml-3">{{ $route.meta.title }} </span>
    </v-app-bar>
    <div v-if="loadingFluxosAutomaticos" class="flex flex-col items-center justify-center mt-12">
      <v-progress-circular width="6" indeterminate color="primary" size="100">
      </v-progress-circular>
      <div class="mt-4">
        <span class="text-xl tex-grey weight">Carregando fluxos automaticos...</span>
      </div>
    </div>
    <div class="grid grid-flow-row-dense gap-6" v-else>
      <div v-for="(fluxo, index) in fluxos" :key="fluxo.id">
        <v-hover v-slot="{ hover }">
          <div
            class="bg-white border rounded-lg border-solid border-gray-200 w-10/12 mx-auto"
            :class="{
              'border-b border-gray-200 border-solid': index < fluxos.length,
            }"
          >
            <div class="bg-gray-100 rounded-t-lg border-b border-gray-200 border-solid">
              <div class="flex justify-between items-center py-2 px-4 h-12">
                <span class="font-bold">{{ traduzCampo(fluxo.nome).nome }}</span>
                <div v-show="hover || loadingDocumentacao == fluxo.id">
                  <v-btn
                    v-if="fluxo.url_documentacao"
                    small
                    text
                    color="primary"
                    @click="mostrarDocumentacao(fluxo)"
                    >Documentação <v-icon small class="ml-1">mdi-file-document</v-icon></v-btn
                  >
                  <v-btn
                    v-else
                    :loading="loadingDocumentacao == fluxo.id"
                    small
                    text
                    color="primary"
                    @click="uploadDoc(fluxo)"
                    >Adicionar documentação
                    <v-icon small class="ml-1">mdi-file-document</v-icon></v-btn
                  >
                  <div class="hidden">
                    <input
                      type="file"
                      class="hidden"
                      accept=".pdf"
                      :ref="`fileInput-${fluxo.id}`"
                      @change="handleFileInput($event, fluxo)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(valor, config) in fluxo"
              :key="config"
              class="config-row"
              v-if="traduzCampo(fluxo.nome)[config]?.titulo"
            >
              <div class="grid grid-cols-6 p-4">
                <div class="col-span-3 flex flex-col justify-center pr-8">
                  <h3 class="text-lg text-gray-800">
                    {{ traduzCampo(fluxo.nome)[config].titulo }}
                  </h3>
                  <span class="text-xs text-gray-500">{{
                    traduzCampo(fluxo.nome)[config].descricao
                  }}</span>
                </div>

                <div class="col-span-3 flex items-center">
                  <component
                    :is="traduzCampo(fluxo.nome)[config].component"
                    v-model="fluxo[config]"
                    outlined
                    dense
                    v-bind="traduzCampo(fluxo.nome)[config].attrs"
                    @change="
                      config == 'ativo'
                        ? alteraStatusFluxo(config, fluxo)
                        : alteraFluxoAutomatico(config, fluxo)
                    "
                    v-mask="'###'"
                    hide-details
                    :loading="camposSendoAlterados.includes(`${config}-fluxo-${fluxo.id}`)"
                  >
                    <template
                      v-slot:append
                      v-if="traduzCampo(fluxo.nome)[config].component == 'v-text-field'"
                    >
                      <div v-if="camposSendoAlterados.includes(`${config}-fluxo-${fluxo.id}`)">
                        <v-progress-circular size="21" width="2" color="primary" indeterminate>
                        </v-progress-circular>
                      </div>
                      <v-fade-transition>
                        <v-icon
                          v-if="camposAlteradosComSucesso.includes(`${config}-fluxo-${fluxo.id}`)"
                          color="success"
                          key="1"
                          >mdi-check</v-icon
                        >
                        <v-icon
                          v-if="camposAlteradosComErro.includes(`${config}-fluxo-${fluxo.id}`)"
                          key="2"
                          color="red"
                          >mdi-close</v-icon
                        >
                      </v-fade-transition>
                      <div></div>
                    </template>
                  </component>
                </div>
              </div>
            </div>
            <div class="p-4 flex flex-wrap gap-4" v-if="fluxo.parametros">
              <div v-for="(eventos, chave) in fluxo.parametros" :key="chave" class="">
                <div class="w-32 h-24 rounded-lg elevation-1 flex flex-col">
                  <div
                    class="shrink bg-primary rounded-t-lg text-white flex items-center text-base font-bold justify-center"
                  >
                    Dia {{ chave }}
                  </div>
                  <div class="h-auto flex items-center justify-center gap-2">
                    <div v-for="evento in eventos" :key="Math.random()">
                      <v-tooltip color="primary" content-class="pb-8" bottom right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="mostrarEvento(evento)"
                            v-on="on"
                            :attrs="attrs"
                            size="20"
                            >{{ iconeDoEvento(evento.tipo).icone }}</v-icon
                          >
                        </template>
                        <!-- <span>{{ iconeDoEvento(evento.tipo).descricao }}</span> -->
                        <div class="pt-2 w-96">
                          <v-chip class="mb-2 bg-gray-200" small>
                            <span class="font-bold text-black">{{
                              iconeDoEvento(evento.tipo).descricao
                            }}</span>
                          </v-chip>
                          <div
                            class="message-bubble shadow-lg p-4 pt-2 border border-gray-200 text-black bg-gray-200 border-solid rounded-lg rounded-bl-sm"
                          >
                            {{ evento.mensagem }}
                          </div>
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-hover>
      </div>
    </div>
    <DialogPreviewEvent ref="DialogPreviewEvent" />
    <DialogDocumentacao
      :loading="loadingDocumentacao"
      @uploadDoc="uploadDoc"
      ref="DialogDocumentacao"
    />
  </div>
</template>
<script>
import { VTextField, VSwitch } from "vuetify/lib";
import DialogPreviewEvent from "./components/DialogPreviewEvent.vue";
import DialogDocumentacao from "./components/DialogDocumentacao.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    DialogPreviewEvent,
    DialogDocumentacao,
    VTextField,
    VSwitch,
  },
  data() {
    return {
      fluxos: [],
      loadingFluxosAutomaticos: false,
      loadingDocumentacao: null,
      camposSendoAlterados: [],
      camposAlteradosComSucesso: [],
      camposAlteradosComErro: [],
    };
  },
  async created() {
    this.carregaFluxosAutomaticos();
  },
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("fluxosAutomaticos", ["getFluxos", "alteraFluxo", "uploadArquivo"]),
    async carregaFluxosAutomaticos() {
      try {
        this.loadingFluxosAutomaticos = true;
        const fluxos = await this.getFluxos();
        this.fluxos = fluxos.sort((a, b) => {
          const ordemA = this.traduzCampo(a.nome).ordem
          const ordemB = this.traduzCampo(b.nome).ordem
          return  ordemA - ordemB
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingFluxosAutomaticos = false;
      }
    },
    async alteraFluxoAutomatico(config, fluxo) {
      this.fluxoSendoAlterado = fluxo.id;
      this.camposSendoAlterados.push(`${config}-fluxo-${fluxo.id}`);
      try {
        await this.alteraFluxo({
          payload: { [config]: fluxo[config] },
          fluxoId: fluxo.id,
        });
        this.setCampoAlteradoComSucesso(config, fluxo);
        this.showSnackBar({
          text: `Alterado ${config} com sucesso`,
          type: "success",
        });
      } catch (e) {
        this.setCampoAlteradoComErro(config, fluxo);
        this.showSnackBar({
          text: `Falha ao alterar o ${config}`,
          type: "success",
        });
        console.error(e);
      } finally {
        this.fluxoSendoAlterado = null;
        const indexOfCampoSendoALterado = this.camposSendoAlterados.indexOf(
          `${config}-fluxo-${fluxo.id}`
        );
        this.camposSendoAlterados.splice(indexOfCampoSendoALterado, 1);
      }
    },
    setCampoAlteradoComSucesso(config, fluxo) {
      this.camposAlteradosComSucesso.push(`${config}-fluxo-${fluxo.id}`);
      setTimeout(() => {
        const indexOfCampoAlterado = this.camposAlteradosComSucesso.indexOf(
          `${config}-fluxo-${fluxo.id}`
        );
        this.camposAlteradosComSucesso.splice(indexOfCampoAlterado, 1);
      }, 3000);
    },
    setCampoAlteradoComErro(config, fluxo) {
      this.camposAlteradosComErro.push(`${config}-fluxo-${fluxo.id}`);
      setTimeout(() => {
        const indexOfCampoAlterado = this.camposAlteradosComErro.indexOf(
          `${config}-fluxo-${fluxo.id}`
        );
        this.camposAlteradosComErro.splice(indexOfCampoAlterado, 1);
      }, 3000);
    },
    async alteraStatusFluxo(config, fluxo) {
      this.fluxoSendoAlterado = fluxo.id;
      this.camposSendoAlterados.push(`${config}-fluxo-${fluxo.id}`);
      const textoAcao = fluxo.ativo ? "ativado" : "desativado";
      try {
        await this.alteraFluxo({
          payload: { ativo: fluxo.ativo },
          fluxoId: fluxo.id,
        });
        this.setCampoAlteradoComSucesso(config, fluxo);
        this.showSnackBar({
          text: `Fluxo ${textoAcao} com sucesso`,
          type: "success",
        });
      } catch (e) {
        this.setCampoAlteradoComErro(config, fluxo);
        this.showSnackBar({
          text: `Falha ao ${textoAcao} o fluxo`,
          type: "success",
        });
        console.error(e);
      } finally {
        this.fluxoSendoAlterado = null;
        const indexOfCampoSendoALterado = this.camposSendoAlterados.indexOf(
          `${config}-fluxo-${fluxo.id}`
        );
        this.camposSendoAlterados.splice(indexOfCampoSendoALterado, 1);
      }
    },
    traduzCampo(nomeFluxo) {
      const DICT = {
        LIGAR_INFORMANDO_EMAIL_NAO_ENTREGUE_PRIMEIRA_LIGACAO: {
          ordem: 0,
          nome: "Ligar informando e-mail não entregue primeira ligação",
          limite: {
            component: "v-text-field",
            titulo: "Limite soft-bounce",
            descricao: "Limite de dias seguidos em que tentaremos ligar para o cliente.",
          },
          periodicidade: null,
          ativo: {
            component: "v-switch",
            titulo: "Fluxo para hard-bounce de e-mails (Primeira ligação)",
            descricao:
              "Ativa ou desativa o fluxo automático para tratar hard-bounces de e-mails através de ligações e SMS.",
          },
        },
        LIGAR_INFORMANDO_EMAIL_NAO_ENTREGUE_SEGUNDA_LIGACAO: {
          ordem: 1,
          nome: "Ligar informando e-mail não entregue segunda ligação",
          limite: {
            component: "v-text-field",
            titulo: "Limite soft-bounce",
            descricao: "Limite de dias seguidos em que tentaremos ligar para o cliente.",
          },
          periodicidade: null,
          ativo: {
            component: "v-switch",
            titulo: "Fluxo para hard-bounce de e-mails (Segunda ligação)",
            descricao:
              "Ativa ou desativa o fluxo automático para tratar hard-bounces de e-mails através de ligações e SMS.",
          },
        },
        LIGAR_SOLICITANDO_EMAIL_PRIMEIRA_LIGACAO: {
          ordem: 2,
          nome: "Ligar solicitando e-mail primeira ligação",
          limite: {
            component: "v-text-field",
            titulo: "Limite sem e-mail",
            descricao: "Limite de dias seguidos em que tentaremos ligar para o cliente.",
          },
          periodicidade: null,
          ativo: {
            component: "v-switch",
            titulo: "Fluxo para clientes sem e-mail (Primeira ligação)",
            descricao:
              "Ativa ou desativa o fluxo automático para tentar obter e-mails de clientes através de ligações e SMS.",
          },
        },
        LIGAR_SOLICITANDO_EMAIL_SEGUNDA_LIGACAO: {
          ordem: 3,
          nome: "Ligar solicitando e-mail segunda ligação",
          limite: {
            component: "v-text-field",
            titulo: "Limite sem e-mail",
            descricao:
              "Limite de dias seguidos por mês em que tentaremos ligar para o cliente no fluxo de e-mail.",
          },
          periodicidade: null,
          ativo: {
            component: "v-switch",
            titulo: "Fluxo para clientes sem e-mail (Segunda ligação)",
            descricao:
              "Ativa ou desativa o fluxo automático para tentar obter e-mails de clientes através de ligações e SMS.",
          },
        },
        LIGAR_AVISANDO_CAIXA_CHEIA: {
          ordem: 4,
          nome: "Ligar avisando caixa cheia",
          limite: {
            component: "v-text-field",
            titulo: "Limite soft-bounce",
            descricao: "Limite de dias seguidos em que tentaremos ligar para o cliente.",
          },
          periodicidade: null,
          ativo: {
            component: "v-switch",
            titulo: "Fluxo para soft-bounce de e-mails",
            descricao:
              "Ativa ou desativa o fluxo automático para tratar\
               soft-bounces de e-mails através de ligações e SMS.",
          },
        },
        SOLICITAR_OPTIN_WHATSAPP_VIA_SMS: {
          ordem: 5,
          nome: "Solicitar opt-in WhatsApp via SMS",
          limite: {
            attrs: {
              disabled: true,
            },
            component: "v-text-field",
            titulo: "Limite fluxo opt-in de WhatsApp via SMS",
            descricao:
              "Limite de dias seguidos por mês em que tentaremos obter o opt-in de WhatsApp dos clientes através de SMS.",
          },
          periodicidade: null,
          ativo: {
            component: "v-switch",
            titulo: "Fluxo opt-in de WhatsApp via SMS",
            descricao:
              "Ativa ou desativa o fluxo automático para tentar obter o opt-in de WhatsApp dos clientes através de SMS",
          },
        },
        ENRIQUECIMENTO_INTERNO: {
          ordem: 6,
          nome: "Enriquecimento interno",
          limite: {
            component: "v-text-field",
            titulo: "Limite de tentativas do enriquecimento interno",
            descricao:
              "Determina o limite de tentativas para tentar enriquecer o cliente através do serviço interno.",
          },
          periodicidade: {
            component: "v-text-field",
            titulo: "Periodicidade do enriquecimento interno",
            descricao:
              "Determina o intervalo de dias entre tentativas para tentar enriquecer o cliente através do serviço interno.",
          },
          ativo: {
            component: "v-switch",
            titulo: "Fluxo de enriquecimento interno",
            descricao:
              "Determina se o fluxo de enriquecimento interno está ativado para a empresa.",
          },
        },
        ENRIQUECIMENTO_EXTERNO: {
          ordem: 7,
          nome: "Enriquecimento externo",
          limite: {
            component: "v-text-field",
            titulo: "Limite de tentativas do enriquecimento externo",
            descricao:
              "Determina o limite de tentativas para tentar enriquecer o cliente através do serviço externo.",
          },
          periodicidade: {
            component: "v-text-field",
            titulo: "Periodicidade do enriquecimento externo",
            descricao:
              "Determina o intervalo de dias entre tentativas para tentar enriquecer o cliente através do serviço externo.",
          },
          ativo: {
            component: "v-switch",
            titulo: "Fluxo de enriquecimento externo",
            descricao:
              "Determina se o fluxo de enriquecimento externo está ativado para a empresa.",
          },
        },
      };
      return DICT[nomeFluxo];
    },
    iconeDoEvento(evento) {
      const icons = {
        SMS: {
          icone: "mdi-message",
          descricao: "SMS",
        },
        FAST_SMS: {
          icone: "mdi-message-fast",
          descricao: "Fast SMS",
        },
        EMAIL: {
          icone: "mdi-email",
          descricao: "E-mail",
        },
        WHATS_APP: {
          icone: "mdi-whatsapp",
          descricao: "WhatsApp",
        },
        LIGACAO_CELULAR: {
          icone: "mdi-cellphone-sound",
          descricao: "Ligação celular",
        },
        LIGACAO_FIXO: {
          icone: "mdi-phone-outline",
          descricao: "Ligação fixo",
        },
        PUSH: {
          icone: "mdi-bell",
          descricao: "Notificação PUSH",
        },
      };
      return icons[evento];
    },
    mostrarEvento(evento) {
      this.$refs.DialogPreviewEvent.dialog = true;
      this.$refs.DialogPreviewEvent.event = evento;
    },
    mostrarDocumentacao(fluxo) {
      this.$refs.DialogDocumentacao.dialog = true;
      this.$refs.DialogDocumentacao.fluxo = fluxo;
    },
    uploadDoc(fluxo) {
      const fileInputRef = `fileInput-${fluxo.id}`;
      const ref = this.$refs[fileInputRef];
      ref[0].click();
    },
    async handleFileInput(event, fluxo) {
      const file = event.target.files[0];
      if (!file) return;
      try {
        this.loadingDocumentacao = fluxo.id;
        const { url } = await this.uploadArquivo(file);
        await this.alteraFluxo({
          payload: { url_documentacao: url },
          fluxoId: fluxo.id,
        });
        const acaoExecutada = fluxo.url_documentacao ? "alterada" : "adicionada";
        this.showSnackBar({ text: `Documentação ${acaoExecutada} com sucesso` });
        fluxo.url_documentacao = url;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingDocumentacao = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.config-row {
  &:not(:nth-last-of-type(1)) {
    border-bottom: 1px solid;
    @apply border-gray-200;
  }
}
.message-bubble {
  border-bottom-left-radius: 0px !important;
  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    width: 25px;
    height: 25px;
    bottom: 15px;
    left: 16px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    @apply bg-gray-200 shadow-xl;
  }
}
</style>
