<template>
  <v-dialog persistent v-model="dialog" :width="550">
    <v-card>
      <v-card-title class="text-h5 title-card font-weight-regular flex align-center">
        Definir régua ganhadora
      </v-card-title>
      <v-card-subtitle v-show="reguaA" class="pt-1"> #{{ reguaA?.id }} - {{ reguaA?.nome }} </v-card-subtitle>

      <v-card-text class=" mb-4">
        <p class="text-body-1 mb-4">
          Selecione a régua ganhadora. A régua perdedora será arquivada e todos os seus perfis
          removidos!
        </p>
        <div class="card-ab d-flex justify-center align-center gap-8">
          <v-card
            outlined
            @click="selected = 'A'"
            class="card-A p-4 d-flex flex-column justify-center align-center"
            :class="selected === 'A' && 'selected elevation-1'"
          >
            <v-icon :style="{ fontSize: '6rem' }" color="#ef5350" class="ml-1">
              mdi-alpha-a-circle-outline
            </v-icon>

            <p class="mb-0 font-weight-light text-subtitle-1">
              Amostra: {{ reguaA?.digito_verificador_inicio }} a
              {{ reguaA?.digito_verificador_fim }}
            </p>
          </v-card>

          <v-card
            outlined
            @click="selected = 'B'"
            class="card-B p-4 d-flex flex-column justify-center align-center"
            :class="selected === 'B' && 'selected elevation-1'"
          >
            <v-icon :style="{ fontSize: '6rem' }" x-large color="#42A5F5" class="ml-1">
              mdi-alpha-b-circle-outline
            </v-icon>
            <p class="mb-0 font-weight-light text-subtitle-1">
              Amostra: {{ reguaB?.digito_verificador_inicio }} a
              {{ reguaB?.digito_verificador_fim }}
            </p>
          </v-card>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="decision = false"> Cancelar </v-btn>
        <v-btn type="submit" color="primary" @click="decision = true" :disabled="!selected">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      selected: null,
      decision: null,
      dialog: false,
      reguaA: null,
      reguaB: null,
    };
  },

  methods: {
    openDialog() {
      this.dialog = true;
      this.selected = null;
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.decision === true || this.decision === false) {
            resolve({ decision: this.decision, winner: this.selected });
            clearInterval(interval);
            this.dialog = false;
            this.decision = null;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-A,
.card-B {
  transition: transform 0.25s ease;
  &:hover {
    transform: scale(1.1);
  }
}

// .card-A.selected {
//   background-color: rgba(239, 83, 80, 0.1) !important;
// }

// .card-B.selected {
//   background-color: rgba(66, 165, 245, 0.1) !important;
// }

.selected {
  transform: scale(1.2) !important;
  transform-origin: center;
}
</style>
