<template>
  <div>
    <v-col cols="12" class="pb-0 pt-0">
      <div class="horarios-header d-flex justify-space-between align-center flex-grow-1">
        <v-card-title
          class="text-black font-weight-bold text-subtitle-1 d-flex align-center justify-space-between pl-0"
        >
          Horários programados
        </v-card-title>

        <v-switch
          :disabled="loadingHorarios || disabled"
          v-model="padrao"
          label="Valores padrão"
        ></v-switch>
      </div>
    </v-col>

    <v-progress-linear
      v-show="loadingHorarios"
      :active="loadingHorarios"
      indeterminate
      color="deep-purple accent-4"
    ></v-progress-linear>
    <v-row v-show="!loadingHorarios" class="mt-0 pt-1">
      <v-col cols="4" class="pt-0">
        <v-card-text class="pb-0 pt-0">
          <p class="body-2">Horário</p>
          <v-select
            v-model="camposDisparo.horario"
            :items="horarios"
            :disabled="padrao"
            label="Horário"
            outlined
            dense
          ></v-select>
        </v-card-text>
      </v-col>
      <v-col class="pt-0" cols="8">
        <v-card-text class="secao-dias pt-0 pb-0">
          <p class="body-2">Dias</p>
          <div class="seletor-dias">
            <div
              @click="dia.active = !dia.active"
              class="dia"
              v-for="dia in camposDisparo.dias"
              :key="dia.id"
              :class="{
                ativo: dia.active,
                feriado: dia.label === 'Feriados',
                disabled: padrao,
              }"
            >
              {{ dia.label }}
            </div>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    event: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loadingHorarios: false,
    padrao: true,
    horarios: [],
    camposDisparo: {
      horario: "09:00",
      dias: [
        { id: "domingo", label: "D", active: false },
        { id: "segunda", label: "S", active: true },
        { id: "terca", label: "T", active: true },
        { id: "quarta", label: "Q", active: true },
        { id: "quinta", label: "Q", active: true },
        { id: "sexta", label: "S", active: true },
        { id: "sabado", label: "S", active: false },
        { id: "feriado", label: "Feriados", active: false },
      ],
    },
  }),

  watch: {
    padrao(padrao) {
      if (padrao) {
        this.resetDisparos();
      } else return;
    },
  },

  methods: {
    resetDisparos() {
      this.camposDisparo.dias.map((d) => {
        return this.disparoPadrao.DIAS.includes(d.id.toUpperCase())
          ? (d.active = true)
          : (d.active = false);
      });

      this.camposDisparo.horario = this.disparoPadrao.HORARIO;
    },

    configuraDisparos() {
      this.loadingHorarios = true;

      for (let i = 0; i <= 23; i++) {
        i <= 9 ? this.horarios.push(`0${i}:00`) : this.horarios.push(`${i}:00`);
      }

      this.resetDisparos();

      if (this.event?.id) {
        this.camposDisparo.dias.forEach((dia) => {
          dia.active = this.event[dia.id] || false;
        });
      }

      if (this.event?.horario && this.event?.id) {
        this.camposDisparo.horario = this.event.horario.slice(0, -3);
      } else {
        this.camposDisparo.horario = this.disparoPadrao.HORARIO;
      }

      const diasPadrao = this.disparoPadrao.DIAS.every(d => {
        const dia = this.camposDisparo.dias.find(item => item.id.toUpperCase() === d)
        return d === dia.id.toUpperCase() && dia.active
      })

      const horarioPadrao =
        this.camposDisparo.horario == this.disparoPadrao.HORARIO ||
        this.camposDisparo.horario == `${this.disparoPadrao.HORARIO}:00`;

      if (diasPadrao && horarioPadrao) {
        this.padrao = true;
      } else {
        this.padrao = false;
      }

      this.loadingHorarios = false;
    },

    montaPayloadDisparos() {
      const payload = {
        horario: this.camposDisparo.horario,
      };
      this.camposDisparo.dias.forEach((dia) => {
        Object.assign(payload, { [dia.id]: dia.active });
      });

      return payload;
    },
  },

  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    disparoPadrao() {
      return this.empresaSelecionada.disparos_dia_hora[this.event.tipo];
    },
  },
};
</script>
<style lang="scss" scoped>
.text-black {
  color: black;
}
.seletor-dias {
  display: flex;
  align-items: center;
  gap: 1rem;
  .dia {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e8eaf6;
    padding: 0.5rem;
    cursor: pointer;
    height: 1.7rem;
    width: 1.7rem;
    font-weight: 500;
    &:hover {
      opacity: 0.7;
    }
    &.disabled {
      color: unset;
      opacity: 0.7;
      pointer-events: none;
    }
    &.ativo {
      color: #fff;
      background-color: #3f51b5;
    }
    &.feriado {
      width: unset;
      height: 2rem;
      border-radius: 2rem;
    }
  }
}
</style>
