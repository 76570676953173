<template>
  <v-dialog v-model="dialog" persistent width="350">
    <v-card>
      <v-card-title class="text-h5">
        Deseja excluir este tipo de movimentação?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fecharDialog()"> Não </v-btn>
        <v-btn
          color="primary"
          text
          @click="excluiMovimentacao(movimentacao)"
          :loading="excluindoMovimentacao"
        >
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      movimentacao: null,
      excluindoMovimentacao: false,
    };
  },
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("tipoMovimentacao", {
      excluirTipoMovimentacao: "excluirTipoMovimentacao",
    }),
    fecharDialog() {
      this.dialog = false;
      this.movimentacao = null;
      this.excluindoMovimentacao = false;
    },
    abrirDialog(movimentacao) {
      this.movimentacao = movimentacao;
      this.dialog = true;
    },
    async excluiMovimentacao(movimentacao) {
      this.excluindoMovimentacao = true;
      await this.excluirTipoMovimentacao(movimentacao.id)
        .then((retorno) => {
          this.excluindoMovimentacao = false;
          this.$emit("excluiMovimentacao", movimentacao.id);
          this.dialog = false;
          this.showSnackBar({
            text: "Tipo movimentação excluído com sucesso!",
          });
        })
        .catch((error) => {
          this.excluindoMovimentacao = false;
          this.dialog = false;
          this.showSnackBar({
            text: "Erro ao excluir o Tipo Movimentação!",
          });
          console.error(error);
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
