<template>
  <div style="width: 100%" class="fill-height d-flex flex-column">
    <div
      class="grow d-flex flex-column pt-0 px-0 pb-3 d-flex align-content-space-between"
      :class="{ wppBack: iniciativa }"
    >
      <v-app-bar
        class="flex-column elevation-0 app-bar shrink"
        v-if="iniciativa"
      >
        <div class="mx-auto font-weight-bold">
          {{ empresaSelecionada.nome }}
          <v-icon small color="green">mdi-check-decagram</v-icon>
        </div>
      </v-app-bar>
      <div v-if="iniciativa" class="message-box-wrapper grow justify-end">
        <div class="message-card">
          <div class="message-box ml-auto justify-start">
            <Header
              v-if="iniciativa?.tipo_header"
              :iniciativa="iniciativa"
            ></Header>
            <Body
              v-if="iniciativa?.mensagem_renderizada || iniciativa?.mensagem"
              class="message-body mt-1"
              :iniciativa="iniciativa"
            ></Body>
            <Footer
              v-if="iniciativa"
              class="message-footer"
              :iniciativa="iniciativa"
            ></Footer>
          </div>
          <Botoes
            v-if="iniciativa?.botoes"
            class="message-buttons"
            :iniciativa="iniciativa"
          ></Botoes>
        </div>
      </div>
      <div v-else class="mx-auto">
        <h2>Nenhuma iniciativa encontrada</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/views/reguas/components/previewIniciativaWhatsapp/Header.vue";
import Body from "@/views/reguas/components/previewIniciativaWhatsapp/Body.vue";
import Footer from "@/views/reguas/components/previewIniciativaWhatsapp/Footer.vue";
import Botoes from "@/views/reguas/components/previewIniciativaWhatsapp/Botoes.vue";

export default {
  components: {
    Header,
    Body,
    Footer,
    Botoes,
  },

  props: {
    iniciativa: null,
    evento: true,
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
      empresas: "empresas",
    }),
  },
};
</script>

<style lang="scss" scoped>
.wppBack {
  background-image: url("../../../../assets/img/whatsappBackGround.png");
  background-size: contain;
  background-repeat: repeat;
}

.container {
  height: 100%;
}

.message-box-wrapper {
  width: 100%;
  max-width: 450px;
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 20px;
  margin-left: auto !important;
}

.message-box {
  position: relative;
  background-color: whitesmoke;
  width: 75%;
  border-radius: 5px;
  padding: 8px;
  margin-right: 15px;
  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    right: -16px;
    border: 8px solid transparent;
    border-left-color: whitesmoke;
    transform: translateY(-50%);
  }
}

.message-buttons {
  margin: 4px 15px 12px auto;
  width: 75%;
}

.app-bar {
  height: 35px !important;
}

:deep(.v-toolbar__content) {
  height: 35px !important;
}
</style>
