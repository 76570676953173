<template>
  <div class="fill-height">
    <div
      class="previsualizacao"
      :class="!templateEmailHTML && 'fill-height'"
      v-if="!eventoMultiplo"
    >
      <v-sheet v-if="!templateEmailHTML" height="100%">
        <v-row
          class="fill-height grey lighten-3 mx-0 my-0"
          align="center"
          justify="center"
        >
          <div
            class="text-h6 d-flex justify-center align-center grey lighten-3 rounded text--secondary"
          >
            Pré-visualização do asset
          </div>
        </v-row>
      </v-sheet>
      <div class="iframe-container" v-else>
        <v-card
          color="grey lighten-4"
          class="mb-4 pa-2 mr-1 ml-1 d-flex"
          elevation="1"
        >
          <v-icon class="mr-2" size="medium"> mdi-email-outline </v-icon>
          <div>{{ templateEmailAssunto }}</div>
        </v-card>
        <div class="fill-height">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="grey"
            class="d-flex mx-auto mt-8"
          ></v-progress-circular>
          <iframe
            v-show="!loading"
            style="height: 100%"
            ref="iframeContent"
            :srcdoc="templateEmailHTML"
            :key="templateEmailHTML"
            @load="handleFrameLoad"
          ></iframe>
        </div>
      </div>
    </div>

    <!-- Pré visualização múltiplos -->
    <div class="previsualizacao" v-if="eventoMultiplo">
      <v-carousel
        :cycle="false"
        height="645"
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item :key="'assetNormal'">
          <v-icon
            class="email-icon"
            size="medium"
            :style="templateEmailHTML ? 'top: 5.5rem' : 'top: 1rem'"
          >
            mdi-email-outline
          </v-icon>
          <v-sheet v-if="!templateEmailHTML" height="100%">
            <v-row
              class="fill-height grey lighten-3"
              align="center"
              justify="center"
            >
              <div
                class="text-h6 d-flex justify-center align-center grey lighten-3 rounded text--secondary"
                style="height: 100%"
              >
                Pré-visualização do asset
              </div>
            </v-row>
          </v-sheet>
          <div class="iframe-container" v-else>
            <v-card
              color="grey lighten-4"
              class="mb-4 pa-2 mr-1 ml-1 d-flex"
              elevation="1"
            >
              <v-icon class="mr-2" size="medium"> mdi-email-outline </v-icon>
              <div>{{ templateEmailAssunto }}</div>
            </v-card>
            <div class="fill-height">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="grey"
                class="d-flex mx-auto mt-8"
              ></v-progress-circular>
              <iframe
                v-show="!loading"
                style="height: 100%"
                ref="iframeContent"
                :srcdoc="templateEmailHTML"
                :key="templateEmailHTML"
                @load="handleFrameLoad"
              ></iframe>
            </div>
          </div>
        </v-carousel-item>
        <v-carousel-item :key="'assetMultiplo'">
          <v-icon
            size="medium"
            class="email-icon"
            :style="templateEmailMultiploHTML ? 'top: 5.5rem' : 'top: 1rem'"
          >
            mdi-email-multiple-outline
          </v-icon>
          <v-sheet v-if="!templateEmailMultiploHTML" height="100%">
            <v-row
              class="fill-height grey lighten-3"
              align="center"
              justify="center"
            >
              <div
                class="text-h6 d-flex justify-center align-center grey lighten-3 rounded text--secondary text-center"
                style="height: 100%"
              >
                Pré-visualização do asset {{ definirNomePorTipoRegua }}
              </div>
            </v-row>
          </v-sheet>
          <div class="iframe-container" v-else>
            <v-card
              color="grey lighten-4"
              class="mb-4 pa-2 mr-1 ml-1 d-flex"
              elevation="1"
            >
              <v-icon class="mr-2" size="medium">
                mdi-email-multiple-outline
              </v-icon>
              <div>{{ templateEmailMultiploAssunto }}</div>
            </v-card>

            <div class="fill-height">
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="grey"
                class="d-flex mx-auto mt-16"
              ></v-progress-circular>
              <iframe
                v-show="!loading"
                style="height: 100%"
                ref="iframeContent"
                :srcdoc="templateEmailMultiploHTML"
                :key="templateEmailMultiploHTML"
                @load="handleFrameLoad"
              ></iframe>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>
<script>
import variavelMensagemService from "@/services/variavel-mensagem";
import { mapState } from "vuex";

export default {
  props: {
    eventoMultiplo: {
      type: Boolean,
      default: false,
    },
    camposEvento: {
      type: Object,
      default: () => {},
    },
    templateEmailMultiploHTML: {
      type: String,
      default: null,
    },
    templateEmailHTML: {
      type: String,
      default: null,
    },
    templateEmailAssunto: {
      type: String,
      default: null,
    },
    templateEmailMultiploAssunto: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState("regua", ["regua"]),
    definirNomePorTipoRegua() {
      if (this.regua.tipo === "IMPLANTACAO") {
        return "Múltiplas Implantações";
      }
      return "Múltiplos Vencimentos";
    },
  },
  methods: {
    preVisualizarMensagem: variavelMensagemService.preVisualizarMensagem,

    handleFrameLoad() {
      this.estilosIFrame();
      this.loading = false;
    },

    estilosIFrame() {
      const frame = this.$refs.iframeContent.contentWindow;
      const css = document.createElement("style");
      css.textContent = `
        ::-webkit-scrollbar {
          width: 6px;
          height:6px;
          margin-left: 1px;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #bdbdbd;
          border-radius: 25px;
          padding-right: 0px;
        }
        button, a {
          pointer-events: none;
        }
      `;
      frame.document.head.appendChild(css);
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.email-icon {
  position: absolute;
  right: 1rem;
  z-index: 1;
  background: rgba(189, 189, 189, 0.5);
  padding: 0.5rem;
  border-radius: 50%;
}

.previsualizacao {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 645px;
  .iframe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    height: 100% !important;
    iframe {
      border: none;
      width: 100%;
    }
  }
}
</style>
