<template>
  <!-- DIALOG CONFRIMAÇÃO DO ENVIO DA FATURA -->
  <v-dialog v-model="dialogEnviarFatura" max-width="450">
    <v-card>
      <v-card-title class="text-h5">
        Deseja realmente enviar o e-mail?
      </v-card-title>
      <v-card-text
        >O e-mail será enviado para {{ emailsCobranca }}.</v-card-text
      >
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fecharDialogEnviarFatura()">
          cancelar
        </v-btn>
        <v-btn color="primary" @click="enviarFatura(faturaSelecionada.id)">
          salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      dialogEnviarFatura: false,
      faturaSelecionada: null,
      emailsCobranca: null,
    };
  },

  methods: {
    ...mapActions("fatura", {
      enviarFaturaCredor: "enviarFaturaCredor",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    abrirDialogEnviarFatura(fatura, emailsCobranca) {
      this.faturaSelecionada = fatura;
      this.emailsCobranca = emailsCobranca;
      this.dialogEnviarFatura = true;
    },
    fecharDialogEnviarFatura() {
      this.idFaturaSelecionada = null;
      this.dialogEnviarFatura = false;
    },
    enviarFatura(id_fatura) {
      this.enviarFaturaCredor(id_fatura)
        .then(() => {
          this.showSnackBar({
            text: `Email enviado com sucesso!`,
          });
          this.dialogEnviarFatura = false;
        })
        .catch(() => {
          this.showSnackBar({
            text: `Erro ao enviar o e-mail`,
          });
        });
    },
  },

  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
};
</script>

<style></style>
