<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="75vw" class="">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 flex justify-between">
          Documentação
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="p-0">
          <div v-if="fluxo.url_documentacao" class="dialog-documentacao">
            <iframe
              :src="fluxo.url_documentacao"
              frameborder="0"
              width="100%"
              height="95%"
            ></iframe>
            <div class="mt-3">
              <v-btn
                @click="$emit('uploadDoc', fluxo)"
                small
                text
                color="primary"
                :loading="loading"
              >
                Alterar documentação
              </v-btn>
            </div>
          </div>
          <div v-else class="flex justify-center items-center flex-col"></div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: false,
  },
  data() {
    return {
      dialog: false,
      fluxo: {},
    };
  },
};
</script>
<style lang="scss" scoped>
.dialog-documentacao {
  height: 80vh;
}
</style>
