<template>
  <v-dialog :width="retornaLargura()" persistent scrollable v-model="dialog">
    <v-card style="min-height: 68vh" class="elevation-0 rounded-0">
      <!-- Cabeçalho -->
      <v-card-title class="text-h5">
        <span class="mr-2">{{ event.tipo | tipoEvento }} </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <!-- Fomulário -->
      <v-card-text class="pt-0 pb-4">
        <v-form class="d-flex fill-height" ref="form" v-model="isFormValid">
          <v-row>
            <v-col :cols="12" class="campos campos-form pt-6 pb-0">
              <v-row>
                <v-col cols="12" sm="4" class="py-0" v-if="typeDay != 1">
                  <v-text-field
                    dense
                    outlined
                    min="1"
                    suffix="dia(s)"
                    type="number"
                    color="deep-purple accent-2"
                    v-model="camposEvento.days"
                    :rules="[
                      regras.exigido,
                      () => camposEvento.days > 0 || 'Mínimo 1 dia',
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" :sm="typeDay == 1 ? '12' : '8'" class="py-0">
                  <v-select
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    color="deep-purple accent-2"
                    :items="typesDays"
                    v-model="typeDay"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    label="Nome do evento"
                    color="deep-purple accent-2"
                    autofocus
                    v-model="camposEvento.name"
                    :rules="regras.exigido"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    persistent-hint
                    label="URL do comando"
                    ref="evento_url_comando"
                    color="deep-purple accent-2"
                    v-model="camposEvento.url_comando"
                    prefix="POST"
                    type="url"
                    hint="Será enviado um JSON com as informações do cliente e do contrato"
                    :rules="regras.exigido"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-textarea
                    dense
                    outlined
                    hide-details
                    label="Observação"
                    color="deep-purple accent-2"
                    v-model="camposEvento.obs"
                    rows="2"
                  ></v-textarea>
                </v-col>

                <!-- Horarios programados -->
                <v-row>
                  <v-col cols="12">
                    <ProgramarHorario
                      :key="event.id"
                      :event="event"
                      ref="horarios"
                      class="pt-4"
                    />
                  </v-col>
                </v-row>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- Ações -->

      <v-card-actions class="mb-3 mr-3 flex-column align-end">
        <div>
          <v-btn color="primary" text @click="fecharDialog()"> Cancelar </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :disabled="!isFormValid"
            :loading="salvando"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import rules from "@/assets/js/rules";
import ProgramarHorario from "./ProgramarHorario.vue";

export default {
  name: "DialogComando",
  components: {
    ProgramarHorario,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    salvando: false,
    isFormValid: false,
    regras: rules,
    typeDay: 1,
    camposEvento: {
      days: null,
      name: null,
      obs: null,
      url_comando: null,
    },
  }),
  computed: {
    ...mapState("regua", ["regua"]),
    ...mapGetters("variavelRegua", ["variaveis"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),

    typesDays() {
      const tipos = [
        {
          tipo: "IMPLANTACAO",
          dias: [
            { index: 1, text: "Na implantação" },
            { index: 2, text: "Depois da implantação" },
          ],
        },
        {
          tipo: "VENCIMENTO_BOLETO",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "VENCIMENTO_DEBITO",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "TOLERANCIA_BOLETO",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },
        {
          tipo: "TOLERANCIA_DEBITO",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },
        {
          tipo: "ANIVERSARIO_DO_CLIENTE",
          dias: [
            { index: 0, text: "Antes do aniversário" },
            { index: 1, text: "No aniversário" },
            { index: 2, text: "Depois do aniversário" },
          ],
        },
        {
          tipo: "BOLETO_SOLICITADO",
          dias: [
            { index: 1, text: "Na solicitação" },
            { index: 2, text: "Depois da solicitação" },
          ],
        },
        {
          tipo: "INICIO_CONTRATO",
          dias: [
            { index: 0, text: "Antes do início" },
            { index: 1, text: "No início" },
            { index: 2, text: "Depois do início" },
          ],
        },
        {
          tipo: "CONTRATO_FINALIZADO",
          dias: [
            { index: 1, text: "Na finalização" },
            { index: 2, text: "Depois da finalização" },
          ],
        },
        {
          tipo: "CONTRATO_CANCELADO",
          dias: [
            { index: 1, text: "No cancelamento" },
            { index: 2, text: "Depois do cancelamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_BOLETO_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_DEBITO_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PARCELA_BOLETO_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "PARCELA_DEBITO_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "TERMINO_CONTRATO",
          dias: [
            { index: 0, text: "Antes do término" },
            { index: 1, text: "No término" },
            { index: 2, text: "Depois do término" },
          ],
        },
        {
          tipo: "MOVIMENTACAO",
          dias: [
            { index: 0, text: "Antes da movimentação" },
            { index: 1, text: "Na movimentação" },
            { index: 2, text: "Depois da movimentação" },
          ],
        },
      ];
      let tipoSelecionado = tipos.find(
        (tipos) => tipos.tipo == this.regua.tipo
      );
      return tipoSelecionado?.dias;
    },
  },

  watch: {
    dialog() {
      if (!this.dialog) {
        this.limpaCampos();
        return;
      }
      this.$refs.form && this.$refs.form.resetValidation();
      this.camposEvento.obs = this.event.observacao || "";
      this.camposEvento.name = this.event.nome || "";
      this.inicializarDialogDias();
      this.camposEvento.url_comando = this.event.url_comando || "";
      this.$nextTick(() => {
        this.$refs.horarios.configuraDisparos();
      });
    },
  },
  methods: {
    ...mapActions("evento", {
      salvarEvento: "salvarEvento",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    // DIALOG

    inicializarDialogDias() {
      if (this.event.dia != 0) {
        if (this.event.dia > 0) {
          this.typeDay = 2;
          this.camposEvento.days = this.event.dia;
        } else if (this.event.dia < 0) {
          this.typeDay = 0;
          this.camposEvento.days = this.event.dia * -1;
        }
      }
    },

    fecharDialog() {
      this.limpaCampos();
      this.$emit("update:dialog", false);
    },

    // MISC

    async save(evento) {
      console.debug("Salvando o evento");
      evento.preventDefault();

      let payload = {};

      if (!this.$refs.form.validate()) return;
      if (this.salvando) return;

      this.salvando = true;

      const defaultPayload = {
        id: null,
        dia: this.dayByPayload(),
        nome: this.camposEvento.name || this.prepareData(this.event.nome),
        observacao:
          this.camposEvento.obs || this.prepareData(this.event.observacao),
        tipo: this.prepareData(this.event.tipo),
        regua_id: this.regua.id,
        status: this.prepareData(this.event.status) || "RASCUNHO",
      };

      payload = {
        ...defaultPayload,
        url_comando:
          this.camposEvento.url_comando ||
          this.prepareData(this.event.url_comando),
        ...this.$refs.horarios.montaPayloadDisparos(),
      };

      if (this.event.id) payload.id = this.event.id;
      else delete payload["id"];

      try {
        const response = await this.salvarEvento(payload);
        console.debug("Evento salvo:", response.id);
        this.$emit("edit", response);
        this.fecharDialog();
        this.showSnackBar({
          text:
            "Evento " +
            (this.event.id ? "editado" : "criado") +
            " com sucesso!",
        });
      } catch (err) {
        console.error("Não foi possível salvar o evento", err);
        this.showSnackBar({
          text:
            "Erro ao " + (this.event.id ? "editar" : "criar") + " o evento!",
        });
      } finally {
        this.salvando = false;
      }
    },

    prepareData(item) {
      return typeof item === "undefined" || typeof item === null ? null : item;
    },

    dayByPayload() {
      switch (this.typeDay) {
        case 0:
          return this.camposEvento.days ? this.camposEvento.days * -1 : null;
        case 1:
          return 0;
        case 2:
          return this.camposEvento.days || null;
        default:
          return null;
      }
    },

    limpaCampos() {
      for (const key of Object.keys(this.camposEvento)) {
        this.camposEvento[key] = null;
      }

      this.typeDay = 1;
      this.$refs.form.resetValidation();
    },

    retornaLargura() {
      return "650";
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  margin-left: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}

.text-black {
  color: black;
}

.campos-form {
  scroll-behavior: smooth;
}
</style>
