var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.erroCarregarResumoContratos && _vm.resumoContratos)?_c('v-card',{staticClass:"table-wraper mx-auto mt-10 mb-2"},[(_vm.resumoContratos)?_c('v-card-title',[_vm._v(" Contratos Ativos "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.carregandoResumoContratos},on:{"click":function($event){return _vm.$emit('recarregarTabela', true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-cached ")])],1)]}}],null,false,372112482)},[_c('span',[_vm._v("Recarregar")])])],1):_vm._e(),_c('v-data-table',{staticClass:"tabela",attrs:{"hide-default-footer":"","headers":_vm.cabecalhoContratos,"items":_vm.linhasTabelaFiltrado,"no-data-text":"Nenhum conteúdo para ser mostrado!","loading":_vm.carregandoResumoContratos,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',_vm._l((item),function(quantidadeContratos,chave,index){return _c('td',{key:index,class:{ 'text-center': chave != 'perfil_contrato' },on:{"mouseover":function($event){return _vm.hoverItem(chave, item)},"mouseleave":function($event){return _vm.unHoverItem()}}},[(chave == 'total' && item.perfil_contrato != 'total')?_c('div',{staticClass:"celula-perfis"},[_c('div'),_c('strong',[_vm._v(" "+_vm._s(item.total.toLocaleString("pt-BR") || 0)+" ")]),(
                  _vm.checkCredentials(['admin', 'editor_regua']) &&
                  item === _vm.itemSelecionado &&
                  chave === _vm.chaveSelecionada &&
                  quantidadeContratos > 0
                )?_c('v-menu',{attrs:{"auto":""},on:{"click:outside":function($event){_vm.menuVisible = false}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.menuVisible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pt-0 pb-0"},[_c('v-list-item',{on:{"click":function($event){return _vm.visualizarCanais(_vm.itemSelecionado, _vm.chaveSelecionada)}}},[_c('v-list-item-title',[_vm._v("Visualizar canais")])],1)],1)],1):_vm._e()],1):_vm._e(),(item.perfil_contrato == 'total')?_c('div',{staticClass:"celula-perfis"},[_c('div'),_c('strong',{class:{ 'text-h5 font-weight-bold': chave == 'total' }},[_vm._v(" "+_vm._s(_vm.verificaCampoTotal(item, chave))+" ")]),(
                  _vm.checkCredentials(['admin', 'editor_regua']) &&
                  item === _vm.itemSelecionado &&
                  chave === _vm.chaveSelecionada &&
                  quantidadeContratos > 0
                )?_c('v-menu',{attrs:{"auto":""},on:{"click:outside":function($event){_vm.menuVisible = false}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.menuVisible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pt-0 pb-0"},[_c('v-list-item',{on:{"click":function($event){return _vm.visualizarCanais(_vm.itemSelecionado, _vm.chaveSelecionada)}}},[_c('v-list-item-title',[_vm._v("Visualizar canais")])],1)],1)],1):_vm._e()],1):_vm._e(),(chave == 'perfil_contrato')?_c('strong',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("perfilContrato")(item.perfil_contrato))+" ")]):_vm._e(),(
                chave != 'perfil_contrato' && item.perfil_contrato != 'total' && chave != 'total'
              )?_c('div',{staticClass:"celula-perfis",class:{
                'perfil-sem-regua':
                  _vm.filtrarReguasPerfil(item.perfil_contrato, chave).length == 0 &&
                  item.perfil_contrato != 'total',
              },staticStyle:{"position":"relative"}},[_c('div',[(
                    _vm.filtrarReguasPerfil(item.perfil_contrato, chave).length > 0 &&
                    _vm.checkCredentials(['admin', 'editor_regua'])
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.abrirDialogLinkarRegua(item.perfil_contrato, chave, true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Vincular régua")])]):_vm._e(),(
                    _vm.filtrarReguasPerfil(item.perfil_contrato, chave).length == 0 &&
                    _vm.checkCredentials(['admin', 'editor_regua']) &&
                    item === _vm.itemSelecionado &&
                    chave === _vm.chaveSelecionada
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.abrirDialogLinkarRegua(item.perfil_contrato, chave)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Vincular régua")])]):_vm._e()],1),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(quantidadeContratos.toLocaleString("pt-BR") || 0)+" ")]),_c('div',[(
                    _vm.checkCredentials(['admin', 'editor_regua']) &&
                    item === _vm.itemSelecionado &&
                    chave === _vm.chaveSelecionada &&
                    quantidadeContratos > 0
                  )?_c('v-menu',{attrs:{"auto":""},on:{"click:outside":function($event){_vm.menuVisible = false}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.menuVisible = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pt-0 pb-0"},[_c('v-list-item',{on:{"click":function($event){return _vm.visualizarCanais(_vm.itemSelecionado, _vm.chaveSelecionada)}}},[_c('v-list-item-title',[_vm._v("Visualizar canais")])],1)],1)],1):_vm._e()],1)]):_vm._e()])}),0)]}}],null,false,2616402197)})],1):_vm._e(),_c('DialogLinkarRegua',{ref:"dialogLinkarRegua",on:{"filtrarReguasPerfil":_vm.filtrarReguasPerfil}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }