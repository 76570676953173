<template>
  <div>
    <v-card
      class="table-wraper mx-auto mt-10 mb-2"
      v-if="!erroCarregarResumoContratos && resumoContratos"
    >
      <v-card-title v-if="resumoContratos">
        Contratos Ativos
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :loading="carregandoResumoContratos"
              @click="$emit('recarregarTabela', true)"
            >
              <v-icon color="black"> mdi-cached </v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        hide-default-footer
        :headers="cabecalhoContratos"
        :items="linhasTabelaFiltrado"
        no-data-text="Nenhum conteúdo para ser mostrado!"
        class="tabela"
        :loading="carregandoResumoContratos"
        :items-per-page="-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              v-for="(quantidadeContratos, chave, index) in item"
              :key="index"
              :class="{ 'text-center': chave != 'perfil_contrato' }"
              @mouseover="hoverItem(chave, item)"
              @mouseleave="unHoverItem()"
            >
              <!-- coluna total -->
              <div class="celula-perfis" v-if="chave == 'total' && item.perfil_contrato != 'total'">
                <div></div>
                <strong>
                  {{ item.total.toLocaleString("pt-BR") || 0 }}
                </strong>

                <v-menu
                  @click:outside="menuVisible = false"
                  v-if="
                    checkCredentials(['admin', 'editor_regua']) &&
                    item === itemSelecionado &&
                    chave === chaveSelecionada &&
                    quantidadeContratos > 0
                  "
                  auto
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click.stop="menuVisible = true">
                      <v-icon size="16">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pt-0 pb-0">
                    <v-list-item @click="visualizarCanais(itemSelecionado, chaveSelecionada)">
                      <v-list-item-title>Visualizar canais</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <!-- linha total -->
              <div class="celula-perfis" v-if="item.perfil_contrato == 'total'">
                <div></div>
                <strong :class="{ 'text-h5 font-weight-bold': chave == 'total' }">
                  {{ verificaCampoTotal(item, chave) }}
                </strong>
                <v-menu
                  @click:outside="menuVisible = false"
                  v-if="
                    checkCredentials(['admin', 'editor_regua']) &&
                    item === itemSelecionado &&
                    chave === chaveSelecionada &&
                    quantidadeContratos > 0
                  "
                  auto
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click.stop="menuVisible = true">
                      <v-icon size="16">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pt-0 pb-0">
                    <v-list-item @click="visualizarCanais(itemSelecionado, chaveSelecionada)">
                      <v-list-item-title>Visualizar canais</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>

              <!-- coluna perfil contrato -->
              <strong v-if="chave == 'perfil_contrato'" style="white-space: nowrap">
                {{ item.perfil_contrato | perfilContrato }}
              </strong>

              <!-- quantitdade de contratos -->
              <div
                v-if="
                  chave != 'perfil_contrato' && item.perfil_contrato != 'total' && chave != 'total'
                "
                class="celula-perfis"
                :class="{
                  'perfil-sem-regua':
                    filtrarReguasPerfil(item.perfil_contrato, chave).length == 0 &&
                    item.perfil_contrato != 'total',
                }"
                style="position: relative"
              >
                <!-- icone de vinculos com reguas -->
                <div>
                  <v-tooltip
                    v-if="
                      filtrarReguasPerfil(item.perfil_contrato, chave).length > 0 &&
                      checkCredentials(['admin', 'editor_regua'])
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click="abrirDialogLinkarRegua(item.perfil_contrato, chave, true)"
                      >
                        <v-icon size="16">mdi-link-variant</v-icon>
                      </v-btn>
                    </template>

                    <span>Vincular régua</span>
                  </v-tooltip>

                  <!-- celulas sem nenhum perfil de contrato ou cliente -->
                  <v-tooltip
                    v-if="
                      filtrarReguasPerfil(item.perfil_contrato, chave).length == 0 &&
                      checkCredentials(['admin', 'editor_regua']) &&
                      item === itemSelecionado &&
                      chave === chaveSelecionada
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click="abrirDialogLinkarRegua(item.perfil_contrato, chave)"
                      >
                        <v-icon size="16">mdi-link-variant</v-icon>
                      </v-btn>
                    </template>
                    <span>Vincular régua</span>
                  </v-tooltip>
                </div>

                <p class="ma-0 pa-0">
                  {{ quantidadeContratos.toLocaleString("pt-BR") || 0 }}
                </p>

                <!-- menu de opcoes -->
                <div>
                  <v-menu
                    @click:outside="menuVisible = false"
                    v-if="
                      checkCredentials(['admin', 'editor_regua']) &&
                      item === itemSelecionado &&
                      chave === chaveSelecionada &&
                      quantidadeContratos > 0
                    "
                    auto
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click.stop="menuVisible = true">
                        <v-icon size="16">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="pt-0 pb-0">
                      <v-list-item @click="visualizarCanais(itemSelecionado, chaveSelecionada)">
                        <v-list-item-title>Visualizar canais</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <DialogLinkarRegua ref="dialogLinkarRegua" @filtrarReguasPerfil="filtrarReguasPerfil" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import Vue from "vue";
import DialogLinkarRegua from "../components/dialogs/DialogLinkarRegua.vue";

export default {
  components: {
    DialogLinkarRegua,
  },
  props: {
    resumoContratos: null,
    reguasFiltradas: null,
    filtro: null,
  },
  data() {
    return {
      upHere: false,
      cabecalhoTabela: [],
      linhasTabela: [],
      cabecalhoContratos: [],
      itemSelecionado: false,
      chaveSelecionada: false,
      menuVisible: false,
      empresaMetabaseId: {
        viavarejo: "10171",
        digio: "10172",
        daycoval: "10173",
        quiteja: "10178",
      },
    };
  },
  mounted() {
    this.criaTabela(this.resumoContratos);
  },
  watch: {
    resumoContratos: {
      handler() {
        this.criaTabela(this.resumoContratos);
      },
    },
  },
  methods: {
    checkCredentials,

    criaCabecalhoTabela(dadosTabela) {
      const cabecalho = [];

      if (this.empresaSelecionada.perfis_regua?.perfis_cliente) {
        const perfisClente = this.empresaSelecionada.perfis_regua.perfis_cliente.reduce(
          (acc, perfilCliente) => {
            if (acc.indexOf(Object.keys(perfilCliente)[0]) < 0)
              acc.push(Object.keys(perfilCliente)[0]);
            return acc;
          },
          []
        );
        perfisClente.forEach((perfil) => {
          cabecalho.push({
            text: Vue.filter("perfilCliente")(perfil),
            value: perfil,
            sortable: false,
            align: "center",
          });
        });
      }

      dadosTabela.forEach((data) => {
        const indexCabecalhoExistente = cabecalho.findIndex(
          (cabecalho) =>
            cabecalho.text == Vue.filter("perfilCliente")(data.perfil_cliente ?? "sem_perfil")
        );
        if (indexCabecalhoExistente == -1) {
          cabecalho.push({
            text: Vue.filter("perfilCliente")(data.perfil_cliente ?? "sem_perfil"),
            value: data.perfil_cliente ?? "sem_perfil",
            sortable: false,
            align: "center",
          });
        }
      });

      cabecalho.unshift({
        text: "",
        value: "perfil_contrato",
        sortable: false,
      });

      cabecalho.push({
        text: "TOTAL",
        value: "total",
        sortable: false,
        align: "center",
      });

      cabecalho.forEach((cabecalhoSelecionado) => {
        cabecalhoSelecionado["width"] = 25 / cabecalho?.length + "%";
      });

      return cabecalho;
    },

    criaLinhasTabela(dadosTabela, cabecalho) {
      const perfisCliente = this.verificaPerfisExistentes("perfil_cliente", dadosTabela, cabecalho);
      const perfisContrato = this.verificaPerfisExistentes("perfil_contrato", dadosTabela);
      const linhasTabela = [];

      if (this.empresaSelecionada.perfis_regua?.perfis_contrato) {
        const perfisRegua = this.empresaSelecionada.perfis_regua.perfis_contrato.reduce(
          (acc, perfilContrato) => {
            if (acc.indexOf(Object.keys(perfilContrato)[0]) < 0)
              acc.push(Object.keys(perfilContrato)[0]);
            return acc;
          },
          []
        );

        perfisRegua.forEach((perfil) => {
          const chavePerfilContrato = perfil;
          const perfilContrato = {};
          perfisCliente.forEach((perfilCliente) => {
            const chavePerfilCliente = perfilCliente;
            Object.assign(perfilContrato, {
              [chavePerfilCliente]: 0,
            });
          });
          perfilContrato["perfil_contrato"] = chavePerfilContrato;
          perfilContrato["total"] = 0;
          linhasTabela.push(perfilContrato);
        });
      }

      perfisContrato.forEach((perfil) => {
        const indexPerfilExistente = linhasTabela.findIndex((perfilContrato) => {
          return perfilContrato?.perfil_contrato == perfil;
        });

        if (indexPerfilExistente == -1) {
          const chavePerfilContrato = perfil;
          const perfilContrato = {};
          perfisCliente.forEach((perfilCliente) => {
            const chavePerfilCliente = perfilCliente;
            Object.assign(perfilContrato, {
              [chavePerfilCliente]: 0,
            });
          });
          perfilContrato["perfil_contrato"] = chavePerfilContrato;
          perfilContrato["total"] = 0;

          linhasTabela.push(perfilContrato);
        }
      });

      dadosTabela.forEach((data) => {
        const indexPerfilContratoExistente = linhasTabela.findIndex((perfilContrato) => {
          return perfilContrato.perfil_contrato == (data.perfil_contrato ?? "sem_perfil");
        });
        if (indexPerfilContratoExistente >= 0) {
          Object.assign(linhasTabela[indexPerfilContratoExistente], {
            [data.perfil_cliente ?? "sem_perfil"]: data.total,
          });
          linhasTabela[indexPerfilContratoExistente].total += data.total;
        } else {
          linhasTabela.push({
            perfil_contrato: data.perfil_contrato ?? "sem_perfil",
            [data.perfil_cliente ?? "sem_perfil"]: data.total,
            total: data.total,
          });
        }
      });

      return linhasTabela;
    },

    criaLinhasTotal(linhasTabela) {
      linhasTabela.push({ perfil_contrato: "total" });
      linhasTabela.forEach((perfilContrato) => {
        if (perfilContrato.perfil_contrato == "total") return;
        Object.entries(perfilContrato).forEach((entries) => {
          const perfilCliente = entries[0];
          const total = entries[1];
          if (perfilCliente == "perfil_contrato") return;
          if (linhasTabela[linhasTabela.length - 1][perfilCliente]) {
            linhasTabela[linhasTabela.length - 1][perfilCliente] += total;
          } else {
            linhasTabela[linhasTabela.length - 1][perfilCliente] = 0 + total;
          }
        });
      });

      return linhasTabela;
    },

    criaTabela(dadosTabela) {
      if (!dadosTabela?.length) return;

      const cabecalho = this.criaCabecalhoTabela(dadosTabela);
      const linhas = this.criaLinhasTabela(dadosTabela, cabecalho);
      const linhasTotal = this.criaLinhasTotal(linhas);

      this.linhasTabela = linhasTotal;
      this.cabecalhoContratos = cabecalho;
    },

    visualizarCanais({ perfil_contrato }, perfil_cliente) {
      let cliente = null;
      let contrato = null;

      cliente = perfil_cliente;
      contrato = perfil_contrato;

      if (perfil_cliente === "sem_perfil") cliente = "null";
      if (perfil_contrato === "sem_perfil") contrato = "null";
      if (perfil_cliente === "total") cliente = "";
      if (perfil_contrato === "total") contrato = "";

      const empresaChaveDash = this.empresaSelecionada.chave + ".dashboard_canais";
      const linkMetabase = this.empresaSelecionada.configuracoes?.find(
        (config) => config.chave === empresaChaveDash
      );

      window.open(
        `${linkMetabase?.valor}?perfil_cliente=${cliente}&perfil_contrato=${contrato}`,
        "_blank"
      );
    },

    verificaPerfisExistentes(tipo, dados, cabecalho) {
      let array = [];
      if (tipo == "perfil_cliente") {
        array = cabecalho.reduce(function (array, b) {
          if (array.indexOf(b.value ?? "sem_perfil") < 0) array.push(b.value ?? "sem_perfil");
          return array;
        }, []);
      } else {
        array = dados.reduce(function (array, b) {
          if (array.indexOf(b.perfil_contrato ?? "sem_perfil") < 0)
            array.push(b.perfil_contrato ?? "sem_perfil");
          return array;
        }, []);
      }
      return array;
    },

    filtrarReguasPerfil(perfilContrato, perfilCliente) {
      if (!this.reguasFiltradas) {
        return [];
      }

      if (perfilCliente == "sem_perfil") {
        perfilCliente = null;
      }
      if (perfilContrato == "sem_perfil") {
        perfilContrato = null;
      }

      let reguas = this.reguasFiltradas.flat();

      reguas = reguas.filter((regua) => {
        if (regua.status != "ARQUIVADA") {
          return regua.perfis.some((perfil) => {
            return (
              perfil.perfil_contrato == perfilContrato && perfil.perfil_cliente == perfilCliente
            );
          });
        }
      });
      return reguas;
    },

    abrirDialogLinkarRegua(perfilContrato, perfilCliente, edita) {
      this.$refs.dialogLinkarRegua.abrirDialog(
        this.reguasFiltradas.flat(),
        perfilCliente,
        perfilContrato,
        edita
      );
    },

    verificaCampoTotal(coluna, perfil) {
      for (const valor in coluna) {
        if (valor == perfil) {
          return Vue.filter("numero")(coluna[valor] != "total" ? coluna[valor] : "");
        }
      }
    },

    hoverItem(chave, item) {
      this.chaveSelecionada = chave;
      this.itemSelecionado = item;
    },

    unHoverItem() {
      if (!this.menuVisible) {
        this.chaveSelecionada = false;
        this.itemSelecionado = false;
      }
    },
  },

  computed: {
    ...mapGetters("reguas", {
      carregandoResumoContratos: "carregandoResumoContratos",
      erroCarregarResumoContratos: "erroCarregarResumoContratos",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    linhasTabelaFiltrado() {
      return this.linhasTabela.filter((linha) => {
        if (!this.filtro) return true;
        return linha.perfil_contrato
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.filtro
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(" ", "_")
          );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-wraper {
  max-width: 1000px;
}
.tabela tr > td {
  border-right: 1px solid #dddddd;
}

.celula-perfis {
  display: grid;
  grid-template-columns: 32px auto 32px;
  gap: 0.5rem;
  align-items: center;
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
  td:hover {
    background-color: #eeeeee !important;
  }
}
.perfil-sem-regua {
  color: #bdbdbd;
}
</style>
