<template>
  <div class="pt-8 pb-8 container">
    <v-app-bar app>
      <div class="text-h6 font-weight-bold ml-3">Iniciativas WhatsApp</div>
      <v-spacer></v-spacer>
      <v-switch
        hide-details=""
        label="Iniciativas arquivadas"
        class="mr-4"
        v-model="mostrarArquivadas"
      ></v-switch>
    </v-app-bar>
    <Tabela
      :mostrarArquivadas="mostrarArquivadas"
      :listaIniciativa="listaIniciativa"
      :carregando="carregando"
      @editar="abrirDialogEditar"
      @alterarEstado="alterarEstado"
    />

    <DialogAlterar
      ref="dialogAlterar"
      :listaIniciativas="listaIniciativa"
      :listaRemetentes="listaRemetentes"
      @cadastrar="novoItem"
      @editar="alterarItem"
    />

    <FloatingBtn @click="abrirDialog({ operacao: 'cadastrar' })" />
  </div>
</template>

<script>
import Tabela from "../componentes/Tabela.vue";
import DialogAlterar from "../componentes/DialogAlterar.vue";
import { mapActions, mapGetters } from "vuex";
import service from "@/services/empresa";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

export default {
  components: {
    Tabela,
    DialogAlterar,
    FloatingBtn,
  },
  data() {
    return {
      mostrarArquivadas: false,
      listaRemetentes: [],
      camposDesnecessarios: [
        "criado_por",
        "criado_em",
        "empresa_id",
        "atualizado_em",
        "atualizado_por",
      ],
    };
  },
  computed: {
    ...mapGetters("iniciativas", ["listaIniciativa", "carregando"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.carregarDados();
      },
      deep: true,
    },
  },
  async created() {
    await this.carregarDados();
  },
  methods: {
    ...mapActions("iniciativas", {
      carregarItens: "listar",
      adicionarItem: "criar",
      editarItem: "editar",
    }),
    async carregarDados() {
      await this.carregarItens({
        empresa_id: this.empresaSelecionada.id,
        limite: 10000,
        ordem: "status",
      });
      await service
        .getEmpresaPorId(this.empresaSelecionada, {
          incluir: "REMETENTES_WHATSAPP",
        })
        .then((retorno) => {
          this.listaRemetentes = retorno?.data?.remetentes_whatsapp ?? [];
        });
    },
    limparOpcoesVazias(iniciativa) {
      for (const [atributo, valor] of Object.entries(iniciativa)) {
        if (!valor || valor === "") {
          delete iniciativa[atributo];
        }
      }
    },
    limparDesnecessarios(iniciativa) {
      this.camposDesnecessarios.forEach((campo) => {
        delete iniciativa[campo];
      });
    },
    abrirDialog(payload) {
      return this.$refs.dialogAlterar.abrir(payload);
    },
    abrirDialogEditar({ id }) {
      const iniciativa = this.listaIniciativa.find((item) => item.id == id);
      const payload = structuredClone(iniciativa);
      this.abrirDialog({ operacao: "editar", iniciativa: payload });
    },
    async novoItem(item) {
      const novoItem = { ...item, empresa_id: this.empresaSelecionada.id };
      this.limparOpcoesVazias(novoItem);
      this.adicionarItem(novoItem);
    },
    async alterarItem(item) {
      const itemAlterado = { ...item };
      this.limparDesnecessarios(itemAlterado);
      this.editarItem(itemAlterado);

      const itemAMover = this.listaIniciativa.findIndex(
        (i) => i.id === itemAlterado.id
      );
      this.listaIniciativa.splice(itemAMover, 1);
      this.listaIniciativa.unshift(itemAlterado);
    },
    async alterarEstado({ id, estado }) {
      const iniciativa = {
        ...this.listaIniciativa.find((item) => item.id == id),
      };
      iniciativa.ativo = estado;
      this.limparDesnecessarios(iniciativa);
      this.editarItem(iniciativa);
    },
  },
};
</script>

<style lang="scss" scoped></style>
