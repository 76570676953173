<template>
  <v-speed-dial v-model="floatingBtn" class="floating-dial" direction="left">
    <template v-slot:activator>
      <v-hover v-slot="{ hover }">
        <div
          class="floating-btn-container"
          :class="{ hovered: hover, active: floatingBtn }"
        >
          <div
            :class="{
              active: floatingBtn,
              'floating-btn': true,
              hovered: hover,
            }"
            @click="$emit('click')"
          >
            <v-fade-transition hide-on-leave>
              <v-icon
                key="add"
                v-if="hover && !floatingBtn"
                class="add"
                color="white"
              >
                mdi-plus
              </v-icon>
              <v-icon
                key="close"
                v-else-if="floatingBtn"
                class="add"
                color="white"
              >
                mdi-close
              </v-icon>
              <v-icon key="arrow" v-else class="arrow" color="white">
                mdi-chevron-left
              </v-icon>
            </v-fade-transition>
          </div>
        </div>
      </v-hover>
    </template>
    <slot name="content"></slot>
  </v-speed-dial>
</template>

<script>
export default {
  data() {
    return {
      floatingBtn: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.scroll-visible {
  .floating-dial {
    position: fixed;
    bottom: 48px;
    right: 72px;
  }
  .floating-btn-container {
    position: fixed;
    bottom: 24px;
    right: 17px;
  }
}
.floating-btn-container {
  transition: width ease-in 0.25s;
  width: 30px;
  position: fixed;
  bottom: 24px;
  right: 0px;
  &.hovered,
  &.active {
    width: 55px;
    .floating-btn {
      width: 48px;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      right: 21px;
    }
  }
  &.active {
    opacity: 0.8;
  }
}

.floating-dial {
  position: fixed;
  bottom: 48px;
  right: 52px;
}

.floating-btn {
  transition: ease-in 0.25s;
  cursor: pointer;
  background: #3f51b5;
  color: white;

  width: 32px;
  height: 48px;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
