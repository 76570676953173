<template>
  <v-card
    outlined
    class="mr-6 my-auto"
    :width="event.tipo == 'SMS' ? '300' : '400'"
    :min-width="event.tipo == 'SMS' ? '300' : '400'"
    :color="event.status == 'ARQUIVADA' ? 'grey lighten-1' : ''"
  >
    <!-- Título SMS -->
    <v-card-text
      v-if="event.tipo == 'SMS'"
      class="amber text--primary py-2"
      :class="{
        'amber lighten-4': event.status == 'PRODUCAO',
        'grey lighten-3 text--disabled': event.status == 'RASCUNHO',
        'grey lighten-1': event.status == 'ARQUIVADA',
      }"
    >
      <p
        class="caption font-weight-bold mb-0"
        :class="{
          'text-decoration-line-through': event.status == 'ARQUIVADA',
        }"
        style="display: flex; justify-content: space-between; align-items: flex-end"
      >
        <span>
          <div v-if="!multiplo">
            {{ event.sms }}
          </div>
          <div v-else>
            {{ event.evento_multiplo.sms }}
          </div>
        </span>
        <v-btn
          icon
          small
          @click="switchEvento"
          style="bottom: -5px; right: -9px; z-index: 1"
          v-if="event.multiplo"
        >
          <v-icon small>{{
            !multiplo ? "mdi-comment-text-multiple-outline" : "mdi-comment-text-outline"
          }}</v-icon>
        </v-btn>
      </p>
    </v-card-text>

    <!-- Título WHATSAPP -->
    <v-card-text
      v-if="event.tipo == 'WHATSAPP'"
      class="amber text--primary py-2"
      :class="{
        'amber lighten-4': event.status == 'PRODUCAO',
        'grey lighten-3 text--disabled': event.status == 'RASCUNHO',
        'grey lighten-1': event.status == 'ARQUIVADA',
      }"
    >
      <p
        class="caption font-weight-bold mb-0"
        :class="{
          'text-decoration-line-through': event.status == 'ARQUIVADA',
        }"
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          cursor: pointer;
        "
        @click="abrirDialogPreVisualizarWhatsapp()"
      >
        <span>
          <div v-if="!multiplo">
            {{ event.whatsapp_iniciativa.mensagem }}
          </div>
          <div v-else>
            {{ event.evento_multiplo.whatsapp_iniciativa.mensagem }}
          </div>
        </span>
        <v-btn
          icon
          small
          @click.stop="switchEvento"
          style="bottom: -5px; right: -9px; z-index: 1"
          v-if="event.multiplo"
        >
          <v-icon small>{{
            !multiplo ? "mdi-comment-text-multiple-outline" : "mdi-comment-text-outline"
          }}</v-icon>
        </v-btn>
      </p>
    </v-card-text>

    <v-card-text
      v-if="event.tipo == 'LIGACAO_CELULAR' || event.tipo == 'LIGACAO_FIXO'"
      class="pa-0"
      :class="{
        'amber lighten-4': event.status == 'PRODUCAO',
        'grey lighten-3 text--disabled': event.status == 'RASCUNHO',
        'grey lighten-1': event.status == 'ARQUIVADA',
      }"
    ></v-card-text>

    <!-- Título COMANDO -->
    <v-card-text
      v-if="event.tipo == 'COMANDO'"
      class="amber text--primary py-2"
      :class="{
        'amber lighten-4': event.status == 'PRODUCAO',
        'grey lighten-3 text--disabled': event.status == 'RASCUNHO',
        'grey lighten-1': event.status == 'ARQUIVADA',
      }"
    >
      <p
        class="caption font-weight-bold mb-0"
        :class="{
          'text-decoration-line-through': event.status == 'ARQUIVADA',
        }"
      >
        POST {{ event.url_comando }}
      </p>
    </v-card-text>

    <!-- Título EMAIL -->
    <v-card-text
      v-if="event.tipo == 'EMAIL'"
      class="text--primary py-2"
      :class="{
        'text--disabled': event.status == 'RASCUNHO',
      }"
    >
      <b
        :class="{
          'text-decoration-line-through': event.status == 'ARQUIVADA',
          'text--disabled': event.status == 'RASCUNHO',
        }"
      >
        <div>
          {{ templateData.assunto }}
        </div>
      </b>
    </v-card-text>

    <!-- Título PUSH -->
    <v-card-text
      v-if="event.tipo == 'PUSH'"
      class="text--primary py-2"
      :class="{
        'text--disabled': event.status == 'RASCUNHO',
      }"
    >
      <b
        :class="{
          'text-decoration-line-through': event.status == 'ARQUIVADA',
          'text--disabled': event.status == 'RASCUNHO',
        }"
      >
        <div v-if="!multiplo">
          {{ event.push_titulo }}
        </div>
        <div v-else>
          {{ event.evento_multiplo.push_titulo }}
        </div>
      </b>
    </v-card-text>

    <v-card-text
      v-if="event.tipo == 'LIGACAO_CELULAR' || event.tipo == 'LIGACAO_FIXO'"
      class="text--primary py-2"
      :class="{
        'text--disabled': event.status == 'RASCUNHO',
      }"
    >
      <b
        :class="{
          'text-decoration-line-through': event.status == 'ARQUIVADA',
          'text--disabled': event.status == 'RASCUNHO',
        }"
      >
        {{ event.campanha.campanha }}</b
      >
    </v-card-text>
    <!-- Img -->

    <v-divider v-if="event.tipo == 'EMAIL'" />

    <v-hover v-slot="{ hover }" v-if="event.tipo == 'EMAIL'">
      <div style="position: relative; cursor: pointer">
        <div @click="templateData.html ? (dialog = true) : undefined" class="qj-preview-email">
          <div v-if="hover" class="preview-hover"></div>
          <div v-if="event.status === 'RASCUNHO'" class="preview-rascunho repeating-gradient"></div>

          <div
            class="d-flex justify-center align-center fill-height ma-0"
            v-if="!templateData.html && !loadingTemplate"
          >
            <v-icon class="mr-2" size="x-large"> mdi-block-helper </v-icon>
          </div>
          <div class="d-flex justify-center align-center fill-height ma-0" v-if="loadingTemplate">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <div v-else>
            <iframe
              scrolling="no"
              ref="iframeThumb"
              :srcdoc="templateData.html"
              :key="templateData.html"
              @load="estilosIFrame"
            ></iframe>
          </div>
        </div>
        <v-icon
          v-if="event.multiplo"
          @click.stop="switchEmailEvento"
          size="medium"
          style="position: absolute; bottom: 10px; right: 15px; z-index: 1"
        >
          {{ !multiplo ? "mdi-email-multiple-outline" : "mdi-email-outline" }}
        </v-icon>
      </div>
    </v-hover>

    <v-divider v-if="event.tipo == 'PUSH' && event.push_corpo"></v-divider>

    <v-card-text
      v-if="event.tipo == 'PUSH' && event.push_corpo"
      class="py-2 status-evento"
      :class="event.status"
      style="display: flex; justify-content: space-between; align-items: flex-end"
    >
      <span>
        <div v-if="!multiplo">
          {{ event.push_corpo }}
        </div>
        <div v-else>
          {{ event.evento_multiplo.push_corpo }}
        </div>
      </span>
      <v-icon v-if="event.multiplo" @click="switchEvento" size="medium">
        {{ !multiplo ? "mdi-comment-text-multiple-outline" : "mdi-comment-text-outline" }}
      </v-icon>
    </v-card-text>

    <v-divider v-if="event.tipo == 'PUSH' && event.push_imagem"></v-divider>

    <v-img
      v-if="event.tipo == 'PUSH' && event.push_imagem"
      height="100"
      :contain="true"
      :src="event.push_imagem"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="primary" :width="2"></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-card-text
      v-if="event.tipo == 'LIGACAO_CELULAR' || event.tipo == 'LIGACAO_FIXO'"
      class="py-2 status-evento"
      :class="event.status"
    >
      {{ event.campanha.roteiro }}
    </v-card-text>

    <!-- Ações -->
    <v-divider />
    <v-card-actions
      :class="{
        'text--disabled': event.status == 'RASCUNHO',
      }"
    >
      <!-- Switch de iniciar/parar evento -->
      <v-switch
        hide-details
        class="my-0 mr-1 pa-0"
        :input-value="event.status == 'PRODUCAO'"
        @change.stop.native.capture=""
        @click:append.stop.native.capture=""
        @click:prepend.stop.native.capture=""
        @click.stop.native.capture="changeEventStatus()"
        v-if="event.status != 'ARQUIVADA' && checkCredentials(['editor_regua'])"
      ></v-switch>
      <v-icon
        v-else-if="checkCredentials(['editor_regua'])"
        class="grey lighten-2 pa-1 rounded-circle mr-2"
        size="18"
        color="grey"
      >
        mdi-archive-outline
      </v-icon>
      <!-- Observação do evento -->
      <v-tooltip bottom :disabled="!event.observacao">
        <template v-slot:activator="{ on, attrs }">
          <p
            v-bind="attrs"
            v-on="on"
            class="text-truncate mb-0 mr-1"
            :class="{
              'text-decoration-line-through': event.status == 'ARQUIVADA',
            }"
          >
            {{ event.nome }}
          </p>
        </template>
        <span>{{ event.observacao }}</span>
      </v-tooltip>
      <v-spacer />
      <v-chip
        v-if="event?.nps_id"
        small
        class="mr-1"
        :color="event.status == 'PRODUCAO' ? 'green' : 'grey'"
        text-color="white"
        style="min-width: max-content"
      >
        NPS
      </v-chip>
      <v-chip
        v-if="event?.pesquisa_id"
        small
        class="mr-1"
        :color="event.status == 'PRODUCAO' ? 'green' : 'grey'"
        text-color="white"
        style="min-width: max-content"
      >
        Pesquisa
      </v-chip>
      <!-- Tipo do evento -->
      <v-chip
        small
        dark
        class="tipo-evento"
        :class="event.status == 'PRODUCAO' ? event.tipo : 'grey'"
        style="min-width: max-content"
      >
        {{ event.tipo | tipoEvento }}
      </v-chip>
      <!-- Menu do card -->
      <v-menu left offset-y v-if="checkCredentials(['editor_regua'])">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon v-bind="attrs" v-on="on" class="ml-2">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list class="pt-0 pb-0">
          <v-list-item @click="editarEvento()">
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item @click="changeEventStatus(true)">
            <v-list-item-title>
              {{ event.status == "ARQUIVADA" ? "Ativar" : "Arquivar" }}
            </v-list-item-title>
          </v-list-item>
          <v-divider v-if="checkCredentials(['editor_regua'])" />
          <v-list-item v-if="checkCredentials(['editor_regua'])" @click="testarEvento(event)">
            <v-list-item-title> Testar </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>

    <DialogTestarEvento :regua="regua" :evento="event" ref="dialogTestarEvento" />
    <DialogTemplateEmail :dialog.sync="dialog" :template="templateData" />
    <DialogPreVisualizarWhatsapp
      ref="dialogPreVisualizarWhatsapp"
      :iniciativa="
        !multiplo ? event.whatsapp_iniciativa : event.evento_multiplo.whatsapp_iniciativa
      "
    />
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import DialogTemplateEmail from "../dialogs/DialogTemplateEmail";
import DialogPreVisualizarWhatsapp from "../dialogs/DialogPreVisualisarWhatsapp.vue";
import DialogTestarEvento from "../dialogs/dialogsEvento/DialogTestarEvento.vue";
import emailService from "@/services/emails.js";

export default {
  name: "CardTimeline",
  props: {
    event: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapState("regua", ["regua"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  watch: {
    event: {
      handler() {
        this.multiplo = false;
      },
      deep: true,
    },
  },
  components: {
    DialogTemplateEmail,
    DialogPreVisualizarWhatsapp,
    DialogTestarEvento,
  },
  data: () => ({
    dialog: false,
    multiplo: false,
    templateData: {
      html: null,
      assunto: null,
    },
    loadingTemplate: false,
  }),
  async created() {
    if (this.event.template_email_id) {
      await this.carregaTemplate();
    }
  },
  methods: {
    checkCredentials,
    changeEventStatus(isDisabled = false) {
      let nameEmit = this.event.status == "PRODUCAO" ? "stop" : "start";
      if (isDisabled) nameEmit = this.event.status == "ARQUIVADA" ? "stop" : "disabled";
      this.$emit(nameEmit, this.event);
    },
    testarEvento(evento) {
      this.$refs.dialogTestarEvento.abrirDialog();
    },
    editarEvento() {
      this.$emit("open", this.event);
    },
    abrirDialogPreVisualizarWhatsapp() {
      this.$refs.dialogPreVisualizarWhatsapp.abrirDialog();
    },
    switchEvento() {
      this.multiplo = !this.multiplo;
    },
    async switchEmailEvento() {
      this.multiplo = !this.multiplo;
      await this.carregaTemplate(this.multiplo);
    },
    async carregaTemplate(multiplo = false) {
      this.loadingTemplate = true;
      try {
        const { data: templateData } = await emailService.get({
          status: "PRODUCAO",
          tipo_regua: this.regua.tipo,
          empresa_id: this.empresaSelecionada.id,
          busca: multiplo
            ? this.event.evento_multiplo.template_email_id
            : this.event.template_email_id,
        });

        const { data: htmlData } = await emailService.renderizar(
          multiplo ? this.event.evento_multiplo.template_email_id : this.event.template_email_id,
          {
            empresa_id: this.empresaSelecionada.id,
            tipo_regua: this.regua.tipo,
          }
        );
        this.templateData.html = htmlData;
        this.templateData.assunto = templateData.objects[0].assunto;
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingTemplate = false;
      }
    },
    estilosIFrame() {
      const frame = this.$refs.iframeThumb.contentWindow;
      const css = document.createElement("style");
      css.textContent = `
      body {
        zoom: 0.3;
      }
      `;
      frame.document.head.appendChild(css);
    },
  },
};
</script>

<style scoped lang="scss">
.icon-container {
  position: absolute;
  bottom: 0;
  right: 0;
}
.qj-preview-email {
  height: 150px;
  .preview-rascunho {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .preview-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  iframe {
    width: 100%;
    overflow: hidden;
    border: none;
  }
}
</style>
