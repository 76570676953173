<template>
  <div>
    <div
      v-if="
        editandoEvento &&
        erroEditarPrecificacaoEvento &&
        erroEditarPrecificacaoEvento.length
      "
    >
      <div v-for="err in erroEditarPrecificacaoEvento" :key="err.motivo_erro">
        <v-alert v-if="err.precificacao_id" text outlined type="error" dense>
          <span>
            <b>{{ err.precificacao_id }}</b
            >: {{ err.motivo_erro }}
          </span>
        </v-alert>
      </div>
    </div>
    <v-data-table
      :headers="cabecalhoTabelaEvento"
      :items="eventos"
      class="elevation-0"
      no-data-text="Nenhuma precificação de eventos cadastrada."
      hide-details
      hide-default-footer
      disable-pagination
      loading-text="Carregando precificação..."
    >
      <template v-slot:[`item.id`]="{ item }">
        <span class="my-2 text--disabled">#{{ item.id }}</span>
      </template>
      <template v-slot:[`item.categoria`]="{ item }">
        <span class="my-2">{{ item.categoria_apelido || "-" }}</span>
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        <div v-show="item.editando">
          <v-text-field
            style="max-width: 120px"
            dense
            hide-details
            type="number"
            hide-spin-buttons
            min="0"
            autofocus
            v-model="item.valor"
          ></v-text-field>
        </div>
        <span v-show="!item.editando" class="my-2">{{
          item.valor | formatadorRealDecimaisNCasas
        }}</span>
      </template>
      <template v-if="permissaoEditar" v-slot:[`item.acoes`]="{ item }">
        <div
          class="d-flex align-center justify-space-between"
          v-if="item.editando"
        >
          <v-icon
            style="font-size: 1rem"
            @click="cancelaEdicaoEvento(item.id)"
            class="my-2"
            >mdi-close</v-icon
          >
        </div>
        <div v-if="!item.editando">
          <v-icon
            style="font-size: 1rem"
            @click="handleEdicaoEvento(item.id)"
            class="edit-button"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import precificacaoService from "@/services/fatura";
import { mapGetters, mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";

export default {
  props: {
    precificacoesEvento: {
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      checkCredentials,
      cabecalhoTabelaEvento: [
        {
          text: "",
          sortable: false,
          align: "start",
          value: "id",
          width: "5%",
        },
        {
          text: "Categoria",
          sortable: false,
          align: "start",
          value: "categoria",
          width: "60%",
        },
        {
          text: "Valor",
          sortable: false,
          value: "valor",
          width: "30%",
        },
        {
          text: "",
          sortable: false,
          value: "acoes",
          width: "10%",
          align: "end",
        },
      ],

      tab: 0,
      eventos: [],
      erroEditarPrecificacaoEvento: [],
      mudouEventos: false,
      mudancasEvento: [],
    };
  },
  created() {
    this.limpaPrecificacoes();
    this.eventos = this.precificacoesEvento
      .map((obj) => ({ ...obj }))
      .sort((a, b) => {
        const categoriaApelidoA = a.categoria_apelido
          ? a.categoria_apelido.toLowerCase()
          : "";
        const categoriaApelidoB = b.categoria_apelido
          ? b.categoria_apelido.toLowerCase()
          : "";

        if (!a.categoria_apelido && b.categoria_apelido) {
          return -1;
        }
        if (!b.categoria_apelido && a.categoria_apelido) {
          return 1;
        }

        return categoriaApelidoA.localeCompare(categoriaApelidoB);
      });
    this.setEventoWatcher();
  },
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    setEventoWatcher() {
      const criaMudanca = function (item, sourceItem) {
        return {
          id: sourceItem.id,
          categoria_apelido: sourceItem.categoria_apelido,
          valor: {
            new: parseFloat(item.valor),
            old: parseFloat(sourceItem.valor),
          },
        };
      };

      const verificaSeMudou = function (newValue, oldValue) {
        this.eventos.forEach((item) => {
          const sourceItem = this.precificacoesEvento.find(
            (sourceItem) => item.id === sourceItem.id
          );

          if (sourceItem) {
            const mudou =
              parseFloat(item.valor) != parseFloat(sourceItem.valor);

            if (mudou) {
              const mudanca = criaMudanca(item, sourceItem);
              const indexMudanca = this.mudancasEvento.findIndex(
                (mudancaItem) => mudancaItem.id === item.id
              );
              if (indexMudanca !== -1) {
                this.mudancasEvento[indexMudanca] = mudanca;
              } else {
                this.mudancasEvento.push(mudanca);
              }
            } else {
              this.mudancasEvento = this.mudancasEvento.filter(
                (mudancaItem) => mudancaItem.id !== sourceItem.id
              );
            }
          }
        });

        this.mudouEventos = this.mudancasEvento.length > 0;
        this.$emit("mudou", this.mudouEventos);
      };

      this.$watch("eventos", verificaSeMudou, {
        deep: true,
        immediate: false,
      });
    },

    limpaAlteracoes() {
      this.eventos = this.precificacoesEvento.map((item) => {
        return {
          ...item,
          editando: false,
        };
      });
    },

    handleEdicaoEvento(itemId) {
      this.eventos = this.eventos.map((item) => {
        if (item.id === itemId) {
          item.editando = true;
        }
        return item;
      });
    },

    async editarEventos() {
      this.erroEditarPrecificacaoEvento = [];

      const eventosFormatados = this.eventos.map((item) => {
        return {
          ...item,
          valor: parseFloat(item.valor),
        };
      });

      try {
        const responseEventos = await precificacaoService.editarPrecificacao(
          eventosFormatados,
          this.empresaSelecionada.id
        );
        if (responseEventos.status === 200) {
          this.eventos = [...responseEventos.data];
        }

        this.eventos = this.eventos.map((item) => {
          return {
            ...item,
            editando: false,
          };
        });
        this.erroEditarPrecificacaoEvento = [];
      } catch (e) {
        this.erroEditarPrecificacaoEvento = e.response?.data;
        throw new Error();
      }
    },

    cancelaEdicaoEvento(itemId) {
      const oldPrecificacao = this.precificacoesEvento.find(
        (item) => item.id == itemId
      );

      this.eventos = this.eventos.map((item) => {
        if (item.id === itemId && oldPrecificacao) {
          item = { ...oldPrecificacao };
          item.editando = false;
        }
        return item;
      });
    },

    async salvar() {
      try {
        await this.editarEventos();

        this.showSnackBar({
          text: `Precificação alterada com sucesso`,
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: `Erro ao alterar precificação`,
        });
      }
    },

    limpaPrecificacoes() {
      this.eventos = [];
      this.erroEditarPrecificacaoEvento = [];
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    permissaoEditar() {
      return checkCredentials(["admin", "editor_precificacao"]);
    },
    editandoEvento() {
      const editando = this.eventos.some((item) => item.editando);
      if (!editando) this.erroEditarPrecificacaoEvento = false;

      return editando;
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
.v-data-table__wrapper tr .edit-button {
  display: none;
}
.v-data-table__wrapper tr:hover .edit-button {
  display: block;
}
</style>
