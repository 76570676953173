<template>
  <v-dialog v-model="dialog" :width="380" persistent>
    <v-form
      v-if="canal"
      v-model="isFormValid"
      ref="form"
      @submit.prevent="handleAdicionarBlacklist"
    >
      <v-card>
        <v-card-title class="pt-4 pa-6 text-h5">
          Gerenciar blacklist
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="pa-6 pb-5 pt-0">
          <div>
            <p class="text-body-1 mb-1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon class="mr-2" v-on="on">{{ verificaIcone(canal) }}</v-icon>
                </template>
                <span>{{ canal.tipo | canais }}</span>
              </v-tooltip>
              {{ verificaTexto(canal) }}
            </p>
          </div>
          <v-radio-group required :rules="[rules.required]" v-model="formBlacklist.tipo_bloqueio">
            <template v-slot:label>
              <div class="">Tipo do bloqueio *</div>
            </template>
            <v-radio
              v-if="canal.tipo !== 'EMAIL'"
              label="Celular inválido"
              value="CELULAR_INVALIDO"
            ></v-radio>
            <v-radio
              v-if="canal.tipo === 'EMAIL'"
              label="Email inválido"
              value="EMAIL_INVALIDO"
            ></v-radio>
            <v-radio label="Solicitação da empresa" value="SOLICITACAO_EMPRESA"></v-radio>
            <v-radio label="Solicitação do cliente" value="SOLICITACAO_CLIENTE"></v-radio>
          </v-radio-group>

          <v-radio-group required :rules="[rules.required]" v-model="formBlacklist.bloquear">
            <template v-slot:label>
              <div>Bloquear *</div>
            </template>
            <v-radio label="Apenas para este cliente" value="UNICO"></v-radio>
            <v-radio label="Para todos os clientes" value="TODOS"></v-radio>
          </v-radio-group>

          <v-text-field
            label="Bloquear Até"
            type="date"
            outlined
            v-model="formBlacklist.bloqueado_ate"
            class="mt-4"
            style="max-width: 190px"
            :min="new Date().toISOString().substring(0, 10)"
            clearable
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-btn small v-show="canal.blacklist" color="error" text @click="handleRemoverBlacklist">
            Remover da Blacklist
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="primary" text @click="fecharDialog()"> cancelar </v-btn>
          <v-btn small :disabled="!isFormValid" color="primary" type="submit"> salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Vue from "vue";

export default {
  data: () => ({
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    isFormValid: false,
    dialog: false,
    canal: null,
    index: null,
    formBlacklist: {
      tipo_bloqueio: null,
      bloquear: null,
      bloqueado_ate: null,
    },
  }),
  methods: {
    abrirDialog() {
      this.dialog = true;

      if (this.canal.blacklist) {
        this.formBlacklist = {
          tipo_bloqueio: this.canal.blacklist.tipo_bloqueio,
          bloquear: this.canal.blacklist.documento ? "UNICO" : "TODOS",
          bloqueado_ate: this.canal.blacklist.bloqueado_ate,
        };
      }
    },
    fecharDialog() {
      this.formBlacklist = {
        tipo_bloqueio: null,
        bloquear: null,
        bloqueado_ate: null,
      };
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
    handleAdicionarBlacklist() {
      if (this.canal.blacklist?.id) {
        this.$emit("atualizar", this.index, this.canal, this.formBlacklist);
      } else {
        this.$emit("adicionar", this.index, this.canal, this.formBlacklist);
      }
    },
    handleRemoverBlacklist() {
      this.$emit("remover", this.index, this.canal);
    },
    verificaTexto(canal) {
      if (canal.email) {
        return canal.email;
      } else if (canal.numero) {
        return Vue.filter("telefone")(canal.numero);
      } else {
        return "-";
      }
    },
    verificaIcone(canal) {
      if (canal.tipo == "EMAIL") {
        return "mdi-email";
      } else if (canal.tipo == "WHATSAPP") {
        return "mdi-whatsapp";
      } else if (canal.tipo == "SMS") {
        return "mdi-message";
      } else if (canal.tipo == "LIGACAO_CELULAR") {
        return "mdi-cellphone";
      } else if (canal.tipo == "LIGACAO_FIXO") {
        return "mdi-phone";
      } else {
        return "mdi-bell";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
