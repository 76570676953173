<template>
  <div>
    <v-skeleton-loader
      v-if="carregando"
      class="mx-auto"
      max-width="1000"
      type="table-tbody"
    ></v-skeleton-loader>
    <v-data-table
      v-else-if="!carregando && listaOrdenada.length"
      :headers="cabecalhos"
      :items="listaOrdenada"
      :items-per-page="-1"
      class="elevation-1 tabela"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr class="text-center" v-show="carregando">
            <td colspan="12">Carregando perguntas</td>
          </tr>
          <tr class="text-center" v-show="listaNps.length === 0 && !carregando">
            <td colspan="12">Nenhuma pergunta encontrada</td>
          </tr>

          <tr
            v-for="item in items"
            :key="item?.id"
            :class="item?.status == 'ARQUIVADA' ? 'pergunta-arquivada' : ''"
            @mouseover="ativarMenu(item)"
            @mouseleave="desativarMenu()"
          >
            <td class="text-start">#{{ item.id }}</td>
            <td class="text-body-1 font-weight-medium pergunta">
              {{ item.pergunta }}
            </td>
            <td class="text-center estado d-flex justify-end align-center">
              <v-chip
                small
                :color="corDoEstado[item.status].fundo"
                :text-color="corDoEstado[item.status].texto"
              >
                {{ `${item.status}` | eventoStatus }}
              </v-chip>
            </td>
            <td class="text-start menuAcoes">
              <v-menu
                offset-x
                offset-y
                v-if="item?.id === existeItemSelecionado"
                v-model="menuAberto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="action-button" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="linkMetabase">
                    <v-list-item-title>
                      <v-btn
                        plain
                        @click="emitirOpcao('navegar', item.id)"
                      >
                        Dashboard
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn plain @click="emitirOpcao('editar', item.id)">
                        Editar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item?.status == 'PRODUCAO'">
                    <v-list-item-title>
                      <v-btn plain @click="emitirOpcao('despublicar', item.id)">
                        Despublicar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item?.status == 'RASCUNHO'">
                    <v-list-item-title>
                      <v-btn plain @click="emitirOpcao('publicar', item.id)">
                        Publicar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item?.status !== 'ARQUIVADA'">
                    <v-list-item-title>
                      <v-btn plain @click="emitirOpcao('arquivar', item.id)">
                        Arquivar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item?.status == 'ARQUIVADA'">
                    <v-list-item-title>
                      <v-btn plain @click="emitirOpcao('desarquivar', item.id)">
                        Desarquivar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-row v-else class="mt-8">
      <v-col cols="12">
        <h2 class="grey--text text-center">Nenhum NPS cadastrado</h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    listaNps: Array,
    carregando: Boolean,
    mostrarArquivadas: Boolean,
  },
  data() {
    return {
      empresaChaveDash: null,
      linkMetabase: null,
      itemSelecionado: false,
      menuAberto: false,
      cabecalhos: [
        { text: "", value: "id", width: "2%", sortable: false },
        { text: "Pergunta", value: "pergunta" },
        { text: "", value: "status", align: "end" },
        {
          text: "",
          value: "actions",
          width: "50",
          align: "end",
          sortable: false,
        },
      ],
      corDoEstado: {
        PRODUCAO: {
          fundo: "#4CAF50",
          texto: "#FFFFFF",
        },
        RASCUNHO: {
          fundo: "#2196F3",
          texto: "#FFFFFF",
        },
        ARQUIVADA: {
          fundo: "#999999",
          texto: "#FFFFFF",
        },
      },
    };
  },
  async created() {
    this.empresaChaveDash = this.empresaSelecionada.chave + ".dashboard_nps"
    this.linkMetabase = this.empresaSelecionada.configuracoes?.find((config) => config.chave === this.empresaChaveDash)
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    listaOrdenada() {
      let ordem = {
        PRODUCAO: 1,
        RASCUNHO: 2,
        ARQUIVADA: 3,
      };

      return this.listaNps
        .filter((item) => {
          if (this.mostrarArquivadas) return true;
          else if (item.status == "ARQUIVADA") return false;
          return true;
        })
        .sort((a, b) => ordem[a.status] - ordem[b.status]);
    },
    existeItemSelecionado() {
      return this.itemSelecionado;
    },
  },
  methods: {
    ativarMenu(item) {
      if (!this.menuAberto) this.itemSelecionado = item.id;
      return;
    },
    desativarMenu() {
      if (!this.menuAberto) this.itemSelecionado = false;
      return;
    },
    fecharMenu() {
      this.menuAberto = false;
      return;
    },
    emitirOpcao(opcao, id) {
      if (opcao == "navegar") {
        this.$emit("navegar", `${this.linkMetabase?.valor}?nps_id=${id}`);
        return
      }

      this.$emit(opcao, id);
      this.fecharMenu();
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabela {
  min-width: 450px;
}

.pergunta-arquivada {
  color: lightgrey !important;
}

.tipo,
.estado {
  text-transform: capitalize;
}

.menuAcoes {
  width: 45px;
  padding: 0px 0px !important;
  padding-right: 12px !important;
}
</style>
