import { render, staticRenderFns } from "./DialogPausarNotificacao.vue?vue&type=template&id=4a2faddc&scoped=true"
import script from "./DialogPausarNotificacao.vue?vue&type=script&lang=js"
export * from "./DialogPausarNotificacao.vue?vue&type=script&lang=js"
import style0 from "./DialogPausarNotificacao.vue?vue&type=style&index=0&id=4a2faddc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a2faddc",
  null
  
)

export default component.exports