<template>
  <v-card v-if="evidencia" elevation="3" class="caixa-sms">
    <p v-html="visualizarConteudo()"></p>
  </v-card>
</template>

<script>
export default {
  props: ["evidencia"],
  methods: {
    transformarConteudo(texto) {
      let urlRegex = /(https?:\/\/[^\s]+)/g;
      return texto.replace(
        urlRegex,
        (url) => `<a target="_blank" href="${url}">${url}</a>`
      );
    },
    visualizarConteudo() {
      let evidencia = this.evidencia?.sms ?? this.evidencia?.fast_sms;
      return this.transformarConteudo(evidencia);
    },
  },
};
</script>

<style scoped lang="scss">
.caixa-sms {
  padding: 22px 35px 22px 50px;
  margin: auto;
  width: 100%;
  color: #282828;
  border-radius: 20px;
  border-bottom-right-radius: 0px;

  p {
    margin-bottom: 0px;
  }
}
</style>
