<template>
  <div class="full-height mb-16">
    <ReguaAppBar
      :tituloRegua="tituloRegua"
      :telaAtual="$route.query.tela ?? 'reguas'"
      :trocar-tela="trocarTela"
      @search="filtro = $event"
    />

    <!-- View Reguas -->
    <div
      class="reguas"
      v-if="$route.query.tela == 'reguas' || !$route.query.tela"
    >
      <!-- TABELA RESUMO CONTRATOS -->
      <v-alert v-if="erroCarregarResumoContratos" type="error" prominent text>
        <v-row align="center">
          <v-col class="grow"
            >Não foi possível carregar o resumo dos contratos.</v-col
          >
          <v-col class="shrink">
            <v-btn color="primary" @click="tabelaResumoContratos(true)">
              Tentar novamente
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-skeleton-loader
        v-if="carregando || carregandoResumoContratos"
        class="mx-auto mt-10 mb-2"
        max-width="1000"
        type="card-heading, table-heading, table-tbody"
      ></v-skeleton-loader>

      <TabelaResumoContratos
        v-else-if="!carregandoResumoContratos && !erroCarregarResumoContratos"
        :resumoContratos="resumoContratos"
        :reguasFiltradas="reguasFiltradas"
        @recarregarTabela="tabelaResumoContratos"
        :filtro="filtro"
      />

      <v-skeleton-loader
        v-if="carregando"
        class="mx-auto mt-10 mb-2"
        max-width="1000"
        type="table-heading, list-item"
      ></v-skeleton-loader>

      <ReguaExpansion
        :routeParams="routeParams"
        :filtroRegua="filtro"
        :reguasFiltradas="reguasFiltradas"
        :carregando="carregando"
        @filtraReguas="filtraReguas"
        @getTodasReguas="getTodasReguas"
        :tituloRegua="tituloRegua"
        :temReguas="temReguas"
        :quantidadeReguas="quantidadeReguas"
        :quantidadeReguasArquivadas="quantidadeReguasArquivadas"
        @atualizarRegua="atualizarRegua"
        :reguaSelecionada="reguaAberta ?? $route?.params?.regua"
        :todasReguas="reguas.objects"
        v-else-if="!erroCarregarReguas && !carregando"
      />

      <v-alert
        v-else-if="erroCarregarReguas"
        type="error"
        prominent
        text
        style="width: 1000px"
        class="mx-auto"
      >
        <v-row align="center">
          <v-col class="grow">Não foi possível carregar as réguas.</v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="getTodasReguas($route.query.tipo)">
              Tentar novamente
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <div v-if="!reguasFiltradas.length" class="pa-8 text-center grey--text">
        Nenhuma régua cadastrada
      </div>
    </div>
    <!-- Fim View Reguas -->

    <div v-if="$route.query.tela == 'variaveis'">
      <TelaVariaveis ref="telaDeVariaveis" />
    </div>

    <FloatingBtn @click="abrirDialogNovo($route.query.tipo)" />

    <PromiseBasedDialog
      ref="promiseBasedDialog"
      :options="promiseBasedDialogOptions"
    />
    <DialogNovaRegua
      ref="editOuNovaReguaDialog"
      :tituloMovimentacao="tituloMovimentacao"
      @addOrUpdateRegua="addOrUpdateRegua"
    />
  </div>
</template>

<script>
import PromiseBasedDialog from "@/components/dialogs/promiseBasedDialog.vue";
import DialogNovaRegua from "../components/dialogs/DialogNovaRegua.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import ReguaAppBar from "../components/ReguaAppBar.vue";
import TabelaResumoContratos from "../components/TabelaResumoContratos.vue";
import TelaVariaveis from "../components/TelaVariaveis.vue";
import ReguaExpansion from "../components/expansion/ReguaExpansion.vue";
import tipoMovimentacaoService from "@/services/tipo-movimentacao";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

export default {
  components: {
    PromiseBasedDialog,
    DialogNovaRegua,
    ReguaAppBar,
    TabelaResumoContratos,
    ReguaExpansion,
    FloatingBtn,
    TelaVariaveis,
  },
  data() {
    return {
      filtro: null,
      cabecalhoContratos: null,
      resumoContratos: null,
      carregando: false,
      erroCarregarReguas: false,
      reguasFiltradas: [],
      floatingBtn: false,
      routeParams: {
        arquivadas: false,
      },
      promiseBasedDialogOptions: {},
      tituloRegua: "",
      quantidadeReguas: 0,
      perfis: {
        contrato: {},
      },
      sem_perfilContrato: {},
      quantidadeReguasArquivadas: 0,
      tituloMovimentacao: "",
      telaAtual: "",
      reguaAberta: null,
    };
  },
  async created() {
    if (!this.$route.query.tipo) {
      this.$router.push({ name: "ListagemReguasPreDefinidas" });
      return;
    }
    if (
      this.$route.query.tipo == "MOVIMENTACAO" &&
      !this.$route.query.tipo_movimentacao_id
    ) {
      this.$router.push({ name: "ListagemReguasMovimentacao" });
      return;
    }
    if (this.empresaSelecionada.perfis_regua) {
      this.empresaSelecionada.perfis_regua.perfis_contrato =
        this.empresaSelecionada.perfis_regua.perfis_contrato || [];
      this.empresaSelecionada.perfis_regua.perfis_cliente =
        this.empresaSelecionada.perfis_regua.perfis_cliente || [];
    }
    if (this.$route?.params?.regua) {
      this.reguaAberta = this.$route?.params?.regua;
    }
    await this.getTodasReguas(this.$route.query.tipo);
    this.pegaQueryParams(this.$route.query);
    this.tabelaResumoContratos();
    this.atualizaQueryParams();
  },
  methods: {
    checkCredentials,
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("reguas", {
      getReguas: "getReguas",
      saveRegua: "saveRegua",
      carregarResumoContratos: "carregarResumoContratos",
    }),
    ...mapMutations("reguas", {
      setReguaSelecionada: "setReguaSelecionada",
    }),
    
    async atualizarRegua(regua) {
      const index = this.reguasFiltradas.findIndex(
        (item) => item.id == regua.id
      );
      this.reguasFiltradas[index] = Object.assign(
        {},
        this.reguasFiltradas[index],
        regua
      );
      this.reguasFiltradas = JSON.parse(JSON.stringify(this.reguasFiltradas));
      this.filtraReguas();
    },
    
    async getTodasReguas(tipo) {
      console.debug("Buscando as réguas.");

      this.carregando = true;
      this.erroCarregarReguas = false;

      let payload = {
        limite: 1000,
        tipo: tipo,
        tipo_movimentacao_id: this.$route.query.tipo_movimentacao_id,
      };
      try {
        await this.getReguas(payload);
        this.erroCarregarReguas = false;
      } catch (erro) {
        console.error("Não foi possível buscar as réguas.", erro);
        this.erroCarregarReguas = true;
      } finally {
        this.carregando = false;
        this.filtraReguas();
      }
    },

    abrirRegua(regua) {
      this.setReguaSelecionada(regua);
      this.$router.push({
        name: "eventos",
        params: { regua_id: regua.id },
      });
    },

    atualizaQueryParams() {
      this.$router.replace({ query: { ...this.routeParams } }).catch(() => {});
    },

    pegaQueryParams(queryParams) {
      Object.assign(this.routeParams, {
        tipo: this.$route.query.tipo,
        tipo_movimentacao_id: this.$route.query.tipo_movimentacao_id,
        arquivadas: queryParams.arquivadas == "true" ? true : false,
        ...(queryParams?.tela && { tela: queryParams?.tela }),
        ...(queryParams?.id && { id: queryParams?.id }),
      });
    },

    ordenarReguas() {
      this.verificaInfosRegua(this.$route.query.tipo);
      if (!this.reguasFiltradas) {
        return;
      }

      const filtros = { ...this.routeParams };
      let reguas = (this.reguas || []).objects || [];
      reguas = reguas.filter((regua) => {
        if (
          filtros.arquivadas == true ||
          (regua.status != "ARQUIVADA" && filtros.arquivadas == false)
        ) {
          return true;
        }
      });
      this.reguasFiltradas = this.ordenaReguaPorStatus(reguas);
    },

    async filtraReguas() {
      this.createEventsInformation();
      this.ordenarReguas();
    },

    async abrirDialogNovo(tipoRegua) {
      if (this.telaAtual == "reguas") {
        if (tipoRegua == "MOVIMENTACAO") {
          await tipoMovimentacaoService
            .getById(this.$route.query.tipo_movimentacao_id)
            .then((retorno) => {
              this.tituloMovimentacao = retorno.data.nome;
            });
        }
        return this.$refs.editOuNovaReguaDialog.abrirNovaRegua(tipoRegua);
      } else {
        return this.$refs.telaDeVariaveis.abrirDialog({
          operacao: "adicionar",
          tipo_regua: tipoRegua,
          empresa_id: this.empresaSelecionada.id,
        });
      }
    },


    addOrUpdateRegua(novaRegua) {
      const index = this.reguas.objects.findIndex(
        (regua) => regua.id == novaRegua.id
      );
      if ((index || index === 0) && index != -1) {
        this.$set(this.reguas.objects, index, novaRegua);
      } else {
        this.reguas.objects.push(novaRegua);
      }
      this.getTodasReguas(this.$route.query.tipo);
    },
    createEventsInformation() {
      if (!this.reguas) {
        return;
      }

      this.reguas.objects.forEach((regua, index) => {
        let eventos = (regua.eventos || []).filter(
          (evento) => evento.status == "PRODUCAO"
        );
        const eventsLength = eventos.filter((x) => x.master_id == null).length;

        const eventDays = this.contarDiasDeEventos(regua);
        this.reguas.objects[index].eventos_info = Object.assign(
          {},
          this.reguas.objects[index].eventos_info,
          {
            quantidade_eventos: eventsLength,
            janela_eventos: eventDays,
          }
        );
      });
    },

    countEventType(regua, eventType) {
      let eventos = (regua.eventos || []).filter(
        (evento) => evento.status == "PRODUCAO"
      );
      return eventos.filter((item) => item.tipo == eventType)?.length || 0;
    },

    contarDiasDeEventos(regua) {
      let eventos = (regua.eventos || []).filter(
        (evento) => evento.status == "PRODUCAO"
      );
      let menorDia = 0;
      let maiorDia = 0;
      eventos.forEach((event) => {
        event.dia < menorDia ? (menorDia = event.dia) : null;
        event.dia > maiorDia ? (maiorDia = event.dia) : null;
      });
      let isNotifyingOnDay0 = eventos.some((event) => event.dia == 0);
      let eventDaysWindow = maiorDia + Math.abs(menorDia);
      if (isNotifyingOnDay0) {
        eventDaysWindow += 1;
      }
      return eventDaysWindow;
    },

    async tabelaResumoContratos(cache) {
      await this.carregarResumoContratos({
        cache: cache ? false : true,
      })
        .then((resp) => {
          if (!resp) return;
          this.resumoContratos = resp;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    ordenaReguaPorStatus(payload) {
      let reguas = payload;
      for (var i = 0; i < reguas.length; i++) {
        if (reguas[i].status == "PRODUCAO") {
          reguas[i]["ordem"] = 1;
        } else if (reguas[i].status == "RASCUNHO") {
          reguas[i]["ordem"] = 2;
        } else if (reguas[i].status == "ARQUIVADA") {
          reguas[i]["ordem"] = 3;
        }
      }
      reguas.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));
      reguas.sort((a, b) => {
        if (a.ordem == b.ordem) {
          return a.atualizado_em < b.atualizado_em ? 1 : -1;
        }
        return 0;
      });
      return reguas;
    },

    async verificaInfosRegua(tipo) {
      if (this.$route.query.tipo == "MOVIMENTACAO") {
        await tipoMovimentacaoService
          .getById(this.$route.query.tipo_movimentacao_id)
          .then((retorno) => {
            this.tituloRegua = "Réguas de " + retorno.data.nome;
          });
        let reguasArquivadas = this.reguas?.objects.filter(
          (regua) => regua.status == "ARQUIVADA"
        );
        this.quantidadeReguasArquivadas = reguasArquivadas?.length;
      } else {
        let reguasArquivadas = this.reguas?.objects.filter(
          (regua) => regua.status == "ARQUIVADA"
        );
        this.quantidadeReguasArquivadas = reguasArquivadas?.length;

        const tipos = [
          { tipo: "IMPLANTACAO", titulo: "Réguas de Implantação" },
          { tipo: "VENCIMENTO_BOLETO", titulo: "Réguas de Vencimento Boleto" },
          { tipo: "VENCIMENTO_DEBITO", titulo: "Réguas de Vencimento Débito" },
          {
            tipo: "TOLERANCIA_BOLETO",
            titulo: "Réguas de Tolerância do Boleto",
          },
          {
            tipo: "TOLERANCIA_DEBITO",
            titulo: "Réguas de Tolerância do Débito",
          },
          {
            tipo: "ANIVERSARIO_DO_CLIENTE",
            titulo: "Réguas de Aniversário do Cliente",
          },
          { tipo: "BOLETO_SOLICITADO", titulo: "Réguas de Boleto Solicitado" },
          { tipo: "INICIO_CONTRATO", titulo: "Réguas de Início do Contrato" },
          {
            tipo: "CONTRATO_FINALIZADO",
            titulo: "Réguas de Contrato Finalizado",
          },
          {
            tipo: "CONTRATO_CANCELADO",
            titulo: "Réguas de Contrato Cancelado",
          },
          {
            tipo: "PAGAMENTO_BOLETO_RECEBIDO",
            titulo: "Réguas de Pagamento de Boleto Recebido",
          },
          {
            tipo: "PAGAMENTO_DEBITO_RECEBIDO",
            titulo: "Réguas de Pagamento de Débito Recebido",
          },
          {
            tipo: "PARCELA_BOLETO_CRIADA",
            titulo: "Réguas de Parcela de Boleto Criada",
          },
          {
            tipo: "PARCELA_DEBITO_CRIADA",
            titulo: "Réguas de Parcela de Débito Criada",
          },
          { tipo: "TERMINO_CONTRATO", titulo: "Réguas de Término do Contrato" },
        ];
        let tipoSelecionado = tipos.find((tipos) => tipos.tipo == tipo);
        this.tituloRegua = tipoSelecionado?.titulo;
        this.quantidadeReguas = this.reguas?.objects.filter(
          (regua) =>
            regua.tipo == tipoSelecionado?.tipo && regua.status != "ARQUIVADA"
        )?.length;
      }
    },

    async trocarTela() {
      const query = { ...this.$route.query };
      if (query?.id && query?.tela == "variaveis") {
        await this.$router.replace({ path: `/reguas/${query.id}` });
        return;
      }

      if (!query?.tela || query.tela == "reguas") {
        query["tela"] = "variaveis";
      } else {
        query["tela"] = "reguas";
      }
      await this.$router.replace({ query });
      return;
    },
  },
  computed: {
    ...mapGetters("reguas", {
      reguas: "reguas",
      getResumoContratos: "getResumoContratos",
      carregandoResumoContratos: "carregandoResumoContratos",
      erroCarregarResumoContratos: "erroCarregarResumoContratos",
      getReguaById: "getReguaById",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),

    temReguas() {
      if (!this.reguasFiltradas) {
        return false;
      }
      return this.reguasFiltradas?.length > 0;
    },
  },
  watch: {
    routeParams: {
      handler() {
        this.filtraReguas();
        this.atualizaQueryParams();
      },
      deep: true,
    },
    empresaSelecionada: {
      handler() {
        this.reguasFiltradas = [];
        this.resumoContratos = null;
        this.sem_perfilContrato = {};

        if (this.$route.query.tipo == "MOVIMENTACAO") {
          this.$router.push({ name: "ListagemReguasMovimentacao" });
        } else {
          this.$router.push({ name: "ListagemReguasPreDefinidas" });
        }
      },
    },
    "$route.query.tela": {
      handler() {
        this.telaAtual = this.$route.query.tela || "reguas";
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-dial {
  position: fixed;
  bottom: 40px;
  right: 60px;
}

a {
  color: hsl(229, 100%, 66%);
}

.ARQUIVADA {
  text-decoration: line-through !important;
  color: #bdbdbd !important;
}

.RASCUNHO {
  .v-application .indigo.accent-3 {
    background-color: #9f9f9f !important;
    border-color: #9f9f9f !important;
  }

  color: #9f9f9f !important;
}

.events__sub {
  font-size: 10px !important;
  letter-spacing: 0.1px !important;
}

.tab-reguas {
  width: 550px !important;
}

.table-wraper {
  max-width: 1000px;
}
</style>
