<template>
  <v-row>
    <div v-for="evento in eventos" :key="evento.id">
      <v-card style="min-width: 100px; height: 70px" class="mx-1 rounded-lg mb-2">
        <v-card-title
          :style="{
            'background-color': retornaCorCard(evento.dia),
          }"
          class="px-2 pt-1 pb-0 justify-center subtitle-2 white--text"
        >
          {{ retornaDiaEvento(evento, regua) }}
        </v-card-title>

        <v-card-text class="text-center pt-2">
          <v-tooltip bottom v-if="contadorEventos(regua, 'EMAIL', evento.dia)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-email </v-icon>
            </template>

            <span>Notificação E-MAIL</span><br />
          </v-tooltip>

          <v-tooltip bottom v-if="contadorEventos(regua, 'SMS', evento.dia)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-message </v-icon>
            </template>

            <span>Notificação SMS</span><br />
          </v-tooltip>

          <v-tooltip bottom v-if="contadorEventos(regua, 'WHATSAPP', evento.dia)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-whatsapp </v-icon>
            </template>

            <span>Notificação WhatsApp</span><br />
          </v-tooltip>

          <v-tooltip bottom v-if="contadorEventos(regua, 'PUSH', evento.dia)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-bell </v-icon>
            </template>

            <span>Notificação PUSH</span><br />
          </v-tooltip>

          <v-tooltip bottom v-if="contadorEventos(regua, 'LIGACAO_CELULAR', evento.dia)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-cellphone </v-icon>
            </template>

            <span>Notificação LIGAÇÃO CELULAR</span><br />
          </v-tooltip>

          <v-tooltip bottom v-if="contadorEventos(regua, 'LIGACAO_FIXO', evento.dia)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-phone </v-icon>
            </template>

            <span>Notificação LIGAÇÃO FIXO</span><br />
          </v-tooltip>

          <v-tooltip bottom v-if="contadorEventos(regua, 'COMANDO', evento.dia)">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-1" v-bind="attrs" v-on="on"> mdi-xml </v-icon>
            </template>

            <span>Notificação COMANDO</span><br />
          </v-tooltip>
        </v-card-text>
      </v-card>
    </div>
  </v-row>
</template>
<script>
import Vue from "vue";

export default {
  props: {
    eventos: {
      required: true,
      default: () => [],
    },
    regua: {
      required: true,
      default: () => {}
    }
  },
  methods: {
    contadorEventos(regua, tipoEvento, diaEvento) {
      if (!regua.eventos) return 0;

      const eventos = regua.eventos.filter(
        (evento) => evento.status === "PRODUCAO" && evento.master_id == null
      );

      return (
        eventos.filter((item) => item.tipo === tipoEvento && item.dia === diaEvento).length || 0
      );
    },

    retornaCorCard(diaEvento) {
      if (diaEvento < 0) {
        return "#448AFF";
      } else if (diaEvento == 0) {
        return "#FFAB40";
      } else {
        return "#EF5350";
      }
    },

    retornaDiaEvento(evento, regua) {
      if (evento.dia == 0) {
        return Vue.filter("tipoReguaEvento")(regua.tipo);
      } else if (evento.dia > 0) {
        return "+" + evento.dia;
      } else {
        return evento.dia;
      }
    },
  },
};
</script>
