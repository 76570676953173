<template>
  <div>
    <div
      v-if="iniciativa.tipo_header === 'IMAGE' && iniciativa.header"
      :key="iniciativa.header"
    >
      <img :src="iniciativa.header" class="header-image" />
    </div>

    <div
      v-if="iniciativa.tipo_header === 'IMAGE_VAR' && iniciativa.header"
      :key="iniciativa.header"
    >
      <div class="mb-1 text-right">
        <i>Imagem ilustrativa</i>
      </div>
      <img
        src="../../../../assets/img/imagem_preview.png"
        class="header-image"
      />
    </div>

    <div
      v-if="['DOCUMENT', 'DOCUMENT_VAR'].includes(iniciativa.tipo_header)"
      class="header-document pa-2 d-flex align-center"
      @click="abrirDocumento(iniciativa?.header_url)"
      :style="iniciativa.header_url ? 'cursor: pointer;' : ''"
    >
      <img
        class="document-icon"
        src="../../../../assets/img/pdf_icon.png"
        alt="pdf-icon"
      />
      <div class="document-text ml-3">
        <span class="document-name">{{
          iniciativa?.nome_arquivo || "Nome Documento"
        }}</span>

        <h5>50 kB <span>⋅</span> PDF</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iniciativa: null,
  },
  methods: {
    abrirDocumento(url) {
      if (!url) return;
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.header-image {
  margin-bottom: 10px;
  border-radius: 5px;
  background-position: center;
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 800px;
  background: white;
  object-fit: contain;
}
.header-document {
  background-size: 200%;
  background-position: center;
  background-color: lightgrey;
  border-radius: 5px;
  .document-icon {
    max-height: 45px;
  }
  .document-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      font-size: 20px;
    }
    h5 {
      font-size: 14px;
      font-weight: 300;
      span {
        font-weight: 800;
      }
    }
  }
}
</style>
