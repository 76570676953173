<template>
  <v-dialog v-model="dialog" width="1000" persistent scrollable>
    <v-card class="elevation-0 rounded-0 d-flex flex-column" v-if="dialog">
      <v-card-title class="text-h5">
        <span class="mr-2">
          <span class="text-capitalize"> {{ operacao }} </span>Iniciativa</span
        >
        <v-spacer></v-spacer>
        <v-btn
          text
          width="113"
          color="primary"
          @click="mostrarVariaveis = !mostrarVariaveis"
        >
          Variáveis
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-1">
        <v-form
          v-model="isFormValid"
          @submit.prevent="salvar()"
          class="formulario"
          ref="form"
        >
          <v-row>
            <v-col class="campos campos-form">
              <div class="obrigatorios">
                <v-text-field
                  class="mb-2"
                  v-model="iniciativa.nome"
                  label="Nome"
                  outlined
                  autofocus
                  required
                  :rules="[regras.exigido, regras.limitado256]"
                  ref="nome"
                  @focus="mudaCampoSelecionado('nome')"
                  maxlength="256"
                  :hint="
                    iniciativa.nome && iniciativa.nome.length >= 256
                      ? 'O nome é limitado à 256 caracteres'
                      : ''
                  "
                />

                <v-textarea
                  class="mb-2"
                  v-model="iniciativa.descricao"
                  rows="2"
                  row-height="10"
                  label="Descrição"
                  outlined
                  maxlength="256"
                  counter
                  ref="descricao"
                  :rules="[regras.limitado256]"
                  :hint="
                    iniciativa.descricao && iniciativa.descricao.length >= 256
                      ? 'A descrição é limitada à 256 caracteres'
                      : ''
                  "
                  @focus="mudaCampoSelecionado('descricao')"
                />

                <v-text-field
                  class="mb-2"
                  v-model="iniciativa.iniciativa"
                  :disabled="campoDesabilitado"
                  label="Chave da Iniciativa"
                  outlined
                  required
                  :rules="[
                    regras.exigido,
                    regras.limitado256,
                    regras.alfanumerico,
                    regraEspecifica.unico,
                  ]"
                  maxlength="256"
                  :hint="
                    iniciativa.iniciativa && iniciativa.iniciativa.length >= 256
                      ? 'A iniciativa é limitada à 256 caracteres'
                      : ''
                  "
                />

                <v-select
                  class="mb-2"
                  v-if="iniciativa.status_broker"
                  v-model="iniciativa.status_broker"
                  :items="statusPossiveis"
                  label="Status Broker"
                  outlined
                  required
                  :rules="[regras.exigido]"
                />

                <v-select
                  class="mb-2"
                  v-model="iniciativa.classificacao"
                  :items="classificacoesPossiveis"
                  label="Classificação"
                  outlined
                  required
                  :rules="[regras.exigido]"
                >
                  <template v-slot:selection="{ item }">
                    <span>{{ item | classificacoesIniciativa }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{ item | classificacoesIniciativa }}</span>
                  </template>
                </v-select>

                <v-select
                  class="mb-2"
                  v-model="numeroSelecionado"
                  :items="listaRemetentes"
                  label="Número remetente"
                  outlined
                  required
                  :rules="[regras.exigido]"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Nenhum remetente cadastrado!</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span>{{ item.numero }} ({{ item.apelido }})</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{ item.numero }} ({{ item.apelido }})</span>
                  </template>
                </v-select>

                <v-textarea
                  class="mb-2"
                  v-model="iniciativa.mensagem"
                  :disabled="campoDesabilitado"
                  label="Mensagem"
                  rows="2"
                  outlined
                  required
                  :rules="[regras.exigido, regras.limitado1024]"
                  ref="mensagem"
                  @focus="mudaCampoSelecionado('mensagem')"
                  maxlength="1024"
                  :hint="
                    iniciativa.mensagem && iniciativa.mensagem.length >= 1024
                      ? 'A mensagem é limitada à 1024 caracteres'
                      : ''
                  "
                ></v-textarea>
              </div>

              <div class="opcionais">
                <div class="cabecalho" v-if="!campoDesabilitado">
                  <v-btn
                    color="primary"
                    class="mb-2"
                    text
                    small
                    @click="alterarCabecalho(!incluirCabecalho)"
                  >
                    {{ incluirCabecalho ? "Remover" : "Adicionar" }} Cabeçalho
                  </v-btn>

                  <div v-if="incluirCabecalho" class="campos">
                    <v-select
                      @change="handleAlterarTipoCabecalho"
                      v-model="iniciativa.tipo_header"
                      :items="cabecalhosPossiveis"
                      label="Tipo do cabeçalho"
                      outlined
                      required
                    >
                      <template v-slot:selection="{ item }">
                        <span>{{ item | cabecalhoIniciativa }}</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span>{{ item | cabecalhoIniciativa }}</span>
                      </template>
                    </v-select>

                    <div v-if="mostraCampoTipoFile" class="mb-4">
                      <v-file-input
                        v-if="iniciativa.tipo_header === 'DOCUMENT'"
                        :value="iniciativa.header"
                        accept=".pdf"
                        required
                        @change="handleSelecionarArquivo"
                        @click:append-outer="this.click()"
                        label="Solte aqui o arquivo ou selecione-o"
                        :rules="[regras.exigido]"
                        prepend-icon="mdi-paperclip"
                      ></v-file-input>
                      <v-file-input
                        v-if="iniciativa.tipo_header === 'IMAGE'"
                        accept="image/png, image/jpeg, image/jpeg"
                        :value="iniciativa.header"
                        required
                        @change="handleSelecionarArquivo"
                        @click:append-outer="this.click()"
                        label="Solte aqui a imagem ou selecione-a"
                        :rules="[regras.exigido]"
                        prepend-icon="mdi-paperclip"
                      ></v-file-input>
                    </div>

                    <div class="mb-4" v-if="mostraCampoTipoVariavel">
                      <v-text-field
                        v-if="iniciativa.tipo_header === 'DOCUMENT_VAR'"
                        v-model="iniciativa.header"
                        label="Variável do documento"
                        outlined
                        required
                        ref="var_documento"
                        readonly
                        clearable
                        :rules="[regras.exigido, regras.umaVariavel]"
                        @click:clear="mostrarVariaveis = true"
                        @focus="
                          mudaCampoSelecionado('var_documento');
                          mostrarVariaveis = true;
                        "
                        @blur="handleBlurCampoVariavel"
                      ></v-text-field>
                      <v-text-field
                        v-if="iniciativa.tipo_header === 'IMAGE_VAR'"
                        v-model="iniciativa.header"
                        label="Variável da imagem"
                        outlined
                        required
                        ref="var_imagem"
                        readonly
                        clearable
                        :rules="[regras.exigido, regras.umaVariavel]"
                        @click:clear="mostrarVariaveis = true"
                        @focus="
                          mudaCampoSelecionado('var_imagem');
                          mostrarVariaveis = true;
                        "
                        @blur="handleBlurCampoVariavel"
                      >
                      </v-text-field>
                    </div>

                    <v-text-field
                      v-if="mostraCampoTipoURL"
                      v-model="iniciativa.header"
                      label="URL do arquivo"
                      outlined
                      required
                      :rules="[regras.exigido, regras.url]"
                    ></v-text-field>

                    <v-text-field
                      v-if="
                        iniciativa.tipo_header === 'DOCUMENT' ||
                        iniciativa.tipo_header === 'DOCUMENT_VAR'
                      "
                      v-model="iniciativa.nome_arquivo"
                      label="Nome do arquivo"
                      outlined
                      required
                      :rules="[regras.exigido, regras.semEspeciais]"
                      ref="nome_arquivo"
                      @focus="mudaCampoSelecionado('nome_arquivo')"
                    ></v-text-field>
                  </div>
                </div>

                <div class="footer" v-if="!campoDesabilitado">
                  <v-btn
                    color="primary"
                    text
                    small
                    class="mb-2"
                    @click="alterarFooter()"
                  >
                    {{ incluirFooter ? "Remover" : "Adicionar" }} Rodapé
                  </v-btn>

                  <div v-if="incluirFooter" class="campos">
                    <v-text-field
                      counter
                      maxlength="60"
                      v-model="iniciativa.footer"
                      label="Rodapé"
                      outlined
                      required
                      :rules="[
                        regras.exigido,
                        regras.limitado60,
                        regras.nenhumaVariavel,
                      ]"
                      :hint="
                        iniciativa.footer && iniciativa.footer.length >= 60
                          ? 'O rodapé é limitado à 60 caracteres'
                          : ''
                      "
                      ref="footer"
                      @focus="mudaCampoSelecionado('footer')"
                    ></v-text-field>
                  </div>
                </div>

                <div class="botoes" v-if="!campoDesabilitado">
                  <v-menu top offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        text
                        small
                        class="mb-4"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Adicionar Botão
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in botoesPossiveis"
                        :key="index"
                        link
                      >
                        <v-list-item-title>
                          <v-btn
                            text
                            samll
                            :ripple="false"
                            color="primary"
                            class="botao-iniciativa"
                            :disabled="verificarLimiteBotoes(item)"
                            @click="adicionarBotao(item)"
                          >
                            {{ item | botoesIniciativa }}
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <div v-if="iniciativa.botoes">
                    <div
                      class="campos d-flex flex-row"
                      v-for="(item, index) in iniciativa.botoes"
                      :key="`botão-${index}`"
                    >
                      <v-text-field
                        class="mb-2"
                        v-model="item.texto"
                        :label="montarLegendaCampo(item.type, true)"
                        outlined
                        required
                        :rules="[regras.exigido, regras.limitado25]"
                        :ref="`botao-texto-${index}`"
                        @focus="mudaCampoSelecionado(`botao-texto-${index}`)"
                        maxLength="25"
                        :hint="
                          item.texto && item.texto.length >= 25
                            ? 'O botão é limitado à 25 caracteres'
                            : ''
                        "
                      >
                      </v-text-field>
                      <v-text-field
                        v-if="item.type !== 'QUICK_REPLY'"
                        class="ml-2 mb-2"
                        v-model="item.variavel"
                        :label="montarLegendaCampo(item.type)"
                        outlined
                        required
                        :rules="[
                          regras.exigido,
                          item.type == 'PHONE_NUMBER'
                            ? regras.limitado25
                            : regras.urlEVariavel,
                          item.type == 'PHONE_NUMBER'
                            ? regras.nenhumaVariavel
                            : regras.umaVariavel,
                        ]"
                        :ref="`botao-variavel-${index}`"
                        @focus="mudaCampoSelecionado(`botao-variavel-${index}`)"
                        :hint="
                          item.variavel && item.variavel.length >= 25
                            ? 'O botão é limitado à 25 caracteres'
                            : ''
                        "
                      >
                      </v-text-field>
                      <v-btn class="mt-3" icon @click="removerBotao(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              v-if="!mostrarVariaveis"
              class="previsualizacao d-flex flex-column"
            >
              <div
                v-if="!iniciativa?.mensagem"
                class="text-h6 d-flex justify-center align-center grey lighten-3 rounded text--secondary flex-1"
                style="height: 100%"
              >
                Pré-visualização do asset
              </div>
              <div v-else class="container-previa">
                <Preview :iniciativa="iniciativa" />
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-spacer></v-spacer>
      <v-divider></v-divider>

      <v-card-actions class="mb-3 mr-3 flex-column align-end">
        <div>
          <v-btn color="primary" text class="text-uppercase" @click="fechar()">
            Cancelar
          </v-btn>
          <v-btn
            :disabled="!isFormValid"
            color="primary"
            class="text-uppercase"
            @click="salvar()"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <div v-show="mostrarVariaveis" class="listaVariaveis">
      <ListaVariaveis
        :abrirVariaveis="mostrarVariaveis"
        @concatenaVariavel="concatenaVariavel"
        @fecharVariaveis="mostrarVariaveis = false"
      />
    </div>
    <DialogConfirmar ref="dialogConfirmar" @confirmarAdicao="confirmarAdicao" />
  </v-dialog>
</template>

<script>
import { regrasDeIniciativas, validateUrl } from "@/assets/js/rules";
import ListaVariaveis from "@/views/reguas/components/ListaVariaveis.vue";
import DialogConfirmar from "./DialogConfirmar.vue";
import { mapGetters, mapActions } from "vuex";
import Preview from "@/views/reguas/components/previewIniciativaWhatsapp/PreviewIniciativaWhatsapp.vue";

export default {
  components: {
    ListaVariaveis,
    DialogConfirmar,
    Preview,
  },
  props: {
    listaIniciativas: Array,
    listaRemetentes: Array,
  },
  data() {
    return {
      isFormValid: false,
      dialog: false,
      operacao: "",
      mostrarVariaveis: false,
      numeroSelecionado: "",
      campoSelecionado: null,
      incluirCabecalho: false,
      incluirFooter: false,
      regras: regrasDeIniciativas,
      regraEspecifica: {
        unico: (valor) =>
          !this.verificarExistencia(valor) ||
          "A chave já existe para a empresa",
      },
      iniciativa: {
        botoes: null,
        classificacao: "PENDENTE",
        descricao: "",
        footer: null,
        header: null,
        nome_arquivo: "",
        iniciativa: "",
        mensagem: "",
        nome: "",
        numero_remetente: "",
        status_broker: null,
        tipo_header: null,
      },
      statusPossiveis: ["PENDENTE", "APROVADA", "REPROVADA"],
      classificacoesPossiveis: [
        "PENDENTE",
        "MARKETING",
        "AUTHENTICATION",
        "UTILITY",
      ],
      cabecalhosPossiveis: ["DOCUMENT", "IMAGE", "DOCUMENT_VAR", "IMAGE_VAR"],
      botoesPossiveis: ["QUICK_REPLY", "URL"],
    };
  },
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
    campoDesabilitado() {
      return this.operacao !== "editar" && this.operacao !== "cadastrar";
    },
    tipoHeaderValido() {
      return ["IMAGE", "DOCUMENT", "DOCUMENT_VAR", "IMAGE_VAR"].includes(
        this.iniciativa.tipo_header
      );
    },
    mostraCampoTipoFile() {
      return (
        !this.iniciativa.header &&
        ["DOCUMENT", "IMAGE"].includes(this.iniciativa.tipo_header)
      );
    },
    mostraCampoTipoVariavel() {
      return ["DOCUMENT_VAR", "IMAGE_VAR"].includes(
        this.iniciativa.tipo_header
      );
    },
    mostraCampoTipoURL() {
      return (
        this.iniciativa.header &&
        ["DOCUMENT", "IMAGE"].includes(this.iniciativa.tipo_header)
      );
    },
  },
  watch: {
    "iniciativa.iniciativa"() {
      if (this.iniciativa?.iniciativa) {
        this.iniciativa.iniciativa = this.iniciativa.iniciativa.replace(
          " ",
          "_"
        );
      }
    },
    dialog: function (novoEstado) {
      if (novoEstado == true) {
        let payload = {
          empresa_id: this.empresaSelecionada.id,
          tipo_regua: "IMPLANTACAO",
          ativa: true,
          limite: 1000,
          ordem: "nome",
        };
        this.$store.dispatch("variavelRegua/pegar", payload);
      }
    },
  },
  methods: {
    ...mapActions("iniciativas", { upload: "uploadArquivo" }),

    abrir({ operacao, iniciativa = null }) {
      this.operacao = operacao;

      if (iniciativa) {
        if (iniciativa?.numero_remetente) {
          this.numeroSelecionado = this.listaRemetentes.find(
            (item) => item.numero == iniciativa?.numero_remetente
          );
        }
        if (iniciativa?.tipo_header || iniciativa?.header) {
          this.incluirCabecalho = true;
          if (!validateUrl(iniciativa.header)) {
            if (iniciativa.tipo_header === "IMAGE") {
              iniciativa.tipo_header = "IMAGE_VAR";
            }
            if (iniciativa.tipo_header === "DOCUMENT") {
              iniciativa.tipo_header = "DOCUMENT_VAR";
            }
          }
        }
        if (iniciativa?.footer) this.incluirFooter = true;

        Object.assign(this.iniciativa, iniciativa);

        this.$nextTick(() => {
          const form = document.querySelector(".campos");
          if (form) {
            form.scrollTop = 0;
          }

          const previa = document.querySelector(".container-previa");
          if (previa) {
            previa.scrollTop = 0;
          }
        });
      }
      this.dialog = true;
      return;
    },

    handleBlurCampoVariavel() {
      setTimeout(() => {
        this.mostrarVariaveis = false;
      }, 300);
    },

    async handleSelecionarArquivo(e) {
      if (e) {
        try {
          const url = await this.upload(e);
          this.iniciativa.header = url;
          this.iniciativa.nome_arquivo = e.name;
        } catch (e) {
          this.iniciativa.header = null;
          this.iniciativa.nome_arquivo = null;
          this.isFormValid = false;
        }
      }
    },

    fechar() {
      this.dialog = false;
      this.operacao = "";
      this.incluirCabecalho = false;
      this.incluirFooter = false;
      this.campoSelecionado = null;
      this.iniciativa = {
        botoes: null,
        classificacao: "PENDENTE",
        descricao: "",
        footer: null,
        header: null,
        iniciativa: "",
        mensagem: "",
        nome: "",
        numero_remetente: "",
        status_broker: null,
        tipo_header: null,
        nome_arquivo: null,
      };
      this.$refs.form.reset();

      return;
    },

    alterarAtributos(botoes) {
      return (
        botoes?.map((botao) => {
          if (botao.type == "PHONE_NUMBER") {
            let { variavel: numero, ...resto } = botao;
            return { numero, ...resto };
          }
          return botao;
        }) ?? []
      );
    },

    handleAlterarTipoCabecalho() {
      this.iniciativa = {
        ...this.iniciativa,
        nome_arquivo: null,
        header: null,
      };
    },

    async salvar() {
      if (await this.$refs.form.validate()) {
        this.iniciativa.numero_remetente = this.numeroSelecionado.numero;
        if (this.iniciativa.tipo_header === "DOCUMENT_VAR")
          this.iniciativa.tipo_header = "DOCUMENT";
        if (this.iniciativa.tipo_header === "IMAGE_VAR")
          this.iniciativa.tipo_header = "IMAGE";

        if (this.operacao == "editar") {
          this.$emit("editar", { ...this.iniciativa });
          this.fechar();
        } else {
          this.iniciativa.botoes = this.alterarAtributos(
            this.iniciativa.botoes
          );

          this.$refs.dialogConfirmar.abrir();
        }
      }
      return;
    },

    async confirmarAdicao() {
      await this.$emit("cadastrar", { ...this.iniciativa });
      this.fechar();
      return;
    },

    alterarCabecalho(estado) {
      this.incluirCabecalho = estado;

      if (estado) {
        this.iniciativa.tipo_header = "";
        this.iniciativa.header = "";
      } else {
        this.iniciativa.tipo_header = null;
        this.iniciativa.header = null;
      }

      return;
    },

    alterarFooter() {
      if (!this.incluirFooter) {
        this.incluirFooter = true;
        this.iniciativa.footer = "";
        return;
      }
      this.incluirFooter = false;
      this.iniciativa.footer = null;
      return;
    },

    adicionarBotao(tipoBotao) {
      if (!this.iniciativa.botoes) {
        this.iniciativa.botoes = [];
      }
      if (this.iniciativa.botoes.length < 13) {
        this.iniciativa.botoes.push({
          type: tipoBotao,
          variavel: "",
          texto: "",
        });
      }

      this.$nextTick(() => {
        const camposForm = document.querySelector(".campos-form");
        camposForm.scrollTop = camposForm.scrollHeight;
      });

      return;
    },

    removerBotao(index) {
      this.iniciativa.botoes.splice(index, 1);
      if (this.iniciativa.botoes.length == 0) {
        this.iniciativa.botoes = null;
      }
      return;
    },

    verificarLimiteBotoes(tipoBotao) {
      if (!this.iniciativa.botoes || !Array.isArray(this.iniciativa.botoes))
        return false;
      if (this.iniciativa.botoes.length >= 10) return true;

      let count = this.iniciativa.botoes.filter(
        (item) => item.type == tipoBotao
      ).length;
      if (tipoBotao == "URL" && count > 1) return true;
      else if (tipoBotao == "PHONE_NUMBER" && count > 0) return true;
      else if (tipoBotao == "QUICK_REPLY" && count == 10) return true;
      return false;
    },

    verificarExistencia(valor) {
      return (
        this.listaIniciativas.findIndex((item) => item.iniciativa === valor) !==
          -1 && this.operacao == "cadastrar"
      );
    },

    montarLegendaCampo(tipo, texto) {
      if (tipo == "QUICK_REPLY" && texto) {
        return "Resposta rápida";
      }
      if (tipo == "URL" && texto) {
        return "Texto da URL";
      }
      let nomeBotao = this.$options.filters.botoesIniciativa(tipo);
      if (texto) {
        return `Texto do ${nomeBotao}`;
      }
      return `${nomeBotao} do botão`;
    },

    mudaCampoSelecionado(campo) {
      this.campoSelecionado = campo;
      return;
    },

    concatenaVariavel(variavel) {
      if (!variavel) return;

      let stringParaArray = [];
      let posicaoCursor;

      if (this.campoSelecionado && this.campoSelecionado?.includes("botao")) {
        let [campo, atributo, posicao] = this.campoSelecionado.split("-");
        stringParaArray = (
          this.iniciativa.botoes[posicao][atributo] || ""
        ).split("");
        posicaoCursor =
          this.$refs[this.campoSelecionado][0]?.$refs.input.selectionStart;
        stringParaArray.splice(posicaoCursor, 0, `{{${variavel.nome}}}`);
        this.iniciativa.botoes[posicao][atributo] = stringParaArray.join("");
      } else if (
        this.campoSelecionado &&
        this.campoSelecionado?.includes("var")
      ) {
        stringParaArray = (this.iniciativa.header || "").split("");
        posicaoCursor =
          this.$refs[this.campoSelecionado]?.$refs.input.selectionStart;

        stringParaArray.splice(posicaoCursor, 0, `{{${variavel.nome}}}`);
        this.iniciativa.header = stringParaArray.join("");
      } else {
        stringParaArray = (this.iniciativa[this.campoSelecionado] || "").split(
          ""
        );
        posicaoCursor =
          this.$refs[this.campoSelecionado]?.$refs.input.selectionStart;

        stringParaArray.splice(posicaoCursor, 0, `{{${variavel.nome}}}`);
        this.iniciativa[this.campoSelecionado] = stringParaArray.join("");
      }

      this.$refs[this.campoSelecionado]?.$refs?.input.focus();
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.listaVariaveis {
  width: 68.5%;
  & > div {
    height: 100%;
    width: 100% !important;
  }
}

.botao-iniciativa {
  width: 100%;
  justify-content: start;

  &::before {
    background-color: transparent !important;
  }
}

.campos-form {
  max-height: calc(85vh - 138px);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.container-previa {
  max-height: calc(85vh - 160px);
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  .fill-height {
    padding: 0px !important;
  }
}

* {
  ::-webkit-scrollbar {
    width: 4px;
    margin-left: 1px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 25px;
    padding-right: 0px;
  }
}
</style>
