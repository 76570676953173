<template>
  <v-dialog v-model="dialog" :width="600" persistent>
    <!-- FORM TELEFONE -->
    <v-form
      ref="formTelefone"
      @submit.prevent="salvarTelefone"
      v-if="tipoContato == 'TELEFONE'"
      lazy-validation
    >
      <v-card>
        <v-card-title class="text-h5">
          Editar Telefone
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="pt-6">
          <div class="d-flex mb-2">
            <v-text-field
              v-model="telefone.numero"
              label="Telefone"
              v-mlmask="{
                mask: '(##) #####-####',
                unmaskedVar: 'payload.numero',
              }"
              :rules="campoRules.numero"
              required
              outlined
              autofocus
              type="tel"
              class="select"
            ></v-text-field>
            <v-text-field
              label="Origem"
              v-model="telefone.origem"
              required
              :rules="campoRules.origem"
              outlined
              type="text"
              class="select ml-2"
            ></v-text-field>
          </div>
          <div class="d-flex">
            <v-select
              :items="tipoTelefone"
              label="Tipo"
              v-model="telefone.tipo"
              no-data-text="Nenhum tipo cadastrado!"
              outlined
              class="select"
            ></v-select>
            <v-select
              :items="statusTelefone"
              label="Status"
              v-model="telefone.status"
              no-data-text="Nenhum status cadastrado!"
              outlined
              class="ml-2 select"
            ></v-select>
          </div>
          <div class="d-flex">
            <v-checkbox
              hide-details
              v-model="telefone.aceite_whatsapp"
              label="WhatsApp"
              class="mt-0 pt-0"
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog('TELEFONE')">
            cancelar
          </v-btn>
          <v-btn color="primary" type="submit" :loading="salvando">
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- FIM FORM TELEFONE -->

    <!-- FORM EMAIL -->
    <v-form
      ref="formTelefone"
      @submit.prevent="salvarEmail"
      v-if="tipoContato == 'EMAIL'"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ email.id ? "Editar" : "Novo" }} E-mail
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            v-model="email.email"
            label="E-mail"
            :rules="campoRules.email"
            outlined
            autofocus
            type="email"
          ></v-text-field>
          <div class="d-flex mt-2">
            <v-text-field
              v-model="email.origem"
              label="Origem"
              :rules="campoRules.origem"
              outlined
              type="text"
            ></v-text-field>
            <v-select
              :items="statusTelefone"
              label="Status"
              no-data-text="Nenhum status cadastrado!"
              outlined
              class="ml-2 select"
              v-model="email.status"
            ></v-select>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog('EMAIL')">
            cancelar
          </v-btn>
          <v-btn color="primary" type="submit" :loading="salvando">
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <!-- FIM FORM EMAIL -->
  </v-dialog>
</template>

<script>
import { mask as mlmask } from "@titou10/v-mask";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { rules } from "@/assets/js/rules";
import contatoService from "@/services/contato";

export default {
  directives: {
    mlmask,
  },
  data() {
    return {
      idCliente: null,
      novo: false,
      campoRules: {
        numero: [(valor) => (valor || "").length > 0 || "Campo obrigatório"],
        origem: [(valor) => (valor || "").length > 0 || "Campo obrigatório"],
        email: [(valor) => (valor || "").length > 0 || "Campo obrigatório"],
      },
      payload: {
        numero: null,
      },
      rules,
      dialog: false,
      salvando: null,
      cliente: {},
      email: {
        email: "",
        origem: "",
        status: null,
      },
      tipoContato: "",
      telefone: null,
      tipoTelefone: [
        {
          text: "Celular",
          value: "CELULAR",
        },
        {
          text: "Fixo",
          value: "FIXO",
        },
      ],
      statusTelefone: [
        {
          text: "Criado",
          value: "CRIADO",
        },
        {
          text: "Confirmado",
          value: "CONFIRMADO",
        },
        {
          text: "Rejeitado",
          value: "REJEITADO",
        },
        {
          text: "Inválido",
          value: "INVALIDO",
        },
      ],
    };
  },

  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapMutations("contato", {
      updateEmail: "updateEmail",
      updateTelefone: "updateTelefone",
      addEmail: "addEmail",
      addTelefone: "addTelefone",
    }),

    abrir(contato, tipoContato, idCliente) {
      this.idCliente = idCliente;

      if (contato) {
        contato = JSON.parse(JSON.stringify(contato));
        console.debug("Editando o contato", contato.id);
      }

      if (tipoContato == "TELEFONE") {
        this.telefone = contato || {
          status: "CRIADO",
          tipo: "CELULAR",
        };
      } else {
        this.email = contato || { status: "CRIADO" };
      }

      this.novo = contato == null;
      this.tipoContato = tipoContato;
      this.dialog = true;
      if (this.$refs.formTelefone) this.$refs.formTelefone.resetValidation();
    },

    async salvarEmail() {
      if (!this.$refs.formTelefone.validate()) return;
      if (this.novo) {
        try {
          this.salvando = true;

          let payload = {
            cliente_id: this.idCliente,
            email: this.email.email,
            status: this.email.status,
            origem: this.email.origem,
          };

          const response = await contatoService.novoEmail(payload);
          this.showSnackBar({
            text: "Novo contato de email criado com sucesso!",
            attrs: { color: "success", text: true },
          });
          this.dialog = false;
          this.salvando = false;
          this.addEmail(response.data);
        } catch (error) {
          console.error(error);
          this.salvando = false;
          this.showSnackBar({
            text: "Falha ao criar novo contato de email!",
            attrs: { color: "primary", text: true },
          });
        }
      } else {
        try {
          this.salvando = true;

          let payload = {
            email: this.email.email,
            status: this.email.status,
            origem: this.email.origem,
          };

          const response = await contatoService.atualizarEmail(
            this.email.id,
            payload
          );
          this.showSnackBar({
            text: "Cliente alterado",
            attrs: { color: "success", text: true },
          });
          this.dialog = false;
          this.salvando = false;
          this.updateEmail(response.data);
        } catch (error) {
          console.error(error);
          this.salvando = false;
          this.showSnackBar({
            text: "Falha ao salvar o cliente",
            attrs: { color: "primary", text: true },
          });
        }
      }
    },

    async salvarTelefone() {
      if (!this.$refs.formTelefone.validate()) return;
      if (this.novo) {
        try {
          this.salvando = true;

          let payload = {
            cliente_id: this.idCliente,
            numero: this.payload.numero,
            status: this.telefone.status,
            origem: this.telefone.origem,
            tipo: this.telefone.tipo,
          };

          if (this.telefone.aceite_whatsapp) {
            payload.aceite_whatsapp = new Date().toISOString().split(".")[0];
          }

          const response = await contatoService.novoTelefone(payload);
          this.showSnackBar({
            text: "Novo contato de telefone criado com sucesso!",
            attrs: { color: "success", text: true },
          });
          this.dialog = false;
          this.salvando = false;
          this.addTelefone(response.data);
        } catch (error) {
          console.error(error);
          this.salvando = false;
          this.showSnackBar({
            text: "Falha ao criar novo contato de telefone!",
            attrs: { color: "primary", text: true },
          });
        }
      } else {
        try {
          this.salvando = true;

          let payload = {
            numero: this.payload.numero,
            status: this.telefone.status,
            origem: this.telefone.origem,
            tipo: this.telefone.tipo,
          };

          if (typeof this.telefone.aceite_whatsapp === "boolean") {
            if (this.telefone.aceite_whatsapp) {
              payload.aceite_whatsapp = new Date().toISOString().split(".")[0];
            } else {
              payload.aceite_whatsapp = null;
            }
          }

          const response = await contatoService.atualizarTelefone(
            this.telefone.id,
            payload
          );
          this.showSnackBar({
            text: "Cliente alterado",
            attrs: { color: "success", text: true },
          });
          this.dialog = false;
          this.salvando = false;
          this.updateTelefone(response.data);
        } catch (error) {
          console.error(error);
          this.salvando = false;
          this.showSnackBar({
            text: "Falha ao salvar o cliente",
            attrs: { color: "primary", text: true },
          });
        }
      }
    },
    fecharDialog(tipoContato) {
      if (tipoContato == "EMAIL") {
        this.dialog = false;
        this.email.email = null;
        this.email.origem = null;
        this.email.status = null;
      } else {
        this.dialog = false;
        this.telefone.numero = null;
        this.telefone.origem = null;
        this.telefone.status = null;
        this.telefone.tipo = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  max-width: 278px !important;
}
:deep(.v-text-field__details) {
  margin-bottom: 0 !important;
}
</style>
