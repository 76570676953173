import http from "@/plugins/axios";
import enviroment from "@/assets/js/enviroment";
const ghUrl = "https://api.github.com";
const GH_TOKEN = "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";
const repo = "melembre-console";
const owner = "quiteja";
const headers = {
  Authorization: `token ${GH_TOKEN}`,
  Accept: "application/vnd.github.v3+json",
};
export default {
  getPullRequests(params) {
    return http.generic.get(`${ghUrl}/repos/${owner}/${repo}/pulls`, {
      params: {
        state: "open",
      },
      headers: headers,
    });
  },
};
