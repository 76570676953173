<template>
  <v-dialog v-model="dialog" :width="450" persistent>
    <v-form ref="form" @submit.prevent="salvarContato">
      <v-card>
        <v-card-title class="text-h5">
          Criar {{ tipoContato | tipoEvento }}
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-text-field
            label="Número"
            type="tel"
            v-mask="
              tipoContato == 'LIGACAO_FIXO'
                ? '(##) ####-####'
                : '(##) #####-####'
            "
            autofocus
            outlined
            v-if="
              tipoContato == 'SMS' ||
              tipoContato == 'LIGACAO_CELULAR' ||
              tipoContato == 'LIGACAO_FIXO' ||
              tipoContato == 'WHATSAPP'
            "
            required
            v-model="campoNumero"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            label="E-mail"
            type="email"
            autofocus
            outlined
            v-if="tipoContato == 'EMAIL'"
            required
            v-model="campoEmail"
            :rules="[rules.required]"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="fecharDialog()"
            :disabled="salvando"
          >
            cancelar
          </v-btn>
          <v-btn color="primary" type="submit" :loading="salvando">
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import contatoService from "@/services/contato";

export default {
  props: {
    clienteId: null,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      dialog: false,
      tipoContato: null,
      campoNumero: null,
      campoEmail: null,
      salvando: false,
    };
  },
  methods: {
    ...mapActions("contato", {
      criaEmail: "criaEmail",
      criaSms: "criaSms",
      criaWhatsapp: "criaWhatsapp",
      criaLigacao: "criaLigacao",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    abrirDialog(tipoContato) {
      this.tipoContato = tipoContato;
      this.dialog = true;
    },
    fecharDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.campoNumero = null;
      this.campoEmail = null;
    },
    async salvarContato() {
      this.salvando = true;
      if (this.tipoContato == "EMAIL") {
        let payload = {
          cliente_id: this.clienteId,
          email: this.campoEmail,
          status: "ATIVO",
          origem: "CONSOLE",
        };
        try {
          const { data } = await contatoService.novoEmail(payload);
          this.$emit("adicionaContato", data, this.tipoContato);
          this.campoEmail = null;
          this.showSnackBar({
            text: `E-mail criado com sucesso!`,
          });
          this.dialog = false;
          this.salvando = false;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao criar o E-mail`,
          });
          this.salvando = false;
        }
      } else if (this.tipoContato == "SMS") {
        let payload = {
          cliente_id: this.clienteId,
          numero: this.campoNumero.replace(/[() -]/g, ""),
          status: "ATIVO",
          origem: "CONSOLE",
        };
        try {
          const { data } = await contatoService.novoSms(payload);
          this.$emit("adicionaContato", data, this.tipoContato);
          this.campoNumero = null;
          this.showSnackBar({
            text: `SMS criado com sucesso!`,
          });
          this.dialog = false;
          this.salvando = false;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao criar o SMS`,
          });
          this.salvando = false;
        }
      } else if (this.tipoContato == "WHATSAPP") {
        let payload = {
          cliente_id: this.clienteId,
          numero: this.campoNumero.replace(/[() -]/g, ""),
          status: "ATIVO",
          origem: "CONSOLE",
        };
        try {
          const { data } = await contatoService.novoWhatsapp(payload);
          this.$emit("adicionaContato", data, this.tipoContato);
          this.campoNumero = null;
          this.showSnackBar({
            text: `WhatsApp criado com sucesso!`,
          });
          this.dialog = false;
          this.salvando = false;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao criar o WhatsApp`,
          });
          this.salvando = false;
        }
      } else {
        let payload = {
          cliente_id: this.clienteId,
          tipo: this.tipoContato.replace("LIGACAO_", ""),
          numero: this.campoNumero.replace(/[() -]/g, ""),
          status: "ATIVO",
          origem: "CONSOLE",
        };
        try {
          const { data } = await contatoService.novaLigacao(payload);
          this.$emit("adicionaContato", data, this.tipoContato);
          this.campoNumero = null;
          this.showSnackBar({
            text: `Ligação criada com sucesso!`,
          });
          this.dialog = false;
          this.salvando = false;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao criar a Ligação`,
          });
          this.salvando = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  max-width: 278px !important;
}
:deep(.v-text-field__details) {
  margin-bottom: 0 !important;
}
</style>
