<template>
  <div>
    <p>{{ iniciativa.footer }}</p>
    <div class="time-container">
      <span>{{
        new Date().getHours() +
        ":" +
        new Date().getMinutes().toString().padStart(2, "0")
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iniciativa: null,
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 12px;
  color: rgb(158, 158, 158);
  margin: 20px 0 0;
}
.time-container {
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 12px !important;
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    font-size: small;
  }
}
</style>
