<template>
  <v-app-bar app>


    <v-skeleton-loader
      min-width="200"
      type="list-item"
      class="ml-3 qj-skeleton-text"
      v-if="loading"
    />
    <v-skeleton-loader tile type="chip" class="ml-2" v-if="loading" />

    <div class="header">
      <v-btn icon :to="telaAnterior()" exact>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
      <div class="informacoes shrink min-w-0">
        <v-toolbar-title class="text-sm w-auto" v-if="!loading">
          <span class="text-lg ">
            {{ regua.nome }}
          </span>

        </v-toolbar-title>
      </div>
      <div>
        <v-icon
            color="#ef5350"
            class="ml-1"
            v-if="regua.id_regua_desafiante && regua.tipo_teste_ab === 'A'"
          >
            mdi-alpha-a-circle-outline
          </v-icon>
          <v-icon
            color="#42A5F5"
            class="ml-1"
            v-if="regua.id_regua_desafiante && regua.tipo_teste_ab === 'B'"
          >
            mdi-alpha-b-circle-outline
          </v-icon>
      </div>
      <v-slide-group v-if="!loading">
        <v-slide-item>
          <v-tooltip v-if="regua.tipo" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                small
                v-on="on"
                class="font-weight-bold darken-2 tipo-regua mx-2"
                :class="regua.tipo"
              >
                <span class="">{{ regua.tipo | tipoRegua }}</span>
              </v-chip>
            </template>

            <span>Tipo da régua</span>
          </v-tooltip>
        </v-slide-item>
        <v-slide-item>
          <v-chip
            small
            outlined
            link
            @click.stop="abrirDialogVisualizarPerfis(regua)"
            v-if="regua.perfis?.length > 0 && regua.status != 'ARQUIVADA'"
            color="purple"
            class="mr-2 white--text regua__evento__text regua__evento"
          >
            <span class="text-subtitle-2 font-weight-black">
              {{ regua.perfis.length }}
              {{ regua.perfis.length > 1 ? "perfis vinculados" : "perfil vinculado" }}
            </span>
          </v-chip>
        </v-slide-item>
        <v-slide-item>
          <v-tooltip bottom v-if="regua.id_regua_desafiante">
            <template v-slot:activator="{ on, attrs }">
              <v-chip small dark v-bind="attrs" v-on="on" color="orange" class="mr-2"> Teste A/B </v-chip>
            </template>

            <span>Amostra início: {{ regua.digito_verificador_inicio }} </span>
            <br />
            <span>Amostra fim: {{ regua.digito_verificador_fim }} </span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>

      <div class="acoes grow" v-if="!loading">
        <v-btn
          class="mr-3"
          text
          color="primary"
          v-if="regua.id_regua_desafiante && $vuetify.breakpoint.width > 1392"
          :to="{
            name: 'eventos',
            params: { regua_id: regua.id_regua_desafiante },
          }"
        >
          régua desafiante
        </v-btn>
        <v-btn
          v-if="$vuetify.breakpoint.width > 1392"
          text
          elevation="0"
          color="primary"
          class="font-weight mr-4"
          @click="irParaVariaveis()"
        >
          Variáveis
        </v-btn>
        <v-chip
          dark
          label
          class="overline"
          :class="{
            green: regua.status == 'PRODUCAO',
            grey: regua.status == 'RASCUNHO',
          }"
          v-if="regua.status && !loading"
          style="min-width: 155px"
        >
          <v-switch
            flat
            hide-details
            class="mt-0 pt-0 mr-1"
            :input-value="regua.status == 'PRODUCAO'"
            @change.stop.native.capture=""
            @click:prepend.stop.native.capture=""
            @click:append.stop.native.capture=""
            @click.stop.native.capture="changeRulerStatus(regua)"
            v-if="regua.status != 'ARQUIVADA' && checkCredentials(['admin', 'editor_regua'])"
          ></v-switch>
          {{ regua.status | eventoStatus }}
        </v-chip>

        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="w-12">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :to="{
                name: 'eventos',
                params: { regua_id: regua.id_regua_desafiante },
              }"
              v-if="regua.id_regua_desafiante && $vuetify.breakpoint.width <= 1392"
            >
              <v-list-item-title>Régua desafiante</v-list-item-title>
            </v-list-item>
            <v-list-item @click="irParaVariaveis()" v-if="$vuetify.breakpoint.width <= 1392">
              <v-list-item-title>Variáveis</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="showDisabled"></v-switch>
              </v-list-item-action>
              <v-list-item-title>Mostrar eventos arquivados</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <PromiseBasedDialog ref="promiseBasedDialog" :options="promiseBasedDialogOptions" />
    <DialogVisualizarPerfis ref="dialogVisualizarPerfis" />
  </v-app-bar>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import PromiseBasedDialog from "@/components/dialogs/promiseBasedDialog.vue";
import DialogVisualizarPerfis from "./dialogs/DialogVisualizarPerfis.vue";

export default {
  components: {
    PromiseBasedDialog,
    DialogVisualizarPerfis,
  },
  name: "AppBarRuler",
  data: () => ({
    loading: false,
    showDisabled: false,
    ruler: {},
    promiseBasedDialogOptions: {},
  }),
  async created() {
    this.loading = true;
    await this.carregarRegua(this.$route.params.regua_id).finally(() => {
      this.ruler = this.regua;
      this.loading = false;
    });
    let payload = {
      empresa_id: this.empresaSelecionada.id,
      tipo_regua: this.regua.tipo,
      ativa: true,
      limite: 1000,
      ordem: "nome",
    };
    this.$store.dispatch("variavelRegua/pegar", payload);
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapState("regua", ["regua"]),
  },
  watch: {
    $route() {
      this.carregarRegua(this.$route.params.regua_id);
    },
    showDisabled() {
      this.$emit("showDisabled", this.showDisabled);
    },
  },
  methods: {
    checkCredentials,
    ...mapActions("regua", [, "carregarRegua"]),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("reguas", {
      saveRegua: "saveRegua",
    }),
    async changeRulerStatus() {
      this.promiseBasedDialogOptions = {
        cardText:
          "Deseja colocar esta régua em " +
          (this.ruler.status == "RASCUNHO" ? "produção" : "rascunho") +
          "?" +
          (this.ruler.status == "PRODUCAO" ? " Todos os perfis serão removidos!" : ""),
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;
      const payload = {
        id: this.ruler.id,
        status: this.ruler.status == "RASCUNHO" ? "PRODUCAO" : "RASCUNHO",
      };
      try {
        const { data } = await this.saveRegua(payload);
        this.ruler.status = data.status;
        this.ruler.perfis = [];
        this.showSnackBar({
          text:
            "Régua  alterada para " + (this.ruler.status == "RASCUNHO" ? "rascunho" : "produção"),
        });
      } catch (e) {
        this.showSnackBar({
          text: "Erro ao " + (payload.status == "RASCUNHO" ? "para" : "rodar") + " a régua",
        });
        console.error(e);
      }
    },
    abrirDialogVisualizarPerfis(regua) {
      this.$refs.dialogVisualizarPerfis.abrirDialog(regua);
    },
    irParaVariaveis() {
      let idRegua = null;
      let idTipoMovimentacao = null;

      if (this.regua.tipo == "MOVIMENTACAO") {
        idRegua = this.$route.params.regua_id;
        idTipoMovimentacao = this.$route.query.tipo_movimentacao_id;
        this.$router.replace({
          path: `/reguas`,
          query: {
            arquivadas: false,
            tipo: this.regua.tipo,
            tipo_movimentacao_id: idTipoMovimentacao,
            tela: "variaveis",
            id: idRegua,
          },
        });
      } else {
        idRegua = this.$route.params.regua_id;
        this.$router.replace({
          path: "/reguas",
          query: {
            arquivadas: false,
            tipo: this.regua.tipo,
            tela: "variaveis",
            id: idRegua,
          },
        });
      }
    },
    telaAnterior() {
      if (this.$route.query?.listagem) {
        return `/regua/tipo/${this.$route.query?.listagem}`;
      }
      return {
        name: "Reguas",
        query: {
          tipo: this.regua.tipo,
          tipo_movimentacao_id: this.$route.query.tipo_movimentacao_id,
          tela: "reguas",
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.qj-skeleton-text.v-skeleton-loader .v-skeleton-loader__text {
  height: 20px !important;
}

.qj-skeleton-text.v-skeleton-loader .v-skeleton-loader__list-item {
  background: transparent !important;
}

.header {
  width: 100%;
  @apply flex items-center gap-1;
  .acoes {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
