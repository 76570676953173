import { render, staticRenderFns } from "./DialogFiltros.vue?vue&type=template&id=6f04c08e&scoped=true"
import script from "./DialogFiltros.vue?vue&type=script&lang=js"
export * from "./DialogFiltros.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f04c08e",
  null
  
)

export default component.exports