<template>
  <v-container class="fill-height pa-0 iframe-wrapper">
    <iframe
      v-if="dashboard"
      :src="dashboard.url"
      border="0"
      frameborder="0"
      allowFullScreen="true"
      fill-height
    ></iframe>

    <v-alert
      v-else
      type="info"
      class="mx-auto my-10 justify-center"
      outlined
      style="width: fit-content"
    >
      Nenhum dashboard configurado.
    </v-alert>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dashboard: null,
  }),
  created() {
    let id = this.$route.params.id || "contratos";
    this.carregarDashboad(id);
  },
  beforeRouteUpdate(to, from, next) {
    let id = to.params.id || "contratos";
    this.carregarDashboad(id);

    return next();
  },

  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.carregarDashboad(this.$route.params.id);
      },
    },
  },

  methods: {
    carregarDashboad(id) {
      console.debug(`Buscando a dashboard "${id}"`);

      let empresa = this.$store.state.empresa || {};
      let empresaSelecionada = empresa.empresaSelecionada || {};
      let dashboards = empresaSelecionada.dashboard || [];

      this.dashboard = dashboards.find((dashboard) => dashboard.id == id);
    },
  },
};
</script>

<style lang="scss">
.pagina-dashboard .v-main__wrap > .container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
</style>

<style scoped lang="scss">
.iframe-wrapper {
  max-width: 100%;
}

iframe {
  border: 0;
  height: 100%;
  width: 100%;
}
</style>
