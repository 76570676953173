<template>
  <v-dialog persistent v-model="dialog" :width="400">
    <v-form @submit.prevent="salvarBrokers" ref="form">
      <v-card>
        <v-card-title class="text-h5 title-card flex align-center">
          Configurar Brokers da Régua
          <v-icon
            v-if="regua?.id_regua_desafiante"
            :color="regua.tipo_teste_ab === 'A' ? '#ef5350' : '#42A5F5'"
            class="ml-1"
          >
            {{
              regua.tipo_teste_ab === "A"
                ? "mdi-alpha-a-circle-outline"
                : "mdi-alpha-b-circle-outline"
            }}
          </v-icon>
        </v-card-title>
        <v-card-subtitle v-show="regua"> #{{ regua?.id }} - {{ regua?.nome }} </v-card-subtitle>

        <v-card-text class="pt-6">
          <div
            class="flex justify-between align-baseline"
            v-for="(broker, index) in Object.entries(empresaSelecionada.brokers)"
            :key="index"
          >
            <!-- <span class="text-body-1">{{ broker[0] | capitalize }}</span> -->
            <v-select
              required
              clearable
              dense
              outlined
              color="deep-purple accent-2"
              :items="broker[1]"
              v-model="brokers[broker[0]]"
              :label="broker[0].replace('_', ' ') | capitalize"
            ></v-select>
          </div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog()"> cancelar </v-btn>
          <v-btn color="primary" type="submit" :loading="salvando || carregando"> salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      regua: null,
      dialog: false,
      salvando: false,
      carregando: false,
      brokers: {},
    };
  },
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  methods: {
    ...mapActions("reguas", ["getReguaBrokers", "postReguaBrokers"]),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    async openDialog() {
      this.dialog = true;
      this.carregando = true;

      // configura os campos que o form vai ter, baseado nos canais disponiveis da empresa
      Object.keys(this.empresaSelecionada.brokers).forEach((key) => {
        this.brokers[key] = null;
      });

      try {
        const data = await this.getReguaBrokers(this.regua.id);
        data.forEach((broker) => {
          const brokerEntry = Object.entries(broker)[0];
          this.brokers[brokerEntry[0]] = brokerEntry[1];
        });
      } catch (e) {
        console.error("Erro ao carregar brokers", e);
      } finally {
        this.carregando = false;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.regua = null;
      this.dialog = false;
      this.salvando = false;
      this.carregando = false;
      Object.keys(this.brokers).forEach((key) => {
        this.brokers[key] = null;
      });
    },
    async salvarBrokers() {
      this.salvando = true;
      try {
        await this.postReguaBrokers({
          regua_id: this.regua.id,
          ...this.brokers,
        });
        this.showSnackBar({
          text: `Brokers configurados com sucesso !`,
        });
        this.closeDialog();
      } catch (e) {
        console.error("Erro ao salvar brokers", e);
        this.showSnackBar({
          text: `Erro ao configurar brokers`,
        });
      } finally {
        this.salvando = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
