<template>
  <div>
    <v-dialog
      v-model="dialogServico"
      max-width="400"
      @click:outside="fecharDialog()"
      persistent
    >
      <v-form ref="formServico" lazy-validation @submit="salvarServico($event)">
        <v-card>
          <v-card-title
            >{{ servicoId ? "Editar" : "Criar" }} Serviço</v-card-title
          >
          <v-card-text class="pt-7 pb-0">
            <v-select
              v-model="servico.referencia"
              :items="referenciaServicos"
              label="Referência do Serviço"
              outlined
              required
              :rules="[rules.required]"
            ></v-select>
            <v-text-field
              label="Descrição"
              outlined
              v-model="servico.descricao"
              required
              multiple
              :rules="[rules.required]"
              :hint="
                servico.descricao && servico.descricao.length >= 256
                  ? 'Descrição limitada à 256 caracteres'
                  : ''
              "
              maxLength="256"
            ></v-text-field>
            <div class="d-flex">
              <v-text-field
                type="number"
                label="Quantidade"
                class="mr-1"
                outlined
                v-model="servico.quantidade"
                required
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Valor Unitário"
                type="number"
                class="ml-2"
                outlined
                v-model="servico.valorUnitario"
                required
                :rules="[rules.required]"
                prefix="R$"
              ></v-text-field>
            </div>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="fecharDialog()">
              cancelar
            </v-btn>
            <v-btn color="primary" type="submit"> salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- DIALOG DELETAR SERVIÇO -->
    <v-dialog v-model="dialogDeletarServico" max-width="400" persistent>
      <v-form
        ref="formDeletarServico"
        lazy-validation
        @submit="apagarServico($event)"
      >
        <v-card>
          <v-card-title>Deseja apagar o serviço selecionado?</v-card-title>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="fecharDialog()"> Não </v-btn>
            <v-btn color="primary" type="submit"> Sim </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { formataCasasDecimais } from "@/assets/js/utils";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      dialogDeletarServico: false,
      fatura: null,
      servico: {
        referencia: null,
        descricao: null,
        quantidade: null,
        valorUnitario: null,
      },
      servicoId: null,
      dialogServico: false,
      referenciaServicos: [
        {
          text: "Franquia",
          value: "FRANQUIA",
        },
        {
          text: "Evento",
          value: "EVENTO",
        },
        {
          text: "Outros",
          value: "OUTROS",
        },
      ],
    };
  },

  methods: {
    ...mapActions("fatura", {
      editaOuCriaItemFatura: "editaOuCriaItemFatura",
      deletaItemFatura: "deletaItemFatura",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapMutations("fatura", {
      setItemFaturaSelecionado: "setItemFaturaSelecionado",
    }),
    abrirDialog(servico) {
      if (servico) {
        this.setItemFaturaSelecionado(servico);
        this.servicoId = servico.id;
        this.servico.referencia = servico.referencia;
        this.servico.descricao = servico.descricao;
        this.servico.quantidade = servico.quantidade;
        this.servico.valorUnitario = formataCasasDecimais(
          servico.valor_unitario
        );
      }
      this.dialogServico = true;
      this.$nextTick(() => this.$refs.formServico.resetValidation());
    },
    fecharDialog() {
      this.$refs.formServico.reset();
      this.dialogDeletarServico = false;
      this.dialogServico = false;
    },
    abrirDialogDeletarServico(servico) {
      this.setItemFaturaSelecionado(servico);
      this.dialogDeletarServico = true;
    },
    salvarServico(event) {
      event.preventDefault();

      const validaCampos = this.$refs.formServico.validate();
      if (!validaCampos) return;

      let payload = {
        id: this.servicoId || undefined,
        fatura_id: this.$route.params.id || undefined,
        referencia: this.servico.referencia,
        descricao: this.servico.descricao,
        quantidade: this.servico.quantidade,
        valor_unitario: this.servico.valorUnitario,
      };
      this.editaOuCriaItemFatura(payload)
        .then(() => {
          this.showSnackBar({
            text: `Serviço ${
              this.servicoId ? "editado" : "criado"
            } com sucesso!`,
          });
          this.$emit("salvar", payload.referencia);
          this.$refs.formServico.reset();
          this.dialogServico = false;
        })
        .catch((error) => {
          console.error(error);
          this.showSnackBar({
            text: `Erro ao ${this.servicoId ? "editar" : "criar"} o serviço!`,
          });
        });
    },
    apagarServico(event) {
      event.preventDefault();
      this.deletaItemFatura(this.itemFaturaSelecionado)
        .then(() => {
          this.showSnackBar({
            text: `Serviço apagado com sucesso!`,
          });
          this.$emit("carregarFatura");
          this.dialogDeletarServico = false;
        })
        .catch((error) => {
          console.error(error);
          this.showSnackBar({
            text: `Erro ao apagar o serviço!`,
          });
        });
    },
  },

  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("fatura", {
      faturas: "fatura",
      itemFaturaSelecionado: "itemFaturaSelecionado",
    }),
  },
};
</script>

<style></style>
