<template>
  <v-dialog v-model="dialog" :width="450">
    <v-card>
      <v-card-title>
        <h3>Tem certeza que deseja {{ titulo.toLowerCase() }} este NPS?</h3>
      </v-card-title>
      <v-card-text>
        <span class="text-subtitle-1">
          {{ textoDoCard }}
        </span>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fechar()"> Cancelar </v-btn>
        <v-btn color="primary" text @click="confirmar()"> Confirmar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      titulo: "",
      quantidade: 0,
      item: {},
    };
  },
  computed: {
    textoDoCard() {
      return this.quantidade > 1
        ? `Ao confirmar, ${this.quantidade} eventos vinculados a este NPS serão arquivados`
        : `Ao confirmar, ${this.quantidade} evento vinculado a este NPS será arquivado`;
    },
  },
  methods: {
    async abrir(titulo, numeroEventos, nps) {
      this.titulo = titulo;
      this.quantidade = numeroEventos;
      this.dialog = true;
      this.item = { ...nps };
      return;
    },
    fechar() {
      this.dialog = false;
      return;
    },
    confirmar() {
      let itemAlterado = this.item;
      if (this.titulo == "despublicar") {
        itemAlterado.status = "RASCUNHO";
      } else if (this.titulo == "arquivar") {
        itemAlterado.status = "ARQUIVADA";
      }

      this.$emit("confirmar", itemAlterado);
      this.fechar();
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  word-break: initial;
}
</style>
