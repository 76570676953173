<template>
  <v-dialog v-model="dialog" :width="650" persistent>
    <v-form @submit="salvarRegua" ref="form" lazy-validation>
      <v-card>
        <v-card-title class="text-h5 title-card">
          {{ retornaCardTitle(regua.id, regua.tipo) }}
          <v-spacer></v-spacer>
          <v-chip
            v-if="regua.tipo == 'MOVIMENTACAO'"
            color="green lighten-2"
            class="black--text font-weight-medium ml-4"
          >
            Movimentação
          </v-chip>
          <v-chip
            v-else
            color="blue lighten-2"
            class="black--text font-weight-medium ml-4"
          >
            Pré-definidas
          </v-chip>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-text-field
            v-model="regua.nome"
            label="Nome"
            outlined
            autofocus
            required
            :rules="[rules.required]"
          ></v-text-field>
          <v-textarea
            label="Observação"
            v-model="regua.observacao"
            rows="2"
            outlined
            hide-details
          ></v-textarea>
          <div>
            <v-switch
              hide-details
              label="Clonar Eventos"
              v-model="clonarEventos"
              v-if="!edit"
              class="font-weight-bold"
              style="width: fit-content !important"
            ></v-switch>
            <v-select
              hide-details
              outlined
              label="Régua"
              v-if="clonarEventos"
              no-data-text="Nenhuma régua ativa"
              class="mt-4"
              :items="reguasFiltradasTipo"
              v-model="selectReguasTipo"
            ></v-select>
          </div>

          <div v-if="regua.id_regua_desafiante || !edit">
            <v-switch
              v-if="
                !regua.id_regua_desafiante ||
                regua.id < regua.id_regua_desafiante
              "
              hide-details
              label="Teste A/B"
              v-model="testeAB"
              :disabled="edit ? true : false"
              class="font-weight-bold"
            ></v-switch>

            <div class="mt-6" v-if="testeAB && reguaA">
              <div class="d-flex justify-between align-center mb-2">
                <span class="font-weight-bold" style="font-size: 1rem">
                  Amostra
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>6º e 7º dígitos do CPF</span>
                  </v-tooltip>
                </span>
                <div>
                  <span class="cor reguaA mr-8"
                    >Régua <b>A</b> (00 ao {{ amostra.corte | padStart }})</span
                  >
                  <span class="cor reguaB"
                    >Régua <b>B</b> ({{ (amostra.corte + 1) | padStart }} ao
                    99)</span
                  >
                </div>
              </div>

              <v-slider
                v-model="amostra.corte"
                max="98"
                min="0"
                hide-details
                style="width: 100%"
                track-color="#42A5F5"
                thumb-color="#EF5350"
                color="#EF5350"
                thumb-label
              >
                <template v-slot:prepend>
                  <p>00</p>
                </template>
                <template v-slot:thumb-label="{ value }">
                  {{ value | padStart }}
                </template>
                <template v-slot:append>
                  <p>99</p>
                </template>
              </v-slider>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog()"> cancelar </v-btn>
          <v-btn color="primary" type="submit" :loading="salvando">
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import reguaService from "@/services/regua";
import Vue from "vue";

export default {
  props: {
    tituloMovimentacao: "",
  },
  data() {
    return {
      selectReguasTipo: undefined,
      clonarEventos: false,
      reguasFiltradasTipo: [],
      editarTipoRegua: false,
      salvando: false,
      edit: false,
      dialog: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      regua: {
        type: null,
        nome: null,
        observacao: null,
        tipo: null,
      },

      testeAB: false,

      testeA: {
        digito_verificador_inicio: null,
        digito_verificador_fim: null,
        tipo_teste_ab: "A",
      },
      testeB: {
        digito_verificador_inicio: null,
        digito_verificador_fim: null,
        tipo_teste_ab: "B",
      },

      amostra: {
        corte: 49,
      },

      perfilContrato: [
        {
          text: "Nenhum",
          value: null,
        },
      ],
    };
  },

  methods: {
    ...mapActions("reguas", {
      createOrEditRegua: "saveRegua",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    abrirNovaRegua(type, perfil_cliente, perfil_contrato) {
      this.filtraReguaPorTipo(type);
      if (perfil_cliente == "sem_perfil") {
        perfil_cliente = null;
      }

      if (perfil_contrato == "sem_perfil") {
        perfil_contrato = null;
      }

      if (type == null) this.editarTipoRegua = true;
      this.dialog = true;
      this.edit = false;
      this.regua = {
        tipo: type || null,
        regua_id: null,
        nome: null,
        observacao: null,
        status: "RASCUNHO",
        empresa_id: this.empresaSelecionada.id,
        perfil_cliente: perfil_cliente,
        perfil_contrato: perfil_contrato,
        tipo_teste_ab: "A",
      };
    },

    abrirEditRegua(regua) {
      this.editarTipoRegua = true;
      this.edit = true;
      this.regua = JSON.parse(JSON.stringify(regua));
      this.dialog = true;

      this.testeA.digito_verificador_inicio = 0;
      this.testeA.digito_verificador_fim = parseInt(
        regua.digito_verificador_fim
      );
      this.testeA.tipo_teste_ab = "A";

      this.testeB.digito_verificador_inicio =
        parseInt(this.testeA.digito_verificador_fim) + 1;
      this.testeB.digito_verificador_fim = 99;
      this.testeB.tipo_teste_ab = "B";

      this.amostra.corte = this.testeA.digito_verificador_fim;

      if (regua.id_regua_desafiante) {
        this.testeAB = true;
      }
    },

    filtraReguaPorTipo(tipo) {
      this.reguasFiltradasTipo = [];
      this.listaReguas.objects.forEach((regua) => {
        if (
          regua.tipo == tipo &&
          regua.status != "ARQUIVADA" &&
          !regua.id_regua_desafiante
        ) {
          this.reguasFiltradasTipo.push({
            text: regua.nome,
            value: regua,
          });
        }
      });
    },

    async salvarRegua(evento) {
      evento.preventDefault();
      const isValid = this.$refs.form.validate();

      if (!isValid) return;
      if (this.salvando) return;

      this.testeA.digito_verificador_inicio = String(0);
      this.testeA.digito_verificador_fim = String(this.amostra.corte);

      this.testeB.digito_verificador_inicio = String(this.amostra.corte + 1);
      this.testeB.digito_verificador_fim = String(99);

      this.salvando = true;

      let payload = {
        empresa_id: this.regua.empresa_id,
        id: this.regua.id,
        nome: this.regua.nome,
        observacao: this.regua.observacao,
        ordem_exibicao: this.regua.ordem_exibicao,
        ordem: this.regua.ordem,
        status: this.regua.status,
        tipo: this.regua.tipo,
        digito_verificador_inicio: String(
          this.testeA.digito_verificador_inicio
        ),
        digito_verificador_fim: String(this.testeA.digito_verificador_fim),
        clonar_regua: this.selectReguasTipo?.id,
        tipo_movimentacao_id: this.$route.query.tipo_movimentacao_id,
      };

      if (this.testeAB && payload.id == null) {
        let testeA = { ...payload, ...this.testeA };
        let testeB = { ...payload, ...this.testeB };
        let testes = [testeA, testeB];

        try {
          const resp = await reguaService.criaTesteAB(testes);
          this.$emit("addOrUpdateRegua", resp.data);
          this.fecharDialog();
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: "Erro ao salvar a régua",
          });
        } finally {
          this.salvando = false;
        }
        return;
      }

      if (this.testeAB && payload.id) {
        let testeA = { ...payload, ...this.testeA };
        let testeB = { ...payload, ...this.testeB };
        let testes = [testeA, testeB];

        try {
          const resp = await reguaService.editaTesteAB(payload.id, testes);
          this.$emit("addOrUpdateRegua", resp.data);
          this.fecharDialog();
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: "Erro ao salvar a régua",
          });
        } finally {
          this.salvando = false;
        }
        return;
      }

      try {
        this.chamadaSalvarRegua(payload);
      } catch (e) {
        this.showSnackBar({
          text: "Erro ao salvar a régua",
        });
      }
    },

    fecharDialog() {
      this.dialog = false;
      this.testeAB = false;
      this.regua.nome = null;
      this.regua.observacao = null;
      this.editarTipoRegua = false;
      (this.testeA = {
        digito_verificador_inicio: null,
        digito_verificador_fim: null,
        tipo_teste_ab: "A",
      }),
        (this.testeB = {
          digito_verificador_inicio: null,
          digito_verificador_fim: null,
          tipo_teste_ab: "B",
        }),
        (this.edit = false);
      this.selectReguasTipo = null;
      this.clonarEventos = false;
      this.amostra.corte = 49;
    },

    async chamadaSalvarRegua(payload) {
      try {
        const { data } = await this.createOrEditRegua(payload);
        this.$emit("addOrUpdateRegua", data);
        this.showSnackBar({
          text: `Régua ${this.edit ? "editada" : "criada"} com sucesso`,
        });

        this.fecharDialog();
      } catch (e) {
        this.showSnackBar({
          text: `Ocorreu um erro ao ${this.edit ? "editar" : "criar"} a regua`,
        });
        console.error(e.message);
      } finally {
        this.salvando = false;
      }
    },

    verificaPerfilCliente(perfil_cliente) {
      if (perfil_cliente == "Novo cliente") {
        return "novo_cliente";
      } else if (perfil_cliente == "Paga adiantado") {
        return "paga_adiantado";
      } else if (perfil_cliente == "Paga em dia") {
        return "paga_em_dia";
      } else if (perfil_cliente == "Paga atrasado") {
        return "paga_atrasado";
      } else {
        return "sem_perfil";
      }
    },
    retornaCardTitle(reguaId, tipoRegua) {
      if (tipoRegua == "MOVIMENTACAO") {
        if (reguaId) {
          return `Editar Régua de ${this.tituloMovimentacao}`;
        } else {
          return `Nova Régua de ${this.tituloMovimentacao}`;
        }
      } else {
        if (reguaId) {
          return `Editar Régua de ${Vue.filter("tipoRegua")(tipoRegua)}`;
        } else {
          return `Nova Régua de ${Vue.filter("tipoRegua")(tipoRegua)}`;
        }
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("reguas", {
      listaReguas: "reguas",
      getReguaById: "getReguaById",
    }),

    reguaA() {
      if (
        this.edit &&
        this.regua.id_regua_desafiante &&
        this.regua.id < this.regua.id_regua_desafiante
      ) {
        return true;
      }
      if (!this.edit) return true;
      return false;
    },

    perfisCliente() {
      let perfis = [
        {
          text: "Nenhum",
          value: null,
        },
      ];

      let perfisRegua = this.empresaSelecionada.perfis_regua || {};
      let perfisCliente = perfisRegua.perfis_cliente || [];

      perfisCliente.forEach((perfilCliente) => {
        let nome = Object.keys(perfilCliente)[0];

        perfis.push({
          text: perfilCliente[nome],
          value: nome,
        });
      });

      return perfis;
    },

    perfisContrato() {
      let perfis = [
        {
          text: "Nenhum",
          value: null,
        },
      ];

      let perfisRegua = this.empresaSelecionada.perfis_regua || {};
      let perfisContrato = perfisRegua.perfis_contrato || [];

      perfisContrato.forEach((perfilContrato) => {
        let nome = Object.keys(perfilContrato)[0];

        perfis.push({
          text: perfilContrato[nome],
          value: nome,
        });
      });

      return perfis;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(.v-slider__thumb-label) {
  transform: translateY(-20%) translateY(-12px) translateX(-47%) rotate(45deg) !important;
}

::v-deep(.v-slider__thumb) {
  border-radius: 0;
  width: 2px;
  height: 20px;
  left: 0;

  &::before {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    content: "";
    color: inherit;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: currentColor;
    opacity: 0.3;
    position: absolute;
    left: -17px;
    top: -8px;
    transform: scale(0.1);
    pointer-events: none;
  }
}

.reguaA,
.reguaB {
  margin-bottom: 0.5rem;
  position: relative;
  font-size: 0.9rem;

  &::before {
    position: absolute;
    top: 0.1rem;
    left: -1.2rem;
    right: 1rem;
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
  }
}

.reguaA {
  &::before {
    background-color: #ef5350;
  }
}

.reguaB {
  &::before {
    background-color: #42a5f5;
  }
}
</style>
