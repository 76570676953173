<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex mx-auto align-center mb-2"
      style="width: 1000px"
      v-if="!carregando"
      :class="{
        'mt-4': !quantidadeReguas || !quantidadeReguasArquivadas,
        'mb-4': !quantidadeReguasArquivadas,
      }"
    >
      <div class="text-h6 font-weight-bold">
        Listagem de Réguas
        {{
          quantidadeReguas
            ? "(" +
              (routeParams.arquivadas
                ? quantidadeReguas + quantidadeReguasArquivadas
                : quantidadeReguas) +
              ")"
            : ""
        }}
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex" v-if="quantidadeReguasArquivadas">
        <v-switch
          v-model="routeParams.arquivadas"
          :label="`Mostrar arquivadas (${quantidadeReguasArquivadas})`"
        ></v-switch>
      </div>
    </div>

    <div>
      <v-card class="table-wraper mx-auto">
        <v-data-table v-if="!carregando" hide-default-footer>
          <template v-slot:body>
            <div class="regua__wrapper list-wraper">
              <v-expansion-panels class="expansion-panels" v-model="painelRegua" accordion multiple>
                <v-expansion-panel
                  v-for="regua in reguas"
                  :key="regua.id"
                  :id="regua.id"
                  ref="reguas"
                  class="rounded-0 pa-0 elevation-0 flex-column"
                  :readonly="!verificaEventoAtivo(regua) && !regua.id_regua_desafiante"
                >
                  <ReguaExpansionCabecalho
                    v-if="!regua.id_regua_desafiante"
                    :regua="regua"
                    @visualizarPerfisRegua="visualizarPerfisRegua"
                    @redirectEvento="handleRedirectEvento"
                    @redirectDashboard="handleRedirectDashboard"
                    @brokersRegua="handleBrokersRegua"
                    @editarRegua="editarRegua"
                    @toggleRegua="handleToggleRegua"
                    @definirGanhadora="handleDefinirGanhadora"
                    @pararRegua="pararRegua"
                    @rodarRegua="rodarRegua"
                  />

                  <!-- cabecalho para reguas de teste ab -->
                  <ReguaAbExpansionCabecalho
                    v-if="regua.id_regua_desafiante"
                    :regua="regua"
                    @visualizarPerfisRegua="visualizarPerfisRegua"
                    @redirectEvento="handleRedirectEvento"
                    @redirectDashboard="handleRedirectDashboard"
                    @brokersRegua="handleBrokersRegua"
                    @editarRegua="editarRegua"
                    @toggleRegua="handleToggleRegua"
                    @definirGanhadora="handleDefinirGanhadora"
                    @pararRegua="pararRegua"
                    @rodarRegua="rodarRegua"
                    @verResultados="verResultados"
                  />

                  <v-expansion-panel-content>
                    <ReguaExpansionEventos
                      v-if="!regua.id_regua_desafiante"
                      class="mt-1 mb-3 px-3"
                      style="max-width: 1000px"
                      :eventos="listarEventosPorDia(regua.eventos)"
                      :regua="regua"
                    />

                    <!-- eventos das reguas de teste ab -->
                    <ReguaAbExpansionEventos
                      v-if="regua.id_regua_desafiante"
                      class="mt-1 mb-3 px-3"
                      style="max-width: 1000px"
                      :regua="regua"
                      @redirectEvento="handleRedirectEvento"
                      @visualizarPerfisRegua="visualizarPerfisRegua"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <PromiseBasedDialog ref="promiseBasedDialog" :options="promiseBasedDialogOptions" />
    <DialogDefinirGanhadora ref="dialogDefinirGanhadora" :reguaA="null" :reguaB="null" />
    <DialogReguaBrokers ref="dialogReguaBrokers" />
    <DialogNovaRegua ref="editOuNovaReguaDialog" @addOrUpdateRegua="addOrUpdateRegua" />
    <DialogVisualizarPerfis ref="dialogVisualizarPerfis" />
  </div>
</template>

<script>
import { checkCredentials } from "@/assets/js/utils";
import { mapGetters, mapActions } from "vuex";
import PromiseBasedDialog from "../../../../components/dialogs/promiseBasedDialog.vue";
import DialogDefinirGanhadora from "../dialogs/DialogDefinirGanhadora.vue";
import DialogReguaBrokers from "../dialogs/DialogReguaBrokers.vue";
import DialogNovaRegua from "../../components/dialogs/DialogNovaRegua.vue";
import DialogVisualizarPerfis from "../dialogs/DialogVisualizarPerfis.vue";
import ReguaExpansionEventos from "./ReguaExpansionEventos.vue";
import ReguaExpansionCabecalho from "./ReguaExpansionCabecalho.vue";
import ReguaAbExpansionCabecalho from "./testeAB/ReguaAbExpansionCabecalho.vue";
import ReguaAbExpansionEventos from "./testeAB/ReguaAbExpansionEventos.vue";

export default {
  components: {
    PromiseBasedDialog,
    DialogNovaRegua,
    DialogVisualizarPerfis,
    ReguaExpansionEventos,
    ReguaExpansionCabecalho,
    ReguaAbExpansionCabecalho,
    ReguaAbExpansionEventos,
    DialogDefinirGanhadora,
    DialogReguaBrokers,
  },
  props: {
    reguasFiltradas: null,
    carregando: false,
    routeParams: null,
    filtroRegua: null,
    reguaSelecionada: null,
    todasReguas: [],
  },
  data() {
    return {
      reguasReduzidas: [],
      promiseBasedDialogOptions: {},
      painelRegua: [],
    };
  },
  mounted() {
    document.addEventListener("keydown", this.interceptSearch);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.interceptSearch);
  },
  async created() {
    if (this.reguaSelecionada) {
      let index = this.reguasFiltradas.findIndex((regua) => regua.id == this.reguaSelecionada.id);

      if (index != -1) {
        if (this.reguasFiltradas[index].eventos.length >= 1) {
          this.painelRegua = [index];
        }

        await this.fazerRolagem(this.reguasFiltradas[index].id);
      }
    }
  },
  methods: {
    checkCredentials,
    ...mapActions("reguas", {
      saveRegua: "saveRegua",
      carregaReguaPerfil: "carregaReguaPerfil",
      definirGanhadora: "definirGanhadora",
    }),

    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    interceptSearch: (event) => {
      if (event.ctrlKey && event.key == "f") {
        event.preventDefault();
        event.stopPropagation();
        document.getElementById("searchInput").focus();
      }
    },

    listarEventosPorDia(eventos) {
      const eventosFiltrados = eventos.reduce((unicoEvento, evento) => {
        if (evento.status == "PRODUCAO" && evento.master_id == null) {
          if (!unicoEvento.some((obj) => obj.dia === evento.dia)) {
            unicoEvento.push(evento);
          }
        }
        return unicoEvento;
      }, []);
      return eventosFiltrados.sort((a, b) => (a.dia > b.dia ? 1 : -1));
    },

    handleRedirectEvento(reguaId) {
      this.$router.push({
        name: "eventos",
        params: {
          regua_id: reguaId,
        },
        query: {
          tipo_movimentacao_id: this.$route.query.tipo_movimentacao_id,
        },
      });
    },

    handleRedirectDashboard(reguaId) {
      const reguaBaseURL = this.empresaSelecionada.configuracoes?.find(
        (config) => config.chave === "dashboard_reguas"
      );

      if (reguaBaseURL.valor)
        window.open(`${reguaBaseURL.valor}?r%25C3%25A9gua=${reguaId}`, "_blank");
    },

    handleBrokersRegua(regua) {
      this.$refs.dialogReguaBrokers.regua = regua;
      this.$refs.dialogReguaBrokers.openDialog();
    },

    verificaEventoAtivo(regua) {
      return regua.eventos.some((ev) => ev.status === "PRODUCAO");
    },

    async pararRegua(regua) {
      if (!checkCredentials(["admin", "editor_regua"])) return;
      this.promiseBasedDialogOptions = {
        cardText: "Deseja parar esta régua? Todos os perfis serão removidos!",
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;

      try {
        const { data } = await this.saveRegua({
          id: regua.id,
          status: "RASCUNHO",
        });
        regua.status = data.status;
        regua.perfis = [];
        this.$emit("atualizarRegua", data);

        if (regua.id_regua_desafiante) {
          const { data } = await this.saveRegua({
            id: regua.id_regua_desafiante,
            status: "RASCUNHO",
          });
          this.getReguaById(regua.id_regua_desafiante).status = data.status;
          this.getReguaById(regua.id_regua_desafiante).perfis = [];
          this.$emit("atualizarRegua", data);
        }
        this.showSnackBar({
          text: "Régua parada",
        });
      } catch (e) {
        this.showSnackBar({
          text: "Erro ao parar régua",
        });
      }
    },

    async rodarRegua(regua) {
      if (!checkCredentials(["admin", "editor_regua"])) return;
      this.promiseBasedDialogOptions = {
        cardText: "Deseja colocar esta régua em produção?",
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;

      try {
        const { data } = await this.saveRegua({
          id: regua.id,
          status: "PRODUCAO",
        });
        regua.status = data.status;
        this.$emit("atualizarRegua", data);

        if (regua.id_regua_desafiante) {
          const { data } = await this.saveRegua({
            id: regua.id_regua_desafiante,
            status: "PRODUCAO",
          });
          this.getReguaById(regua.id_regua_desafiante).status = data.status;
          this.$emit("atualizarRegua", data);
        }

        this.showSnackBar({
          text: "Régua alterada para produção",
        });
      } catch (e) {
        this.showSnackBar({
          text: "Régua alterada para rascunho",
        });
      }
    },

    verResultados(regua_id) {
      window.open(
        `https://metabase.melembre.com.br/dashboard/708-teste-a-b?r%25C3%25A9gua=${regua_id}`,
        "_blank"
      );
    },

    async handleDefinirGanhadora({ reguaA, reguaB }) {
      this.$refs.dialogDefinirGanhadora.reguaA = reguaA;
      this.$refs.dialogDefinirGanhadora.reguaB = reguaB;

      const { decision, winner } = await this.$refs.dialogDefinirGanhadora.openDialog();
      if (!decision) return;

      try {
        const reguaGanhadoraId = winner === "A" ? reguaA.id : reguaB.id;
        await this.definirGanhadora(reguaGanhadoraId);
        this.showSnackBar({
          text: `Régua definida com sucesso!`,
        });
        this.$emit("getTodasReguas", this.$route.query.tipo);
      } catch {
        this.showSnackBar({
          text: `Erro ao definir régua como ganhadora`,
        });
      }
      return;
    },

    async toggleReguaAb(regua) {
      this.promiseBasedDialogOptions = {
        cardText:
          regua.status == "ARQUIVADA"
            ? "Deseja ativar esta régua?"
            : "Deseja arquivar esta régua? Lembrando que é uma régua do tipo Teste A/B, caso seja arquivada, a régua desafiante ficará como principal.",
        cardTitle: regua.status == "ARQUIVADA" ? 'Ativar régua' : 'Arquivar régua',
        cardSubtitle: `#${regua.id} - ${regua.nome}`,
        acceptBtn: regua.status != "ARQUIVADA" ? "arquivar" : "ativar",
        declineBtn: "cancelar",
        width: "400px",
      };

      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;

      const reguaDesafiante = this.getReguaById(regua.id_regua_desafiante);

      try {
        const dataArquivada = await this.saveRegua({
          id: regua.id,
          status: regua.status != "ARQUIVADA" ? "ARQUIVADA" : "RASCUNHO",
          id_regua_desafiante: null,
          digito_verificador_inicio: null,
          digito_verificador_fim: null,
        });

        const dataDesafiante = await this.saveRegua({
          id: reguaDesafiante.id,
          id_regua_desafiante: null,
          digito_verificador_inicio: null,
          digito_verificador_fim: null,
        });

        this.showSnackBar({
          text: `Régua ${regua.status != "ARQUIVADA" ? "arquivada" : "ativada"} com sucesso!`,
        });

        regua.status = dataArquivada.data.status;
        reguaDesafiante.status = dataDesafiante.data.status;
        reguaDesafiante.id_regua_desafiante = dataDesafiante.data.id_regua_desafiante;
        reguaDesafiante.digito_verificador_inicio = dataDesafiante.data.digito_verificador_inicio;
        reguaDesafiante.digito_verificador_fim = dataDesafiante.data.digito_verificador_fim;
      } catch (e) {
        this.showSnackBar({
          text: `Ocorreu um erro ao ${regua.status != "ARQUIVADA" ? "arquivar" : "ativar"} a regua`,
        });
      }
    },

    async toggleRegua(regua) {
      this.promiseBasedDialogOptions = {
        cardText:
          regua.status == "ARQUIVADA" ? "Deseja ativar esta régua?" : "Deseja arquivar esta régua?",
        cardTitle: regua.status == "ARQUIVADA" ? 'Ativar régua' : 'Arquivar régua',
        cardSubtitle: `#${regua.id} - ${regua.nome}`,
        acceptBtn: regua.status != "ARQUIVADA" ? "arquivar" : "ativar",
        declineBtn: "cancelar",
        width: "300px",
      };
      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;

      try {
        const { data } = await this.saveRegua({
          id: regua.id,
          status: regua.status != "ARQUIVADA" ? "ARQUIVADA" : "RASCUNHO",
        });
        this.showSnackBar({
          text: `Régua ${regua.status != "ARQUIVADA" ? "arquivada" : "ativada"} com sucesso!`,
        });
        regua.status = data.status;
      } catch (e) {
        this.showSnackBar({
          text: `Ocorreu um erro ao ${regua.status != "ARQUIVADA" ? "arquivar" : "ativar"} a régua`,
        });
      }
    },

    async handleToggleRegua(regua) {
      regua.id_regua_desafiante ? await this.toggleReguaAb(regua) : await this.toggleRegua(regua);

      this.$emit("filtraReguas");
    },

    editarRegua(regua) {
      this.$refs.editOuNovaReguaDialog.abrirEditRegua(regua);
    },

    abrirNovaRegua(type, perfil_cliente, perfil_contrato) {
      this.$refs.editOuNovaReguaDialog.abrirNovaRegua(type, perfil_cliente, perfil_contrato);
    },

    addOrUpdateRegua() {
      this.$emit("getTodasReguas", this.$route.query.tipo);
    },

    visualizarPerfisRegua(regua) {
      this.$refs.dialogVisualizarPerfis.abrirDialog(regua, "EDITAR");
    },

    async fazerRolagem(id) {
      await setTimeout(() => {
        document.getElementById(id)?.scrollIntoView({
          block: "center",
          inline: "nearest",
          behavior: "smooth",
        });
      }, 250);
    },
  },
  computed: {
    ...mapGetters("reguas", {
      reguas: "reguas",
      getReguaById: "getReguaById",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    quantidadeReguas() {
      return this.reguas.length;
    },
    quantidadeReguasArquivadas() {
      try {
        return this.todasReguas
          ?.filter((regua) => {
            if (!this.filtroRegua) return true;
            return regua.nome
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                this.filtroRegua
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              );
          })
          .filter((item) => item.status == "ARQUIVADA").length;
      } catch {
        return 0;
      }
    },
    reguas() {
      // nas reguas repetidas (A/B) filtrar para usar só a regua A, por conta da visualização
      this.reguasReduzidas = this.reguasFiltradas.reduce((acc, curr) => {
        if (curr.id_regua_desafiante) {
          const itemRepetido = acc.find((item) => item.id === curr.id_regua_desafiante);
          if (itemRepetido) return acc;
        }

        acc.push(curr);
        return acc;
      }, []);

      return this.reguasReduzidas.filter((regua) => {
        if (!this.filtroRegua) return true;
        return regua.nome
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.filtroRegua
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-wraper {
  max-width: 1000px;
}

a {
  color: hsl(229, 100%, 66%);
}

.ARQUIVADA {
  text-decoration: line-through !important;
  color: #bdbdbd !important;
}

.RASCUNHO {
  .v-application .indigo.accent-3 {
    background-color: #9f9f9f !important;
    border-color: #9f9f9f !important;
  }

  color: #9f9f9f !important;
}

.regua__wrapper {
  a:nth-of-type(1) {
    &:hover {
      color: #4264fe !important;
      text-decoration: underline;
    }
  }
}

.list-wraper {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}

.events__sub {
  font-size: 10px !important;
  letter-spacing: 0.1px !important;
}

.expansion-panels {
  z-index: auto;
}

:deep(.v-expansion-panel-content__wrap) {
  padding: 0px 4px 0px !important;
}
</style>
