<template>
  <v-dialog
    persistent
    v-model="dialog"
    width="600"
    @click:outside="fecharDialog()"
  >
    <v-card>
      <v-card-title class="text-h5"> {{ tituloDialog }}</v-card-title>
      <v-card-text>
        <vue-json-pretty
          :data="dados"
          showIcon
          showLineNumber
          showLength
          :deep="3"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fecharDialog()"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import contatoService from "@/services/contato";
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      dialog: false,
      dados: [],
      tituloDialog: "",
    };
  },
  methods: {
    async abrirDialog(dados, titulo) {
      this.tituloDialog = titulo;
      if (dados?.contato_id && dados?.tipo && dados?.tipo != "PUSH") {
        await this.pegarContato(dados.contato_id, dados.tipo);
        this.dados = Object.assign(dados, { contato: this.dados.contato });
      } else {
        this.dados = dados;
      }
      this.dialog = true;
    },
    fecharDialog() {
      this.dialog = false;
      this.dados = [];
      this.tituloDialog = "";
    },
    async pegarContato(contato_id, tipo) {
      if (tipo == "EMAIL") {
        try {
          const { data } = await contatoService.getEmailById(contato_id);
          this.dados["contato"] = data.email;
        } catch (e) {
          console.error(e);
        }
      } else if (["SMS", "FAST_SMS"].includes(tipo)) {
        try {
          const { data } = await contatoService.getSmsById(contato_id);
          this.dados["contato"] = data.numero;
        } catch (e) {
          console.error(e);
        }
      } else if (["LIGACAO_CELULAR", "LIGACAO_FIXO"].includes(tipo)) {
        try {
          const { data } = await contatoService.getLigacaoById(contato_id);
          this.dados["contato"] = data.numero;
        } catch (e) {
          console.error(e);
        }
      } else if (tipo == "WHATSAPP") {
        try {
          const { data } = await contatoService.getWhatsappById(contato_id);
          this.dados["contato"] = data.numero;
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.vjs-tree {
  height: 60vh;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
</style>
