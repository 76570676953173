<template>
  <div
    class="pb-8 d-flex justify-center"
    :class="{
      'pr-2 pl-2 mr-1 ml-1': $vuetify.breakpoint.smAndDown,
      'pr-2 pl-2 mr-2 ml-2': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-skeleton-loader
      v-if="carregando"
      class="mx-auto"
      max-width="1100"
      type="table-tbody"
    ></v-skeleton-loader>
    <v-data-table
      v-else-if="!carregando && listaOrdenada.length"
      :headers="cabecalhos"
      :items="listaOrdenada"
      :items-per-page="-1"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            class="text-center"
            v-show="listaIniciativa.length === 0 && !carregando"
          >
            <td colspan="12">Nenhuma iniciativa encontrada</td>
          </tr>

          <tr
            v-for="item in items"
            :key="item?.id"
            :class="item?.status == 'ARQUIVADA' ? 'iniciativa-arquivada' : ''"
            @mouseover="ativarMenu(item)"
            @mouseleave="desativarMenu()"
          >
            <td>#{{ item.id }}</td>
            <td class="text-start nome">
              {{ item.nome }}
            </td>
            <td class="text-start iniciativa">
              {{ `${item.iniciativa}` }}
            </td>
            <td class="text-start mensagem">
              <div class="two-lines">
                {{ item.mensagem }}
              </div>
            </td>
            <td class="text-start status_broker">
              {{ item.status_broker | statusIniciativa }}
            </td>
            <!-- <td class="text-start classificacao">
              {{ item.classificacao | classificacoesIniciativa }}
            </td> -->
            <td class="text-center estado">
              <v-chip
                small
                :color="pegarEstilos(item.ativo).fundo"
                :text-color="pegarEstilos(item.ativo).texto"
              >
                {{ pegarEstilos(item.ativo).estado }}
              </v-chip>
            </td>
            <td class="text-start menuAcoes px-0">
              <v-menu
                left
                offset-x
                v-if="item?.id === existeItemSelecionado"
                v-model="menuAberto"
                dense
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="action-button" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="emitirOpcao('editar', item.id)">
                    <v-list-item-title>
                      <v-btn plain> Editar </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        plain
                        @click="
                          emitirOpcao('alterarEstado', item.id, !item.ativo)
                        "
                      >
                        {{ item.ativo ? "Desativar" : "Ativar" }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-row v-else class="mt-8">
      <v-col cols="12">
        <h2 class="grey--text text-center">Nenhuma iniciativa cadastrada</h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    listaIniciativa: Array,
    carregando: Boolean,
    mostrarArquivadas: Boolean,
  },
  data() {
    return {
      itemSelecionado: false,
      menuAberto: false,
      cabecalhos: [
        { text: "", value: "id", width: 50 },
        { text: "Nome", value: "nome", width: 300 },
        { text: "Iniciativa", value: "iniciativa" },
        { text: "Mensagem", value: "mensagem" },
        {
          text: "Status Broker",
          value: "status_broker",
          align: "center",
          width: 124,
        },
        // { text: 'Classificação', value: 'classificacao', width: 123 },
        { text: "", value: "ativo", sortable: false, width: 50 },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    listaOrdenada() {
      return this.listaIniciativa
        .filter((item) => {
          if (this.mostrarArquivadas) return true;
          else if (!item.ativo) return false;
          return true;
        })
        .map((item) => {
          if (item.atualizado_em) {
            item.atualizado_em = new Date(item.atualizado_em) || null;
          }
          item.criado_em = new Date(item.criado_em) || null;
          return item;
        })
        .sort((a, b) => {
          if (a.atualizado_em && b.atualizado_em)
            return b.atualizado_em.getTime() - a.atualizado_em.getTime();

          return b.criado_em.getTime() - a.criado_em.getTime();
        })
        .sort((a, b) => b.ativo - a.ativo);
    },
    existeItemSelecionado() {
      return this.itemSelecionado;
    },
  },
  methods: {
    ativarMenu(item) {
      if (!this.menuAberto) this.itemSelecionado = item.id;
      return;
    },
    desativarMenu() {
      if (!this.menuAberto) this.itemSelecionado = false;
      return;
    },
    fecharMenu() {
      this.menuAberto = false;
      return;
    },
    emitirOpcao(opcao, id, estado) {
      this.$emit(opcao, { id, estado });
      this.fecharMenu();
      return;
    },
    pegarEstilos(ativacao) {
      if (ativacao) {
        return {
          fundo: "#4CAF50",
          texto: "#FFFFFF",
          estado: "Ativo",
        };
      }
      return {
        fundo: "#999999",
        texto: "#FFFFFF",
        estado: "Inativo",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.estado .v-chip {
  width: 60px;
  justify-content: center;
}

.mensagem {
  max-width: 150px;
  width: 200px;
  min-width: 150px;
}

.two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.menuAcoes {
  width: 50px;
  min-width: 50px;
}
* {
  ::-webkit-scrollbar {
    height: 6px;
    margin-left: 1px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 25px;
    padding-right: 0px;
  }
}
</style>
