<template>
  <div class="text--primary">
    <span
      class="footer-text"
      v-html="
        getFormatedHtml(
          iniciativa?.mensagem_renderizada
            ? iniciativa?.mensagem_renderizada
            : iniciativa?.mensagem
        )
      "
    ></span>
  </div>
</template>

<script>
import variavelMensagemService from "@/services/variavel-mensagem";

export default {
  props: {
    iniciativa: null,
  },
  methods: {
    /** Retorna a mensagem com as variáveis substituídas pelos exemplos. */
    preVisualizarMensagem: variavelMensagemService.preVisualizarMensagem,

    getFormatedHtml(text) {
      if (text) {
        let textoComQuebrasDeLinha = text.replace(/\\n/g, "\n");
        let html_ = textoComQuebrasDeLinha.replace(/\*(.*?)\*/g, "<b>$1</b>");
        html_ = html_.replace(/\n/g, "<br>");
        return this.preVisualizarMensagem(html_);
      }
    },
  },
};
</script>

<style lang="scss">
span.footer-text {
  white-space: pre-line;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
</style>
