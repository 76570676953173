<template>
  <v-chart
    autoresize
    v-if="mostrarGrafico"
    class="bar-chart mx-auto"
    :option="notificacoesPorDia"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import Vue from "vue";
import { mapGetters } from "vuex";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export default {
  components: { VChart },
  props: {
    notificacoes: null,
    dataFatura: null,
    inicioFatura: null,
    parcial: null,
  },
  data: () => ({
    notificacoesPorDia: {
      legend: {
        left: "0%",
        data: [],
      },
      tooltip: {
        trigger: "item",
        formatter: function (item) {
          return (
            item.seriesName +
            "<br> Dia " +
            item.name +
            "<br>" +
            Vue.filter("fomatadorReal")(item.value) +
            " (" +
            item.data.quantidade +
            ")"
          );
        },
      },

      xAxis: {
        name: "Dia",
        nameLocation: "end",
        data: [],
        axisLabel: {
          interval: 0,
        },
      },

      yAxis: [
        {
          axisLabel: {
            formatter: (item) => {
              return Vue.filter("fomatadorReal")(item);
            },
            show: true,
          },
        },
      ],
      grid: { left: 90, right: 34 },
      series: [],
    },
    mostrarGrafico: false,
  }),
  async created() {
    this.carregaGrafico();
  },
  watch: {
    notificacoes: {
      handler() {
        this.carregaGrafico();
      },
    },
  },
  methods: {
    getNomePorCategoria(categoria) {
      const precificacao = this.precificacoes.find((p) => {
        return p.chave === categoria;
      });
      return precificacao && precificacao?.nome ? precificacao.nome : categoria;
    },

    getCorPorCategoria(categoria) {
      const precificacao = this.precificacoes.find(
        (p) => p.chave === categoria
      );
      return precificacao && precificacao?.cor
        ? precificacao.cor
        : Vue.filter("corDoGrafico")();
    },

    async carregaGrafico() {
      this.getDiasDoMes(this.inicioFatura, this.dataFatura, this.parcial);
      this.notificacoesPorDia.legend.data = [];
      this.notificacoesPorDia.series = [];

      var dias = [];
      this.notificacoesPorDia.xAxis.data.forEach((dia) => {
        dias.push(dia.split("\n")[0]);
      });

      this.notificacoes.forEach((notificacao) => {
        const categorias = Object.keys(notificacao);
        categorias.forEach((categoria) => {
          let categoriaFormatada = this.getNomePorCategoria(categoria);
          if (
            categoriaFormatada != "dia" &&
            this.notificacoesPorDia.legend.data.indexOf(categoriaFormatada) < 0
          ) {
            this.notificacoesPorDia.legend.data.push(categoriaFormatada);
            this.notificacoesPorDia.series.push({
              name: categoriaFormatada,
              key: categoria,
              type: "bar",
              stack: "one",
              itemStyle: {
                color: this.getCorPorCategoria(categoria),
              },
              emphasis: {
                itemStyle: { shadowBlur: 10, shadowColor: "rgba(0,0,0,0.3)" },
              },
              data: [],
            });
          }
        });

        let listaAuxiliar = [...this.notificacoesPorDia.series];
        listaAuxiliar.filter((retorno) => {
          if (notificacao[retorno.key]?.valor > 0) {
            let dia = dias.indexOf(notificacao.dia);
            retorno.data[dia] = {
              value: notificacao[retorno.key].valor,
              quantidade: Vue.filter("numero")(
                notificacao[retorno.key]?.quantidade ?? 0
              ),
            };
          }
        });
      });

      this.notificacoesPorDia.series = this.notificacoesPorDia.series.filter(
        (item) => {
          return item.data.length > 0;
        }
      );

      this.mostrarGrafico = true;
    },

    getDiasDoMes(inicioFatura, fimFatura, parcial) {
      var diaSemana = ["dom", "seg", "ter", "qua", "qui", "sex", "sab"];
      var resultados = [];
      this.notificacoesPorDia.xAxis.data = [];
      inicioFatura = new Date(Date.parse(inicioFatura));
      inicioFatura.setDate(inicioFatura.getDate() + 1);
      if (parcial) {
        fimFatura = new Date(Date.parse(inicioFatura));
        fimFatura.setMonth(fimFatura.getMonth() + 1);
        fimFatura.setDate(inicioFatura.getDate() - 1);
      } else {
        fimFatura = new Date(Date.parse(fimFatura));
        fimFatura.setDate(fimFatura.getDate() + 1);
      }
      var data = inicioFatura;
      while (data <= fimFatura) {
        resultados.push(data.getDate() + "\n" + diaSemana[data.getDay()]);
        data.setDate(data.getDate() + 1);
      }
      resultados.forEach((resultado) => {
        this.notificacoesPorDia.xAxis.data.push(resultado);
      });
      return resultados;
    },
  },
  computed: {
    ...mapGetters("fatura", {
      precificacoes: "precificacoes",
    }),
  },
};
</script>

<style lang="scss" scoped>
.bar-chart {
  height: 250px;
  max-width: 1000px;
  min-width: 100%;
  display: flex;
  justify-content: center;
}
</style>
