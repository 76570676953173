<template>
  <v-dialog v-model="dialogFecharFatura" max-width="450" persistent>
    <v-card v-if="fatura">
      <v-card-title class="text-h5"> Deseja aprovar a fatura? </v-card-title>
      <v-card-text class="subtitle-1">
        <strong>Subtotal serviços</strong> {{ fatura.subtotal | fomatadorReal }}
      </v-card-text>
      <v-card-text class="subtitle-1" v-if="fatura.descontos">
        <strong>Subtotal descontos</strong>
        {{ fatura.descontos | fomatadorReal }}
      </v-card-text>
      <v-card-text class="subtitle-1">
        <strong>TOTAL</strong> {{ fatura.total | fomatadorReal }}
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="fecharDialog()"
          :disabled="carregandoAprovarFatura"
        >
          cancelar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="aprovaFatura(fatura)"
          :loading="carregandoAprovarFatura"
        >
          APROVAR
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialogFecharFatura: false,
      fatura: null,
    };
  },
  methods: {
    ...mapActions("fatura", {
      aprovarFatura: "aprovarFatura",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    abrirDialog(fatura) {
      this.fatura = fatura;
      this.dialogFecharFatura = true;
    },
    fecharDialog() {
      this.fatura = null;
      this.dialogFecharFatura = false;
    },
    aprovaFatura(fatura) {
      this.aprovarFatura(fatura.id)
        .then(() => {
          this.showSnackBar({
            text: `Fatura aprovada com sucesso!`,
          });
          this.$router.push({ name: "Fatura" });
        })
        .catch((error) => {
          console.error(error);
          this.showSnackBar({
            text: `Erro ao aprovar a fatura!`,
          });
        });
    },
  },

  computed: {
    ...mapGetters("fatura", {
      carregandoAprovarFatura: "carregandoAprovarFatura",
    }),
    ...mapGetters("fatura", {
      carregandoAprovarFatura: "carregandoAprovarFatura",
    }),
  },
};
</script>

<style></style>
