<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 flex justify-between">
          Conteudo
          <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text class="p-4 mb-4">
          <div class="pt-4">
            <div
              class="message-bubble shadow-lg p-4 border border-gray-200 text-black bg-gray-200 border-solid rounded-lg rounded-br-sm"
            >
              {{ event.mensagem }}
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      event: {},
    };
  },
};
</script>
