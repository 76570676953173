<template>
  <v-container>
    <v-app-bar app>
      <div class="text-h6 font-weight-bold ml-3">Réguas pré-definidas</div>
    </v-app-bar>
    <v-row justify="center">
      <v-col xl="8" xs="12" lg="10" sm="12">
        <v-expansion-panels accordion v-model="reguaAberta">
          <v-expansion-panel
            class="my-2"
            v-for="(tipoRegua, index) in tiposReguas"
            :key="index"
            :value="tipoRegua.tipo"
            :disabled="tipoRegua.carregando"
            :readonly="!tipoRegua.carregando && !tipoRegua.reguas.length"
          >
            <v-hover v-slot="{ hover }">
              <v-expansion-panel-header ripple class="py-2">
                <v-container>
                  <v-row>
                    <div class="d-flex align-center flex-grow-1">
                      <span class="text-subtitle-1 font-weight-bold py-2 mr-4"
                        >{{ tipoRegua.tipo | tipoRegua }}

                        <v-tooltip bottom color="#494949">
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-icon small> mdi-text-long </v-icon>
                            </span>
                          </template>
                          <span>{{ tipoRegua.texto }}</span>
                        </v-tooltip>
                      </span>
                      <v-skeleton-loader
                        type="chip"
                        v-if="tipoRegua.carregando"
                      ></v-skeleton-loader>
                      <v-chip
                        color="#1b5e20"
                        v-else-if="tipoRegua.reguas.length"
                        outlined
                        class="font-weight-black"
                        >{{ escreverReguasAtivas(tipoRegua.reguas) }}</v-chip
                      >
                      <v-spacer></v-spacer>
                      <div v-show="hover || index == reguaAberta">
                        <template v-if="tipoRegua.doisBotoes">
                          <v-menu right origin="center center" transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn text v-bind="attrs" v-on="on" color="indigo accent-3"
                                >Gerenciar</v-btn
                              >
                            </template>
                            <v-list>
                              <v-list-item @click="verificaTipoAtivo(tipoRegua.primeiroBotao)">
                                <v-list-item-title>{{
                                  tipoRegua.primeiroBotao | tituloBotao
                                }}</v-list-item-title>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item @click="verificaTipoAtivo(tipoRegua.segundoBotao)">
                                <v-list-item-title>{{
                                  tipoRegua.segundoBotao | tituloBotao
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>

                        <template v-else>
                          <v-btn
                            text
                            color="indigo accent-3"
                            @click="verificaTipoAtivo(tipoRegua.tipo)"
                            >Gerenciar</v-btn
                          >
                        </template>
                      </div>
                    </div>
                  </v-row>
                </v-container>
                <template v-slot:actions>
                  <span v-if="!tipoRegua.carregando && !tipoRegua.reguas.length"></span>
                </template>
              </v-expansion-panel-header>
            </v-hover>
            <v-expansion-panel-content>
              <v-data-table
                v-if="tipoRegua.reguas"
                :items="tipoRegua.reguas"
                :items-per-page="-1"
                :headers="cabeçalhoReguas"
                :loading="tipoRegua.carregando"
                hide-default-footer
                hide-default-header
                no-data-text="Nenhuma régua cadastrada"
              >
                <template v-slot:item.nome="{ item }">
                  <router-link
                    :to="{
                      name: 'Reguas',
                      query: {
                        tipo: item.tipo,
                      },
                      params: {
                        regua: item,
                      },
                    }"
                    class="text-decoration-none"
                  >
                    <div class="d-flex">
                      <div class="text-body-2 font-weight-medium secondary--text">
                        {{ item.nome }}
                        <v-icon
                          color="#ef5350"
                          class="ml-1"
                          v-if="item?.id_regua_desafiante && item.id < item?.id_regua_desafiante"
                        >
                          mdi-alpha-a-circle-outline
                        </v-icon>
                        <v-icon color="#42A5F5" class="ml-1" v-else-if="item?.id_regua_desafiante">
                          mdi-alpha-b-circle-outline
                        </v-icon>
                      </div>
                      <v-chip
                        class="ml-2 font-weight-black user-select-none"
                        small
                        outlined
                        color="teal"
                        v-if="tipoRegua.doisBotoes"
                        @click.stop.prevent=""
                        style="font-size: 14px !important"
                      >
                        {{ item.tipo | tituloBotao | tipoRegua }}
                      </v-chip>
                      <v-chip
                        v-if="item.id_regua_desafiante"
                        color="orange"
                        small
                        class="ml-2"
                        dark
                      >
                        Teste A/B
                      </v-chip>
                      <v-chip
                        small
                        outlined
                        v-if="item.perfis?.length > 0 && item.status != 'ARQUIVADA'"
                        :color="item.status != 'PRODUCAO' ? 'grey' : 'purple'"
                        @click.stop.prevent="visualizarPerfisRegua(item)"
                        class="mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none ml-2"
                      >
                        <span class="text-subtitle-2 font-weight-black">
                          {{ item.perfis?.length }}
                          {{ item.perfis?.length > 1 ? "perfis vinculados" : "perfil vinculado" }}
                        </span>
                      </v-chip>
                      <v-spacer> </v-spacer>
                      <router-link
                        :to="{
                          name: 'eventos',
                          query: { listagem: 'predefinidas' },
                          params: { regua_id: item.id },
                        }"
                        v-if="contarNumeroDeEventos(item)"
                        class="regua__evento d-flex flex-row justify-start text-decoration-none"
                      >
                        <v-chip
                          outlined
                          link
                          small
                          :color="item.status != 'PRODUCAO' ? 'grey' : 'indigo accent-3'"
                          class="mr-2 d-flex flex-column white--text item__evento__text"
                        >
                          <span class="text-subtitle-2 font-weight-black">
                            {{ contarNumeroDeEventos(item) }}
                            {{ contarNumeroDeEventos(item) > 1 ? "eventos" : "evento" }}
                            <span
                              v-if="item.tipo != 'IMPLANTACAO' && contarDiasDeEventos(item) > 1"
                              class="text-secondary text-caption font-weight-medium events__sub"
                            >
                              em
                              {{ contarDiasDeEventos(item) }}
                              {{ contarDiasDeEventos(item) > 1 ? "dias" : "dia" }}
                            </span>
                          </span>
                        </v-chip>
                      </router-link>
                    </div>
                  </router-link>
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <DialogVisualizarPerfis ref="dialogVisualizarPerfis" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DialogVisualizarPerfis from "../components/dialogs/DialogVisualizarPerfis.vue";

export default {
  components: {
    DialogVisualizarPerfis,
  },
  data: () => ({
    reguaAberta: null,
    tiposAtivos: [],
    cabeçalhoReguas: [{ text: "Nome", value: "nome" }],
    tiposReguas: [
      {
        tipo: "IMPLANTACAO",
        texto:
          "Eventos relacionados com a data de importação dos contratos. Ex: comando de integrações ou comunicações de boas-vindas.",
        doisBotoes: false,
      },
      {
        tipo: "INICIO_CONTRATO",
        texto:
          "Eventos relacionados ao período de início  dos contratos. Ex: Pesquisas de satisfação recorrentes.",
        doisBotoes: false,
      },
      {
        tipo: "TERMINO_CONTRATO",
        texto:
          "Eventos relacionados ao período de término dos contratos. Ex: Pesquisas de satisfação recorrentes.",
        doisBotoes: false,
      },
      {
        tipo: "CONTRATO_CANCELADO",
        texto:
          "Eventos relacionados ao cancelamento do contrato.",
        doisBotoes: false,
      },
      {
        tipo: "CONTRATO_FINALIZADO",
        texto:
          "Eventos relacionados a finalização do contrato.",
        doisBotoes: false,
      },
      {
        tipo: "VENCIMENTO_BOLETO",
        texto: "Eventos relacionados ao vencimento da parcela de boleto. Ex: Lembretes para pagamentos.",
        doisBotoes: false,
      
      },
      {
        tipo: "VENCIMENTO_DEBITO",
        texto: "Eventos relacionados ao vencimento da parcela de débito. Ex: Lembretes para pagamentos.",
        doisBotoes: false,
      },
      {
        tipo: "TOLERANCIA_BOLETO",
        texto:
          "Eventos relacionados ao prazo final para o pagamento de boleto.",
        doisBotoes: false,
      },
      {
        tipo: "TOLERANCIA_DEBITO",
        texto:
          "Eventos relacionados ao prazo final para o pagamento de débito.",
        doisBotoes: false,
      },
      {
        tipo: "BOLETO_SOLICITADO",
        texto:
          "Eventos relacionados as solicitações do cliente. Ex: Segue o boleto solicitado. Lembretes gerenciados pelo cliente.",
        doisBotoes: false,
      },
      {
        tipo: "PARCELA_BOLETO_CRIADA",
        texto: "Eventos relacionados com referência data de criação de parcelas de boleto.",
        doisBotoes: false,
      },
      {
        tipo: "PARCELA_DEBITO_CRIADA",
        texto: "Eventos relacionados com referência data de criação de parcelas de débito.",
        doisBotoes: false,
      },
      {
        tipo: "PAGAMENTO_BOLETO_RECEBIDO",
        texto:
          "Eventos relacionados a data de compensação de um pagamento de boleto. Ex: Recebemos seu pagamento.",
        doisBotoes: false,
      },
      {
        tipo: "PAGAMENTO_DEBITO_RECEBIDO",
        texto:
          "Eventos relacionados a data de compensação de um pagamento de débito. Ex: Recebemos seu pagamento.",
        doisBotoes: false,
      },
      {
        tipo: "ANIVERSARIO_DO_CLIENTE",
        texto: "Eventos relacionados com o aniversário do cliente. Ex: Feliz aniversário.",
        doisBotoes: false,
      },
    ],
  }),
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.carregarDados();
        this.reguaAberta = null;
      },
    },
  },
  async created() {
    await this.carregarDados();
  },
  methods: {
    ...mapActions("reguas", ["carregarReguas"]),
    async carregarDados() {
      this.tiposReguas.forEach(async (tipoRegua, index) => {
        this.tiposReguas[index].carregando = true;
        this.tiposReguas[index].reguas = [];
        if (tipoRegua.doisBotoes) {
          const resultadoBotaoUm = await this.carregarReguas({
            tipo: tipoRegua.primeiroBotao,
            limite: 1000,
            incluir: "eventos,perfis",
            status: "PRODUCAO",
          });
          const resultadoBotaoDois = await this.carregarReguas({
            tipo: tipoRegua.segundoBotao,
            limite: 1000,
            incluir: "eventos,perfis",
            status: "PRODUCAO",
          });
          this.$set(this.tiposReguas[index], "reguas", [
            ...resultadoBotaoUm.objects,
            ...resultadoBotaoDois.objects,
          ]);
        } else {
          const resultadoReguas = await this.carregarReguas({
            tipo: tipoRegua.tipo,
            limite: 1000,
            incluir: "eventos,perfis",
            status: "PRODUCAO",
          });
          this.$set(this.tiposReguas[index], "reguas", resultadoReguas.objects);
        }
        this.tiposReguas[index].carregando = false;
      });
      this.tiposReguas = JSON.parse(JSON.stringify(this.tiposReguas));
    },
    contarNumeroDeEventos(regua) {
      let eventos = (regua.eventos || []).filter((evento) => evento.status == "PRODUCAO");
      return eventos.filter((x) => x.master_id == null && x.status == "PRODUCAO").length;
    },
    contarDiasDeEventos(regua) {
      let eventos = (regua.eventos || []).filter((evento) => evento.status == "PRODUCAO");
      let menorDia = 0;
      let maiorDia = 0;
      eventos.forEach((event) => {
        event.dia < menorDia ? (menorDia = event.dia) : null;
        event.dia > maiorDia ? (maiorDia = event.dia) : null;
      });
      let isNotifyingOnDay0 = eventos.some((event) => event.dia == 0);
      let eventDaysWindow = maiorDia + Math.abs(menorDia);
      if (isNotifyingOnDay0) {
        eventDaysWindow += 1;
      }
      return eventDaysWindow;
    },
    verificaTipoAtivo(tipo) {
      if (!this.tiposAtivos.includes(tipo)) {
        this.$router
          .push({ name: "Reguas", query: { tipo: tipo, tela: "reguas" } })
          .catch(() => {});
      }
    },
    escreverReguasAtivas(listaDeReguas) {
      let tamanho = listaDeReguas.length;
      return tamanho > 1 ? `${tamanho} réguas ativas` : "1 régua ativa";
    },
    visualizarPerfisRegua(regua) {
      this.$refs.dialogVisualizarPerfis.abrirDialog(regua, "VISUALIZAR");
    },
    filtrarProducao(lista) {
      let reguasProducao = lista.filter((item) => item.status == "PRODUCAO");
      return this.ordenaReguaPorStatus(reguasProducao);
    },
    ordenaReguaPorStatus(payload) {
      let reguas = payload;
      for (var i = 0; i < reguas.length; i++) {
        if (reguas[i].status == "PRODUCAO") {
          reguas[i]["ordem"] = 1;
        } else if (reguas[i].status == "RASCUNHO") {
          reguas[i]["ordem"] = 2;
        } else if (reguas[i].status == "ARQUIVADA") {
          reguas[i]["ordem"] = 3;
        }
      }
      reguas.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));
      reguas.sort((a, b) => {
        if (a.ordem == b.ordem) {
          return a.atualizado_em < b.atualizado_em ? 1 : -1;
        }
        return 0;
      });
      return reguas;
    },
  },
};
</script>

<style lang="scss"></style>
